import { React, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  Paper,
  FormControlLabel,
  Tooltip,
  makeStyles,
  TextField,
  Grid,
  IconButton,
  AccordionDetails,
  Button,
  Box
} from '@material-ui/core';
import {
  Search,
  TuneTwoTone
} from '@material-ui/icons';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import genService from 'src/services/generalServices';
import ordenService from 'src/services/ordenService';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: '2px 6px',
    display: 'contents'
  },
  accordionSumm: {
    padding: 0,
    display: 'flex',
    '& .MuiIconButton-edgeEnd': {
      marginRight: 5
    }
  },
  bottomSpace: {
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    display: 'flex'
  },
  combo: {
    marginLeft: theme.spacing(1),
    display: 'flex'
  },
  iconButton: {
    alignItems: 'center'
  },
}));

const BusquedaEvaluaciones = ({
  comboEscuelas,
  mostrarBusqueda,
  setMostrarBusqueda,
  comboOrdenEstatus,
  setRowsOrdenes,
  setOpenBackDrop,
  setServerError,
  setOpenSnackError,
}) => {
  const {
    handleSubmit,
    register,
    errors
  } = useForm();
  const paperRef = useRef();
  const classes = useStyles();
  const [valueCombo, setValueCombo] = useState([]);
  const [valueComboEstatus, setValueComboEstatus] = useState([]);
  const [openFechaDesde, setOpenFechaDesde] = useState(false);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [openFechaHasta, setOpenFechaHasta] = useState(false);
  const [fechaHasta, setFechaHasta] = useState(null);

  const onSubmit = (formulario) => {
    if (valueCombo !== null && valueCombo !== undefined) {
      formulario.idCliente = valueCombo.value;
    }
    if (!mostrarBusqueda) {
      if (fechaDesde !== null && fechaDesde !== '') {
        formulario.desde = format(fechaDesde, 'yyyy-MM-dd');
      }
      if (fechaHasta !== null && fechaHasta !== '') {
        formulario.hasta = format(fechaHasta, 'yyyy-MM-dd');
      }
      if (valueComboEstatus !== null && valueComboEstatus !== undefined) {
        formulario.idOrdenEstatus = valueComboEstatus.value;
      }
    }
    const asyncCall = async (datos) => {
      setOpenBackDrop(true);
      await ordenService.searchOrdenes(datos, true).then((resp) => {
        setRowsOrdenes(resp.data);
      }).catch((error) => {
        setRowsOrdenes([]);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario);
    console.log('Formulario busqueda de ordenes:', formulario);
    console.log(comboEscuelas);
  };

  return (
    <Paper
      ref={paperRef}
      component="form"
      className={classes.paperRoot}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={(
            <Tooltip title="Búsqueda Avanzada">
              <TuneTwoTone />
            </Tooltip>
          )}
          onClick={() => setMostrarBusqueda(!mostrarBusqueda)}
          className={classes.accordionSumm}
        >
          <Grid
            container
            spacing={3}
            justifyContent="center"
          >
            <Grid item xs={12} md={8} lg={8}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.combo}
                control={(
                  <Autocomplete
                    id="combo-box-escuelas"
                    options={comboEscuelas}
                    getOptionLabel={(option) => (option.label ? option.label : '')}
                    value={valueCombo}
                    fullWidth
                    className={classes.combo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regEscuelas"
                        label="Seleccione un Cliente"
                        variant="outlined"
                        size="small"
                        error={!!errors.regEscuelas}
                        helperText={errors.regEscuelas?.message}
                        onInput={genService.toUpperCase}
                        inputRef={register}
                      />
                    )}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setValueCombo(newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={mostrarBusqueda ? 8 : 11} xs={mostrarBusqueda ? 8 : 11} md={3}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <TextField
                    fullWidth
                    inputRef={register}
                    className={classes.input}
                    label="Folio"
                    size="small"
                    name="folio"
                    onInput={genService.toUpperCase}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={1}
              md={1}
              sm={1}
              lg={1}
              align="right"
              style={{ visibility: mostrarBusqueda ? 'visible' : 'hidden' }}
            >
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <IconButton
                    className={classes.iconButton}
                    aria-label="search"
                    type="submit"
                  >
                    <Search />
                  </IconButton>
                )}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} lg={6}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="desde"
                      open={openFechaDesde}
                      onOpen={() => setOpenFechaDesde(true)}
                      onClose={() => setOpenFechaDesde(false)}
                      onChange={setFechaDesde}
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Desde"
                      value={fechaDesde}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12} lg={6}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="hasta"
                      open={openFechaHasta}
                      onOpen={() => setOpenFechaHasta(true)}
                      onClose={() => setOpenFechaHasta(false)}
                      onChange={setFechaHasta}
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Hasta"
                      value={fechaHasta}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      variant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <TextField
                    fullWidth
                    inputRef={register}
                    label="Alumno"
                    size="small"
                    name="investigado"
                    onInput={genService.toUpperCase}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                className={classes.input}
                control={(
                  <Autocomplete
                    id="combo-box-estatus"
                    options={comboOrdenEstatus}
                    getOptionLabel={(option) => (option.label ? option.label : '')}
                    fullWidth
                    value={valueComboEstatus}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="ordenStatus"
                        label="Seleccione un Estatus"
                        variant="outlined"
                        size="small"
                        error={!!errors.ordenStatus}
                        helperText={errors.ordenStatus?.message}
                        onInput={genService.toUpperCase}
                        inputRef={register}
                      />
                    )}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      setValueComboEstatus(newValue);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.bottomSpace} />
              <Box display="flex" justifyContent="center">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Buscar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

BusquedaEvaluaciones.propTypes = {
  comboEscuelas: PropTypes.array,
  mostrarBusqueda: PropTypes.bool,
  setMostrarBusqueda: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  setRowsOrdenes: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func
};

export default BusquedaEvaluaciones;
