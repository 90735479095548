import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  makeStyles,
  Popper,
  Card,
  CardHeader,
  Divider,
  Grid,
  CardContent,
  TextField,
  Box,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import genService from 'src/services/generalServices';
import esLocale from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
  root: {},
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
}));

const BusquedaUsuarios = ({
  anchorEl,
  openPopper,
  setFechaDesde,
  setFechaHasta,
  fechaDesde,
  fechaHasta,
  onSubmitAdv,
  preloadedValues
}) => {
  const { register, handleSubmit, control } = useForm();
  const classes = useStyles();
  const [openFechaHasta, setOpenFechaHasta] = React.useState(false);
  const [openFechaDesde, setOpenFechaDesde] = React.useState(false);

  return (
    <Popper open={openPopper} anchorEl={anchorEl} getcontentanchorel={null}>
      <form
        onSubmit={handleSubmit((data) => onSubmitAdv(data, 'adv'))}
      >
        <Card className={classes.root}>
          <CardHeader subheader="Búsqueda avanzada" style={{ padding: '5px' }} />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  inputRef={register}
                  label="Usuario"
                  size="small"
                  name="usuario"
                  onInput={genService.toUpperCase}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  inputRef={register}
                  label="Nombre"
                  name="nombre"
                  size="small"
                  onInput={genService.toUpperCase}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="desde"
                    open={openFechaDesde}
                    onOpen={() => setOpenFechaDesde(true)}
                    /* onClick={() => setOpenFechaDesde(true)} */
                    onClose={() => setOpenFechaDesde(false)}
                    onChange={setFechaDesde}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Desde"
                    value={fechaDesde}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="hasta"
                    open={openFechaHasta}
                    onOpen={() => setOpenFechaHasta(true)}
                    onClose={() => setOpenFechaHasta(false)}
                    /* onClick={() => setOpenFechaHasta(true)} */
                    onChange={setFechaHasta}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Hasta"
                    value={fechaHasta}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="rol-select">
                    Seleccione un Rol
                  </InputLabel>
                  <Controller
                    control={control}
                    name="rol"
                    defaultValue=""
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    as={(
                      <Select
                        id="rol-select"
                        label="Seleccione un Rol"
                        value=""
                        MenuProps={{
                          disablePortal: true,
                          anchorEl: this,
                          PaperProps: { style: { position: 'sticky' } }
                        }}
                      >
                        <MenuItem>Seleccione un Rol</MenuItem>
                        {preloadedValues.map((rol) => (
                          <MenuItem key={rol.value} value={rol.value}>{rol.text}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.bottomSpace} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Buscar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Popper>
  );
};
BusquedaUsuarios.propTypes = {
  anchorEl: PropTypes.object,
  openPopper: PropTypes.bool,
  setFechaDesde: PropTypes.func,
  setFechaHasta: PropTypes.func,
  fechaDesde: PropTypes.string,
  fechaHasta: PropTypes.string,
  onSubmitAdv: PropTypes.func,
  preloadedValues: PropTypes.array
};
export default BusquedaUsuarios;
