import React from 'react';

// Andale Brahiam, arregla este logo.
const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logo_consultores.svg"
      {...props}
    />
  );
};

export default Logo;
