import { React } from 'react';
import {
  AppBar,
  Box,
  Hidden,
  Paper,
  Tab,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  AccountBalance,
  AttachMoney,
  House,
  MoneyOff,
  Work
} from '@material-ui/icons';
import genService from 'src/services/generalServices';
import evaluacionService from 'src/services/evaluacionService';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles, withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
  },
  bottomSpace1: {
    marginBottom: theme.spacing(1),
  },
  tableWidth: {
    width: '100%',
    display: 'contents'
  }
}));

const Table = withStyles({
  root: {
    border: '1px solid rgba(204, 204, 204)',
    borderCollapse: 'inherit',
    borderSpacing: 'revert'
  },
})(MuiTable);

const TableCell = withStyles({
  root: {
    border: '1px solid rgba(204, 204, 204)',
  }
})(MuiTableCell);

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Details = ({
  className,
  tabValue,
  setTabValue,
  evaluacion,
  comboViviendaA,
  comboViviendaB1,
  comboViviendaB2,
  comboViviendaB3,
  comboViviendaC,
  rolUsuario,
  setOpenAddDialog,
  setTituloDialog,
  setItemDTO,
  comboPrincipalA,
  comboPrincipalB1,
  comboPrincipalB2,
  comboPrincipalB3,
  comboPrincipalC,
  setOpenAddDialogPrin,
  formName,
  setServerError,
  setOpenSnackError,
  valComboPrincipal,
  valComboVivienda,
  setValComboPrincipal,
  setValComboVivienda,
  setOpenSnackSuccess,
  setServerMessage,
  setEvaluacion,
  valComboEscuelas,
  setValComboEscuelas,
  setOpenBackDrop,
  resetPlantilla,
  setResetPlantilla
}) => {
  const classes = useStyles();
  const {
    errors,
    handleSubmit,
    setError,
    setValue,
    clearErrors
  } = useForm();
  const requiredField = 'Este campo es requerido.';
  const maxPct = 'El porcentaje es entre 0 y 99';
  function toCurrency(numberString) {
    const number = parseFloat(numberString);
    return `$ ${number.toLocaleString('MXN')}`;
  }
  function concatTitleViabilidad(value) {
    setTituloDialog(`Agregar Vivienda -> ${value}`);
  }
  function concatTitlePrincipal(value) {
    setTituloDialog(`Agregar Principal -> ${value}`);
  }

  const onError = (err) => {
    if (err) {
      setServerError('Hay campos pendientes por llenar, favor de revisar las Tabs.');
      setOpenSnackError(true);
    }
  };
  const removeZero = (newVal, fieldName) => {
    while (newVal.length > 1 && newVal.charAt(0) === '0') {
      newVal = newVal.substring(1);
      setValue(fieldName, newVal);
    }
    return newVal;
  };
  const onSubmit = () => {
    // Validar que todos los campos se hayan llenado
    let errorEmptyFields = 'Hay campos por llenar en: ';
    let boolVivienda = false;
    let boolPrincipal = false;
    let boolDeuda = false;
    for (let aux = 0; aux < evaluacion.detalles.length; aux++) {
      const viviendaArr = evaluacion.detalles[aux].vivienda;
      const principalArr = evaluacion.detalles[aux].principal;
      const deudaArr = evaluacion.detalles[aux].deuda;
      for (let auxItem = 0; auxItem < viviendaArr.length; auxItem++) {
        if (viviendaArr[auxItem].idVivienda === null) {
          boolVivienda = true;
          setError(`vivienda_${evaluacion.detalles[aux].idViabilidad}_${auxItem}`, { type: 'required', message: requiredField });
        }
      }
      for (let auxItem = 0; auxItem < principalArr.length; auxItem++) {
        if (principalArr[auxItem].idPrincipal === null) {
          boolPrincipal = true;
          setError(`principal_${evaluacion.detalles[aux].idViabilidad}_${auxItem}`, { type: 'required', message: requiredField });
        }
      }
      for (let auxItem = 0; auxItem < deudaArr.length; auxItem++) {
        if (deudaArr[auxItem].deudaMin === null || deudaArr[auxItem].deudaMin === '') {
          boolDeuda = true;
          setError(`deudaMin_${evaluacion.detalles[aux].idViabilidad}_${auxItem}`, { type: 'required', message: requiredField });
        }
        if (deudaArr[auxItem].deudaMax === null || deudaArr[auxItem].deudaMax === '') {
          boolDeuda = true;
          setError(`deudaMax_${evaluacion.detalles[aux].idViabilidad}_${auxItem}`, { type: 'required', message: requiredField });
        }
      }
    }
    if (boolDeuda || boolPrincipal || boolVivienda) {
      if (boolVivienda && boolPrincipal && boolDeuda) {
        errorEmptyFields += 'VIVIENDA, PRINCIPAL Y DEUDA';
      } else if (boolVivienda && boolPrincipal) {
        errorEmptyFields += 'VIVIENDA Y PRINCIPAL';
      } else if (boolVivienda && boolDeuda) {
        errorEmptyFields += 'VIVIENDA Y DEUDA';
      } else if (boolPrincipal && boolDeuda) {
        errorEmptyFields += 'PRINCIPAL Y DEUDA';
      } else {
        errorEmptyFields += boolVivienda ? 'VIVIENDA' : boolPrincipal ? 'PRINCIPAL' : boolDeuda ? 'DEUDA' : '';
      }
      setServerError(errorEmptyFields);
      setOpenSnackError(true);
    } else {
      const asyncCall = async () => {
        setOpenBackDrop(true);
        evaluacion.idCliente = valComboEscuelas.value;
        if (resetPlantilla > 0) {
          evaluacion.resetPlantilla = true;
          evaluacion.id = resetPlantilla;
        }
        console.log('Evaluacion:', evaluacion);
        await evaluacionService.guardarPlantillaEval(evaluacion).then((resp) => {
          console.log('La plantilla se guardó correctamente:', resp);
          valComboEscuelas.plantilla = true;
          setValComboEscuelas(valComboEscuelas);
          setServerMessage('La plantilla se guardó correctamente');
          setOpenSnackSuccess(true);
          setEvaluacion(resp.data);
          setOpenBackDrop(false);
          setResetPlantilla(0);
        }).catch((error) => {
          setOpenBackDrop(false);
          console.log('Error al guardar la plantilla', error);
          setServerError('Hubo un error al guardar la plantilla, favor de contactar al administrador.');
          setOpenSnackError(true);
        });
      };
      asyncCall();
    }
  };

  return (
    <form
      id={formName}
      onSubmit={handleSubmit(onSubmit, onError)}
      className={clsx(classes.appBar, className)}
    >
      <AppBar position="static" color="default">
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
          /* orientation="vertical" */
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            style={{ minWidth: 50 }}
            label={<Hidden smDown>Ingresos</Hidden>}
            icon={<AttachMoney fontSize="small" />}
          />
          <Tab
            style={{ minWidth: 50 }}
            label={<Hidden smDown>Egresos</Hidden>}
            icon={<MoneyOff fontSize="small" />}
          />
          <Tab
            style={{ minWidth: 50 }}
            label={<Hidden smDown>Vivienda</Hidden>}
            icon={<House fontSize="small" />}
          />
          <Tab
            style={{ minWidth: 50 }}
            label={<Hidden smDown>Principal</Hidden>}
            icon={<Work fontSize="small" />}
          />
          <Tab
            style={{ minWidth: 50 }}
            label={<Hidden smDown>Deuda</Hidden>}
            icon={<AccountBalance fontSize="small" />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody align="center">
              {evaluacion?.detalles?.map((row, viavIndex) => {
                const keyIngresos = `ing_${viavIndex}`;
                return (
                  <div className={classes.tableWidth}>
                    <TableRow key={keyIngresos}>
                      <TableCell align="center" rowSpan={row.ingresos?.length + 1}>
                        {row.viabilidad}
                      </TableCell>
                    </TableRow>
                    {row?.ingresos?.map((rowItem, rowIndex) => {
                      const keyRow = `ingresos_${row.idViabilidad}_${rowIndex}`;
                      return (
                        <TableRow key={keyRow}>
                          <TableCell align="center">
                            {rowItem.factor}
                          </TableCell>
                          <TableCell align="center">
                            {toCurrency(rowItem.montoMin)}
                          </TableCell>
                          <TableCell align="center">
                            {rowItem?.montoMax > 0
                              ? (
                                toCurrency(rowItem.montoMax)
                              ) : (
                                'o más'
                              )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </div>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody align="center">
              {evaluacion?.detalles?.map((row, viavIndex) => {
                const keyEgresos = `ing_${viavIndex}`;
                return (
                  <td className={classes.tableWidth}>
                    <TableRow key={keyEgresos}>
                      <TableCell align="center" rowSpan={row.egresos?.length + 1}>
                        {row.viabilidad}
                      </TableCell>
                    </TableRow>
                    {row?.egresos?.map((rowItem, rowIndex) => {
                      const keyRow = `egresos_${row.idViabilidad}_${rowIndex}`;
                      return (
                        <TableRow key={keyRow}>
                          <TableCell align="center">
                            {rowItem.factor}
                          </TableCell>
                          <TableCell align="center">
                            {toCurrency(rowItem.montoMin)}
                          </TableCell>
                          <TableCell align="center">
                            {rowItem?.montoMax > 0
                              ? (
                                toCurrency(rowItem.montoMax)
                              ) : (
                                'o más'
                              )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </td>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody align="center">
              {evaluacion?.detalles?.map((row, viavIndex) => {
                const keyVivienda = `viviend_${viavIndex}`;
                return (
                  <td className={classes.tableWidth}>
                    <TableRow key={keyVivienda}>
                      <TableCell align="center" rowSpan={row.vivienda?.length + 1}>
                        {row.viabilidad}
                        {(rolUsuario === 'ADMINISTRADOR')
                          ? (
                            <Tooltip title="Agregar Vivienda">
                              <IconButton
                                className={classes.customHoverFocus}
                                onClick={() => {
                                  setOpenAddDialog(true);
                                  concatTitleViabilidad(row.viabilidad);
                                  const viabilidadDTO = {
                                    id: 0,
                                    idViabilidad: row.idViabilidad,
                                    viabilidad: row.viabilidad,
                                    descripcion: null
                                  };
                                  setItemDTO(viabilidadDTO);
                                }}
                                fontSize="small"
                                color="primary"
                              >
                                <AddBoxIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : null }
                      </TableCell>
                    </TableRow>
                    {row?.vivienda?.map((rowItem, rowIndex) => {
                      const comboName = `vivienda_${row.idViabilidad}_${rowIndex}`;
                      return (
                        <TableRow key={comboName}>
                          <TableCell align="center">
                            {rowItem.factor}
                          </TableCell>
                          <TableCell align="center">
                            <Autocomplete
                              id="combo-box-vivenda"
                              options={row.viabilidad === 'A / 81-100' ? comboViviendaA
                                : row.viabilidad === 'B-1 / 61-80' ? comboViviendaB1
                                  : row.viabilidad === 'B-2 / 41-60' ? comboViviendaB2
                                    : row.viabilidad === 'B-3 / 21-40' ? comboViviendaB3
                                      : row.viabilidad === 'C / 0-20' ? comboViviendaC : null}
                              getOptionLabel={(option) => (option.label ? option.label : '')}
                              renderOption={(option) => option.label}
                              fullWidth
                              value={valComboVivienda[comboName]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={comboName}
                                  label="Seleccione un valor"
                                  variant="outlined"
                                  size="small"
                                  error={!!errors[comboName]}
                                  helperText={errors[comboName]?.message}
                                  onInput={genService.toUpperCase}
                                />
                              )}
                              onChange={(event, newValue) => {
                                console.log('resetPlantilla:', resetPlantilla);
                                valComboVivienda[comboName] = newValue;
                                setValComboVivienda(valComboVivienda);
                                rowItem.idViabilidad = newValue?.idViabilidad || null;
                                rowItem.idVivienda = newValue?.value || null;
                                clearErrors(comboName);
                              }}
                              onLoad={(option) => {
                                /* rowItem.idVivienda;
                                valComboVivienda[comboName] = ;
                                setValComboVivienda(valComboVivienda); */
                                console.log('La opcion:', option);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </td>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody align="center">
              {evaluacion?.detalles?.map((row, viavIndex) => {
                const keyPrincipal = `principal_${viavIndex}`;
                return (
                  <td className={classes.tableWidth}>
                    <TableRow key={keyPrincipal}>
                      <TableCell align="center" rowSpan={row.principal?.length + 1}>
                        {row.viabilidad}
                        {(rolUsuario === 'ADMINISTRADOR')
                          ? (
                            <Tooltip title="Agregar Principal">
                              <IconButton
                                className={classes.customHoverFocus}
                                onClick={() => {
                                  setOpenAddDialogPrin(true);
                                  concatTitlePrincipal(row.viabilidad);
                                  const viabilidadDTO = {
                                    id: 0,
                                    idViabilidad: row.idViabilidad,
                                    viabilidad: row.viabilidad,
                                    descripcion: null
                                  };
                                  setItemDTO(viabilidadDTO);
                                }}
                                fontSize="small"
                                color="primary"
                              >
                                <AddBoxIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          ) : null }
                      </TableCell>
                    </TableRow>
                    {row?.principal?.map((rowItem, rowIndex) => {
                      const comboName = `principal_${row.idViabilidad}_${rowIndex}`;
                      return (
                        <TableRow key={comboName}>
                          <TableCell align="center">
                            {rowItem.factor}
                          </TableCell>
                          <TableCell align="center">
                            <Autocomplete
                              id="combo-box-vivenda"
                              options={row.viabilidad === 'A / 81-100' ? comboPrincipalA
                                : row.viabilidad === 'B-1 / 61-80' ? comboPrincipalB1
                                  : row.viabilidad === 'B-2 / 41-60' ? comboPrincipalB2
                                    : row.viabilidad === 'B-3 / 21-40' ? comboPrincipalB3
                                      : row.viabilidad === 'C / 0-20' ? comboPrincipalC : null}
                              getOptionLabel={(option) => (option.label ? option.label : '')}
                              renderOption={(option) => option.label}
                              fullWidth
                              value={valComboPrincipal[comboName]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={comboName}
                                  label="Seleccione un valor"
                                  variant="outlined"
                                  size="small"
                                  error={!!errors[comboName]}
                                  helperText={errors[comboName]?.message}
                                  onInput={genService.toUpperCase}
                                />
                              )}
                              onChange={(event, newValue) => {
                                valComboPrincipal[comboName] = newValue;
                                setValComboPrincipal(valComboPrincipal);
                                rowItem.idViabilidad = newValue?.idViabilidad || null;
                                rowItem.idPrincipal = newValue?.value || null;
                                clearErrors(comboName);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </td>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableBody align="center">
              {evaluacion?.detalles?.map((row, viavIndex) => {
                const keyDeuda = `deuda_${viavIndex}`;
                return (
                  <td className={classes.tableWidth}>
                    <TableRow key={keyDeuda}>
                      <TableCell align="center" rowSpan={row.deuda?.length + 1}>
                        {row.viabilidad}
                      </TableCell>
                    </TableRow>
                    {row?.deuda?.map((rowItem, rowIndex) => {
                      const nameDeudaMin = `deudaMin_${row.idViabilidad}_${rowIndex}`;
                      const nameDeudaMax = `deudaMax_${row.idViabilidad}_${rowIndex}`;
                      return (
                        <TableRow key={nameDeudaMin}>
                          <TableCell align="center">
                            {rowItem.factor}
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="Inicio"
                              name={nameDeudaMin}
                              variant="outlined"
                              value={rowItem.deudaMin === null ? '' : rowItem.deudaMin}
                              size="small"
                              onChange={(e) => {
                                if (e.target.value.length <= 2) {
                                  rowItem.deudaMin = removeZero(e.target.value, nameDeudaMin);
                                  clearErrors(nameDeudaMin);
                                } else {
                                  setError(nameDeudaMin, { type: 'maxLength', message: maxPct });
                                  setValue(nameDeudaMin, rowItem.deudaMin);
                                }
                              }}
                              onBlur={() => {
                                clearErrors(nameDeudaMin);
                              }}
                              onInput={genService.removeLetters}
                              error={!!errors[nameDeudaMin]}
                              helperText={errors[nameDeudaMin]?.message}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="Fin"
                              variant="outlined"
                              size="small"
                              onChange={(e) => {
                                removeZero(e.target.value, nameDeudaMax);
                                if (e.target.value.length <= 2) {
                                  rowItem.deudaMax = removeZero(e.target.value, nameDeudaMax);
                                  clearErrors(nameDeudaMax);
                                } else {
                                  setError(nameDeudaMax, { type: 'maxLength', message: maxPct });
                                  setValue(nameDeudaMax, rowItem.deudaMax);
                                }
                              }}
                              value={rowItem.deudaMax ? rowItem.deudaMax : ''}
                              name={nameDeudaMax}
                              onInput={genService.removeLetters}
                              onBlur={() => {
                                clearErrors(nameDeudaMax);
                              }}
                              error={!!errors[nameDeudaMax]}
                              helperText={errors[nameDeudaMax]?.message}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </td>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </form>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  tabValue: PropTypes.number,
  setTabValue: PropTypes.func,
  evaluacion: PropTypes.object,
  comboViviendaA: PropTypes.array,
  comboViviendaB1: PropTypes.array,
  comboViviendaB2: PropTypes.array,
  comboViviendaB3: PropTypes.array,
  comboViviendaC: PropTypes.array,
  rolUsuario: PropTypes.string,
  setOpenAddDialog: PropTypes.func,
  setTituloDialog: PropTypes.func,
  setItemDTO: PropTypes.func,
  comboPrincipalA: PropTypes.array,
  comboPrincipalB1: PropTypes.array,
  comboPrincipalB2: PropTypes.array,
  comboPrincipalB3: PropTypes.array,
  comboPrincipalC: PropTypes.array,
  setOpenAddDialogPrin: PropTypes.func,
  formName: PropTypes.string,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  valComboPrincipal: PropTypes.array,
  valComboVivienda: PropTypes.array,
  setValComboPrincipal: PropTypes.func,
  setValComboVivienda: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  setEvaluacion: PropTypes.func,
  valComboEscuelas: PropTypes.array,
  setValComboEscuelas: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  resetPlantilla: PropTypes.object,
  setResetPlantilla: PropTypes.func
};

export default Details;
