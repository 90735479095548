import React from 'react';

// Andale Brahiam, arregla este logo.
const LogoMini = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logoMini.svg"
      {...props}
    />
  );
};

export default LogoMini;
