import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ordenService from 'src/services/ordenService';
import moment from 'moment';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import DialogForms from 'src/views/commons/DialogForms';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import genService from 'src/services/generalServices';
import { FiberManualRecord } from '@material-ui/icons';

const valEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  spaces: {
    padding: theme.spacing(2),
    backgroundColor: '#e7ebf0'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#4caf508a'
  },
  list: {
    width: 300,
    height: 353,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  listLeft: {
    width: 300,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const NotificacionOrden = ({
  setOpenNotificacion,
  openNotificacion,
  left,
  setLeft,
  right,
  setRight,
  setOpenSnackError,
  setOpenSnackSuccess,
  setServerMessage,
  setServerError,
  emailData
}) => {
  const {
    errors,
    setError,
    setValue,
    register,
    clearErrors
  } = useForm();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [openBack, setOpenBack] = useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const sendNotification = async () => {
    console.log('Enviar email', emailData);
    setOpenBack(true);
    await ordenService.enviarEmailOrdenEstauts(emailData, right).then(() => {
      setServerMessage(`El correo para la orden ${emailData.folio} con estatus ${emailData.descOrdenEstatus}, se ha enviado correctamente.`);
      setOpenSnackSuccess(true);
    }).catch((error) => {
      console.log('Error en el envío de correo:', error.response);
      if (error.response) {
        setServerError(error.response.data.message);
      } else {
        setServerError(`${error.message}: No fue posible conectar con el servidor`);
      }
      setOpenSnackError(true);
      setOpenBack(false);
    });
    setOpenNotificacion(false);
    setOpenBack(false);
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={(
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        )}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} seleccionado`}
      />
      <Divider />
      { title === 'Agregar' ? (
        <TextField
          variant="standard"
          label="Agregar correo"
          name="newEmail"
          fullWidth
          inputRef={register}
          onKeyUp={(e) => {
            const mailVal = e.target.value;
            if (errors.newEmail && valEmail.test(mailVal)) {
              clearErrors('newEmail');
            }
            if (e.key === ' ') {
              setValue('newEmail', mailVal.trim());
            }
            if (e.key === 'Enter') {
              if (valEmail.test(mailVal)) {
                const email = {
                  email: mailVal
                };
                left.push(email);
                setLeft(left);
                handleCheckedLeft();
                setValue('newEmail', '');
              } else {
                setError('newEmail', { type: 'custom', message: 'Formato de correo inválido.' });
              }
            }
          }}
          onInput={genService.toLowerCase}
          error={!!errors.newEmail}
          helperText={errors.newEmail?.message}
        />
      ) : (
        null
      )}
      <List
        className={title === 'Agregar' ? classes.list : classes.listLeft}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  size="small"
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={(
                  <div>
                    {/* <Typography variant="overline" display="block">
                      <b>{value.usuario}</b>
                    </Typography> */}
                    <Typography variant="caption" display="block">
                      {value.email}
                    </Typography>
                  </div>
                )}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  return (
    <div>
      <DialogForms
        setOpenDialog={setOpenNotificacion}
        openDialog={openNotificacion}
        funcButtonName="ENVIAR"
        titulo="Enviar Notificación"
        disableButtonFunc={right.length === 0}
        functionButton={sendNotification}
        size="md"
      >
        <Card className={classes.spaces}>
          <Typography variant="body1">
            {'La orden de trabajo con folio '}
            <b>{emailData.folio}</b>
            {' se encuentra en estatus '}
            <b>{emailData.descOrdenEstatus}</b>
            .
            <List>
              {/* <ListItem>
                <ListItemIcon>
                  <FiberManualRecord fontSize="small" style={{ fill: '#000000' }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h4">
                    <b>{emailData.descOrdenEstatus}</b>
                  </Typography>
                </ListItemText>
              </ListItem> */}
              {emailData.descOrdenEstatus === 'CITA'
                ? (
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecord fontSize="small" style={{ fill: '#000000' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="h4">
                        <b>
                          {moment(new Date(emailData.fechaCita)).format('YYYY-MM-DD HH:mm:ss')}
                        </b>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ) : (
                  null
                )}
              {emailData.descOrdenEstatus === 'SUSPENDIDO'
                ? (
                  <ListItem>
                    <ListItemIcon>
                      <FiberManualRecord fontSize="small" style={{ fill: '#000000' }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="caption">
                        {'COMENTARIOS: '}
                        <b>
                          {emailData.comentarios !== undefined && emailData.comentarios !== ''
                            ? (
                              emailData.comentarios
                            ) : (
                              'SIN COMENTARIOS'
                            )}
                        </b>
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ) : (
                  null
                )}
            </List>
          </Typography>
          <Typography variant="caption">
            * Si desea cambiar el estatus de la orden aseguresé
            de guardar los cambios previo al envío de la notificacion.
          </Typography>
          <br />
          <br />
          <Typography variant="body1">
            Puede envíar la notificación a las siguientes direcciones:
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item>{customList('Agregar', left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  aria-label="Mover a Enviar"
                  disabled={leftChecked.length === 0}
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  aria-label="Mover a Agregar"
                  disabled={rightChecked.length === 0}
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList('Enviar', right)}</Grid>
          </Grid>
        </Card>
        <GeneralBackDrop open={openBack} />
      </DialogForms>
    </div>
  );
};

NotificacionOrden.propTypes = {
  setOpenNotificacion: PropTypes.func,
  openNotificacion: PropTypes.bool,
  left: PropTypes.array,
  setLeft: PropTypes.func,
  right: PropTypes.array,
  setRight: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  setServerError: PropTypes.func,
  emailData: PropTypes.object
};
export default NotificacionOrden;
