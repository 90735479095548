import http from '../http-common';

class ReporteService {
  generateReport = (data) => {
    const config = {
      responseType: 'blob'
    };
    const json = JSON.stringify(data);
    return http.put('reporte/generar', json, config);
  };
}

export default new ReporteService();
