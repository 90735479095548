import React from 'react';
import {
  Box,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  ClickAwayListener
} from '@material-ui/core';
import {
  Add,
  Search,
  TuneTwoTone as Menu,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import ordenService from 'src/services/ordenService';
import { format } from 'date-fns';
import BusquedaAvOT from './BusquedaAvOT';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paperRoot: {
    padding: '2px 6px',
    display: 'flex'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const OrdenesToolBar = ({
  className,
  setOpenAltaOrden,
  setOpenBackDrop,
  setOpenSnackError,
  setServerError,
  setRowsOrdenes,
  rolUsuario,
  comboOrdenEstatus,
}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const paperRef = React.useRef();
  const openPopper = Boolean(anchorEl);
  const [fechaDesde, setFechaDesde] = React.useState(null);
  const [fechaHasta, setFechaHasta] = React.useState(null);

  const handleClickAway = () => {
    setAnchorEl(null);
    setFechaDesde(null);
    setFechaHasta(null);
  };
  const handleClickPopper = () => {
    setAnchorEl(anchorEl ? null : paperRef.current);
    reset({});
  };
  const onSubmit = (formulario, searchType) => {
    console.log('Formulario busqueda de ordenes:', formulario);
    const asyncCall = async (datos, type) => {
      setOpenBackDrop(true);
      if (fechaDesde !== null && fechaDesde !== '') {
        datos.desde = format(fechaDesde, 'yyyy-MM-dd');
      }
      if (fechaHasta !== null && fechaHasta !== '') {
        datos.hasta = format(fechaHasta, 'yyyy-MM-dd');
      }
      await ordenService.searchOrdenes(datos, false).then((resp) => {
        setRowsOrdenes(resp.data);
        if (resp.data.length > 0 && type === 'adv') {
          handleClickPopper();
        }
      }).catch((error) => {
        setRowsOrdenes([]);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario, searchType).catch((error) => {
      setRowsOrdenes([]);
      const mensajeError = `Error al realiza la búsqueda: ${error.message}`;
      setServerError(mensajeError);
      setOpenSnackError(true);
      setOpenBackDrop(false);
    });
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.title}>
        Busqueda de Ordenes
      </Typography>
      <Divider className={classes.bottomSpace} />
      {rolUsuario === 'CLIENTE'
        ? (
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Agregar">
              <Fab
                color="primary"
                onClick={() => setOpenAltaOrden(true, 0)}
                className={classes.bottomSpace}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Box>
        ) : (
          null
        )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid
            className={classes.item}
            item
            md={6}
            sm={12}
            xs={12}
            align="center"
          >
            <Paper
              ref={paperRef}
              component="form"
              className={classes.paperRoot}
              onSubmit={handleSubmit((data) => onSubmit(data, 'single'))}
            >
              <InputBase
                inputRef={register}
                className={classes.input}
                placeholder="Folio"
                inputProps={{ 'aria-label': 'buscar usuario' }}
                name="folio"
                onFocus={handleClickAway}
                onInput={genService.toUpperCase}
              />
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                type="submit"
              >
                <Search />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton className={classes.iconButton} aria-label="menu" onClick={handleClickPopper}>
                <Menu />
              </IconButton>
            </Paper>
          </Grid>
          <BusquedaAvOT
            openPopper={openPopper}
            anchorEl={anchorEl}
            setFechaDesde={setFechaDesde}
            setFechaHasta={setFechaHasta}
            fechaDesde={fechaDesde}
            fechaHasta={fechaHasta}
            onSubmitAdv={onSubmit}
            comboOrdenEstatus={comboOrdenEstatus}
          />
        </Grid>
      </ClickAwayListener>
    </div>
  );
};
OrdenesToolBar.propTypes = {
  className: PropTypes.string,
  setOpenAltaOrden: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  setRowsOrdenes: PropTypes.func,
  rolUsuario: PropTypes.string,
  comboOrdenEstatus: PropTypes.array
};

export default OrdenesToolBar;
