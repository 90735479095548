import React from 'react';
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import genService from 'src/services/generalServices';
import PropTypes from 'prop-types';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Controller } from 'react-hook-form';

const OrderGeneral = ({
  register,
  folio,
  errors,
  isLaboral,
  isEscuela,
  idOrden,
  comboGradoEscolar,
  valGradoEscolar,
  setValGradoEscolar,
  setValue,
  setError,
  clearErrors,
  optionsCliente,
  valComboClientes,
  setValComboClientes,
  setIsEscuela,
  setIsLaboral,
  getComboGradoEscolar,
  getComboUsuarioCliente,
  comboUsuarios,
  valComboUsuarios,
  setValComboUsuarios,
  rolUsuario,
  comboEstados,
  comboMunicipios,
  getComboMunicipios,
  control,
  // fechaCitaStr,
  // ordenEstatus
}) => {
  const errorReq = 'Campo requerido.';
  const filtro = createFilterOptions();
  const onlyRead = idOrden > 0 && (rolUsuario !== 'ADMINISTRADOR' && rolUsuario !== 'ANALISTA');

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            label="Folio"
            name="folio"
            value={folio || ''}
            inputRef={register}
            inputProps={{
              readOnly: true
            }}
            variant="outlined"
            onLoad={genService.toUpperCase}
            error={errors.nombreCliente}
            helperText={errors.nombreCliente?.message}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      {rolUsuario === 'ADMINISTRADOR'
        ? (
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              {idOrden === 0
                ? (
                  <Autocomplete
                    id="combo-box-demo"
                    options={optionsCliente.sort((a, b) => (
                      -b.firstLetter.localeCompare(a.firstLetter)
                    ))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => (option.label !== undefined ? `${option.label} - (${option.tipoCliente})` : '')}
                    renderOption={(option) => option.label}
                    fullWidth
                    value={valComboClientes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regCliente"
                        inputRef={register({
                          required: { value: true, message: errorReq }
                        })}
                        label="Seleccione un Cliente *"
                        variant="outlined"
                        size="small"
                        error={errors.regCliente}
                        helperText={errors.regCliente?.message}
                        onInput={genService.toUpperCase}
                      />
                    )}
                    onChange={(event, newValue) => {
                      console.log('nuevo valor del combo cliente', newValue);
                      errors.regCliente = null;
                      console.log(newValue);
                      setValComboClientes(newValue);
                      setIsLaboral(false);
                      setIsEscuela(false);
                      if (newValue !== null && newValue.tipoCliente === 'Laboral') {
                        setIsLaboral(true);
                      }
                      if (newValue !== null && newValue.tipoCliente === 'Escuela') {
                        setIsEscuela(true);
                        getComboGradoEscolar(newValue.value, undefined);
                      }
                      getComboUsuarioCliente(newValue.value, null);
                      console.log('Combo de usuarios:', comboUsuarios);
                    }}
                  />
                ) : (
                  <TextField
                    name="regCliente"
                    value={`${valComboClientes.label} - (${valComboClientes.tipoCliente})`}
                    variant="outlined"
                    size="small"
                    label="Seleccione un Cliente"
                    fullWidth
                    inputProps={{
                      readOnly: true
                    }}
                  />
                )}
            </Grid>
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={comboUsuarios}
                getOptionLabel={(option) => (option.usuario !== undefined ? `${option.usuario} - (${option.nombreUsuario})` : '')}
                renderOption={(option) => `${option.usuario} - (${option.nombreUsuario})`}
                fullWidth
                value={valComboUsuarios}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="regUsuario"
                    inputRef={register({
                      required: { value: true, message: errorReq }
                    })}
                    label="Seleccione un Usuario *"
                    variant="outlined"
                    size="small"
                    error={errors.regUsuario}
                    helperText={errors.regUsuario?.message}
                    onInput={genService.toUpperCase}
                  />
                )}
                onChange={(event, newValue) => {
                  errors.regUsuario = null;
                  console.log(newValue);
                  setValComboUsuarios(newValue);
                }}
              />
            </Grid>
          </Grid>
        ) : (
          null
        )}
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            label="Nombre *"
            name="nombre"
            inputRef={register({
              required: { value: true, message: errorReq },
              maxLength: { value: 255, message: 'Máximo 255 carácteres.' }
            })}
            inputProps={{
              readOnly: onlyRead
            }}
            variant="outlined"
            onInput={genService.toUpperCase}
            error={errors.nombre}
            helperText={errors.nombre?.message}
            fullWidth
            size="small"
          />
        </Grid>
        {isLaboral
          ? (
            <Grid item md={6} xs={12}>
              <TextField
                label="Puesto *"
                name="puesto"
                inputRef={register({
                  required: { value: isLaboral, message: errorReq },
                  maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                })}
                variant="outlined"
                inputProps={{
                  readOnly: onlyRead
                }}
                onInput={genService.toUpperCase}
                error={errors.puesto}
                helperText={errors.puesto?.message}
                fullWidth
                size="small"
              />
            </Grid>
          ) : (
            null
          )}
        {isEscuela
          ? (
            <Grid item md={6} xs={12}>
              <TextField
                label="Matrícula"
                name="matricula"
                inputRef={register({
                  /* required: { value: isEscuela, message: errorReq }, */
                  maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                })}
                variant="outlined"
                inputProps={{
                  readOnly: onlyRead
                }}
                onInput={genService.toUpperCase}
                error={errors.matricula}
                helperText={errors.matricula?.message}
                fullWidth
                size="small"
              />
            </Grid>
          ) : (
            null
          )}
      </Grid>
      {isEscuela
        ? (
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} style={{ display: isEscuela ? '' : 'none' }}>
              { (!onlyRead && rolUsuario !== 'NO_ROL')
                ? (
                  <Autocomplete
                    id="combo-box-demo"
                    options={comboGradoEscolar}
                    filterOptions={(options, params) => {
                      const filtered = filtro(options, params);
                      if (params.inputValue.trim() !== '' && rolUsuario === 'ADMINISTRADOR') {
                        filtered.push({
                          value: 0,
                          labelRender: `Agregar: "${params.inputValue.trim()}"`,
                          label: params.inputValue.trim()
                        });
                      }
                      return filtered;
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      const existValue = comboGradoEscolar.filter((opt) => (
                        opt.label === option.label
                      ));
                      if (existValue.length === 0 && option.value !== undefined) {
                        comboGradoEscolar.push({
                          value: option.value,
                          label: option.label.trim(),
                          labelRender: `${option.label.trim()} - NUEVO`
                        });
                        return (option.label ? option.label : '');
                      }
                      return (option.label ? option.label.trim() : '');
                    }}
                    renderOption={(option) => (
                      option.labelRender
                        ? option.labelRender : option.label
                    )}
                    value={valGradoEscolar}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    freeSolo
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="gradoEscolar"
                        inputRef={register({
                          required: { value: false, message: errorReq }
                        })}
                        label="Seleccione un Grado Escolar"
                        variant="outlined"
                        size="small"
                        error={errors.gradoEscolar}
                        helperText={errors.gradoEscolar?.message}
                        onInput={genService.toUpperCase}
                      />
                    )}
                    onChange={(event, newValue) => {
                      errors.gradoEscolar = null;
                      console.log('valor del grado escolar', newValue);
                      setValGradoEscolar(newValue);
                    }}
                  />
                ) : (
                  rolUsuario === 'CLIENTE'
                    ? (
                      <TextField
                        name="gradoEscolarTxt"
                        label="Grado Escolar"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={valGradoEscolar.label}
                        inputProps={{
                          readOnly: onlyRead
                        }}
                      />
                    ) : (
                      null
                    )
                )}
            </Grid>
            <Grid item md={2} xs={6}>
              <TextField
                label="% de Beca"
                name="pctBeca"
                defaultValue={0}
                inputRef={register({
                  /* required: { value: isEscuela, message: errorReq }, */
                  maxLength: { value: 3, message: 'Máximo 3 carácteres.' }
                })}
                onChange={(newVal) => {
                  if (newVal.target.value !== undefined
                    && newVal.target.value > 100) {
                    setError('pctBeca', { type: 'custom', message: 'Entre 0 y 100' });
                    setValue('pctBeca', 100);
                  } else {
                    clearErrors();
                  }
                }}
                inputProps={{
                  readOnly: onlyRead
                }}
                variant="outlined"
                onInput={genService.removeLetters}
                error={errors.pctBeca}
                helperText={errors.pctBeca?.message}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>
        ) : (
          null
        )}
      <br />
      <Divider />
      <br />
      { rolUsuario !== 'NO_ROL'
        ? (
          <div>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Calle"
                  name="direccion.calle"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.direccion?.calle}
                  helperText={errors.direccion?.calle?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: onlyRead
                  }}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  label="Número"
                  name="direccion.numero"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caractéres.' },
                  })}
                  onInput={genService.removeLetters}
                  error={errors.direccion?.numero}
                  helperText={errors.direccion?.numero?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: onlyRead
                  }}
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  label="Núm Int."
                  name="direccion.numeroInt"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.direccion?.numeroInt}
                  helperText={errors.direccion?.numeroInt?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: onlyRead
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="idEstado-select">
                    {
                      errors.idEstado
                        ? <div style={{ color: '#f44336' }}>Seleccione un Estado</div>
                        : <div>Seleccione un Estado</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="direccion.idEstado"
                    rules={{ required: { value: false, message: errorReq } }}
                    error={errors.idEstado}
                    defaultValue={9}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="idEstado-select"
                        label="Seleccione un Estado"
                        value={value}
                        onChange={(evento, newValue) => {
                          getComboMunicipios(newValue.props.value);
                          onChange(newValue.props.value);
                        }}
                        inputProps={{
                          readOnly: onlyRead
                        }}
                      >
                        {comboEstados.map((edo) => (
                          <MenuItem
                            key={edo.value}
                            value={edo.value}
                          >
                            {edo.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {
                    errors.direccion?.idEstado
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.direccion?.idEstado.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel htmlFor="idMunicipio-select">
                    {
                      errors.idMunicipio
                        ? <div style={{ color: '#f44336' }}>Seleccione un Municipio</div> : <div>Seleccione un Municipio</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="direccion.idMunicipio"
                    rules={{ required: { value: false, message: errorReq } }}
                    error={errors.idMunicipio}
                    defaultValue=""
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="idMunicipio-select"
                        label="Seleccione un Municipio"
                        value={value}
                        onChange={(evento, newValue) => {
                          onChange(newValue.props.value);
                        }}
                        inputProps={{
                          readOnly: onlyRead
                        }}
                      >
                        {comboMunicipios.length === 0
                          ? (
                            <MenuItem
                              key={0}
                              value={0}
                            >
                              Elija un Estado
                            </MenuItem>
                          ) : (
                            comboMunicipios.map((mun) => (
                              <MenuItem
                                key={mun.value}
                                value={mun.value}
                              >
                                {mun.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                  {
                    errors.direccion?.idMunicipio
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.direccion?.idMunicipio.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Colonia"
                  name="direccion.colonia"
                  inputRef={register({
                    maxLength: { value: 125, message: 'Máximo 125 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.direccion?.colonia}
                  helperText={errors.direccion?.colonia?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: onlyRead
                  }}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  label="Código Postal"
                  name="direccion.cp"
                  inputRef={register({
                    maxLength: { value: 5, message: 'Máximo 5 caractéres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  onInput={genService.removeLetters}
                  error={errors.direccion?.cp}
                  helperText={errors.direccion?.cp?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: onlyRead
                  }}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <TextField
                  label="País"
                  name="direccion.pais"
                  defaultValue="MEXICO"
                  inputRef={register({
                    maxLength: { value: 125, message: 'Máximo 125 caractéres.' }
                  })}
                  inputProps={{
                    readOnly: true
                  }}
                  onInput={genService.toUpperCase}
                  error={errors.direccion?.pais}
                  helperText={errors.direccion?.pais?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </div>
        ) : (
          null
        )}
      <Grid container spacing={3}>
        <Grid item md={4} xs={8}>
          <TextField
            label="Tel. Primario *"
            name="telefonoPrimario"
            inputRef={register({
              required: { value: true, message: errorReq },
              maxLength: { value: 20, message: 'Máximo 20 caracteres.' },
              pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
            })}
            variant="outlined"
            onInput={genService.removeLetters}
            error={errors.telefonoPrimario}
            helperText={errors.telefonoPrimario?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <TextField
            label="Ext."
            name="extPrimaria"
            inputRef={register({
              maxLength: { value: 6, message: 'Máximo 6 caracteres.' },
              pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
            })}
            variant="outlined"
            onInput={genService.removeLetters}
            error={errors.extPrimaria}
            helperText={errors.extPrimaria?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
        <Grid item md={4} xs={8}>
          <TextField
            label="Tel. Adicional"
            name="telefonoSecundario"
            ref={register}
            inputRef={register({
              maxLength: { value: 20, message: 'Máximo 20 caracteres.' },
              pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
            })}
            variant="outlined"
            onInput={genService.removeLetters}
            error={errors.telefonoSecundario}
            helperText={errors.telefonoSecundario?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <TextField
            label="Ext."
            name="extSecundaria"
            inputRef={register({
              maxLength: { value: 6, message: 'Máximo 6 caracteres.' },
              pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
            })}
            variant="outlined"
            onInput={genService.removeLetters}
            error={errors.extSecundaria}
            helperText={errors.extSecundaria?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            label={isLaboral ? 'Email Primario' : 'Email Primario *'}
            name="emailPrimario"
            inputRef={register({
              required: { value: !isLaboral, message: errorReq },
              maxLength: { value: 255, message: 'Máximo 255 caracteres.' },
              pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
            })}
            variant="outlined"
            onInput={genService.toLowerCase}
            error={errors.emailPrimario}
            helperText={errors.emailPrimario?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Email Adicional"
            name="emailAdicional"
            ref={register}
            inputRef={register({
              maxLength: { value: 255, message: 'Máximo 255 caracteres.' },
              pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
            })}
            variant="outlined"
            onInput={genService.toLowerCase}
            error={errors.emailAdicional}
            helperText={errors.emailAdicional?.message}
            fullWidth
            size="small"
            inputProps={{
              readOnly: onlyRead
            }}
          />
        </Grid>
      </Grid>
      { rolUsuario !== 'NO_ROL'
        ? (
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                label="Observaciones"
                name="observaciones"
                inputRef={register}
                variant="outlined"
                fullWidth
                size="small"
                multiline
                minRows={3}
                onInput={genService.toUpperCase}
                inputProps={{
                  readOnly: onlyRead
                }}
              />
            </Grid>
          </Grid>
        ) : (
          null
        )}
    </div>
  );
};

OrderGeneral.propTypes = {
  register: PropTypes.func,
  folio: PropTypes.string,
  errors: PropTypes.object,
  isLaboral: PropTypes.bool,
  isEscuela: PropTypes.bool,
  idOrden: PropTypes.number,
  comboGradoEscolar: PropTypes.array,
  valGradoEscolar: PropTypes.object,
  setValGradoEscolar: PropTypes.func,
  setValue: PropTypes.func,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  optionsCliente: PropTypes.array,
  valComboClientes: PropTypes.object,
  setValComboClientes: PropTypes.func,
  setIsEscuela: PropTypes.func,
  setIsLaboral: PropTypes.func,
  getComboGradoEscolar: PropTypes.func,
  getComboUsuarioCliente: PropTypes.func,
  comboUsuarios: PropTypes.array,
  valComboUsuarios: PropTypes.object,
  setValComboUsuarios: PropTypes.func,
  rolUsuario: PropTypes.string,
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  getComboMunicipios: PropTypes.func,
  control: PropTypes.object,
  // ordenEstatus: PropTypes.string,
  // fechaCitaStr: PropTypes.string
};

export default OrderGeneral;
