import React from 'react';
import { Navigate } from 'react-router-dom';
import tokenService from 'src/services/token.service';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  component: Component,
  isAuth,
  ...props
}) => {
  return (
    (isAuth && tokenService.getToken())
      ? (
        <Component {...props} isAuth={isAuth} />
      ) : <Navigate to="/" />
  );
};
PrivateRoute.propTypes = {
  component: PropTypes.func,
  isAuth: PropTypes.bool
};
/*
PrivateRoute.propTypes = {
  Component: PropTypes.func,
  isAuth: PropTypes.bool,
  nombreUsuario: PropTypes.string,
  rolUsuario: PropTypes.string,
  setIsAuth: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  comboRoles: PropTypes.array,
  setComboRoles: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  setComboMunicipios: PropTypes.func,
  mapaMunicipios: PropTypes.array,
  setMapaMunicipios: PropTypes.func,
  comboTipoClientes: PropTypes.array,
  setComboTipoClientes: PropTypes.func,
  setComboOrdenEstatus: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  setComboHiperliga: PropTypes.func,
  comboHiperliga: PropTypes.array
};
*/

export default PrivateRoute;
