import React, {
  useState, forwardRef, useImperativeHandle
} from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  TextField,
  makeStyles,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import GeneralDialog from 'src/views/commons/GeneralDialog';
import genService from 'src/services/generalServices';
import evaluacionService from 'src/services/evaluacionService';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';

const useStyles = makeStyles((theme) => ({
  root: {},
  spaceButton: {
    marginRight: theme.spacing(1)
  },
  closeIcon: {
    float: 'right'
  },
  checkBoxVerde: {
    color: '#4caf50 !important'
  }
}));

const AltaPrincipal = forwardRef(({
  className,
  formName,
  setOpenDialog,
  itemDTO,
  setOpenSnackError,
  setServerError,
  comboPrincipalA,
  comboPrincipalB1,
  comboPrincipalB2,
  comboPrincipalB3,
  comboPrincipalC,
  setComboPrincipalA,
  setComboPrincipalB1,
  setComboPrincipalB2,
  setComboPrincipalB3,
  setComboPrincipalC
}, ref) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setError
  } = useForm({
    defaultValues: {
      rol: ''
    },
  });
  const [open, setOpen] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const resetForm = () => {
    reset({
      descripcion: ''
    });
  };
  useImperativeHandle(ref, () => ({
    resetUsuariosForm: () => {
      resetForm();
    }
  }));
  const onSubmit = (data) => {
    let selectedCombo;
    let selectedSetCombo;
    let existValue;
    itemDTO.descripcion = data.descripcion.trim();
    setOpenBack(true);

    if (itemDTO.viabilidad === 'A / 81-100') {
      existValue = comboPrincipalA.filter((opt) => (
        opt.label === itemDTO.descripcion
      ));
      selectedCombo = comboPrincipalA;
      selectedSetCombo = setComboPrincipalA;
    } else if (itemDTO.viabilidad === 'B-1 / 61-80') {
      existValue = comboPrincipalB1.filter((opt) => (
        opt.label === itemDTO.descripcion
      ));
      selectedCombo = comboPrincipalB1;
      selectedSetCombo = setComboPrincipalB1;
    } else if (itemDTO.viabilidad === 'B-2 / 41-60') {
      existValue = comboPrincipalB2.filter((opt) => (
        opt.label === itemDTO.descripcion
      ));
      selectedCombo = comboPrincipalB2;
      selectedSetCombo = setComboPrincipalB2;
    } else if (itemDTO.viabilidad === 'B-3 / 21-40') {
      existValue = comboPrincipalB3.filter((opt) => (
        opt.label === itemDTO.descripcion
      ));
      selectedCombo = comboPrincipalB3;
      selectedSetCombo = setComboPrincipalB3;
    } else if (itemDTO.viabilidad === 'C / 0-20') {
      existValue = comboPrincipalC.filter((opt) => (
        opt.label === itemDTO.descripcion
      ));
      selectedCombo = comboPrincipalC;
      selectedSetCombo = setComboPrincipalC;
    }

    if (existValue !== undefined && existValue.length > 0) {
      setError('descripcion', { message: `Este valor ya existe en ${itemDTO.viabilidad}` });
      setOpenBack(false);
    } else if (data.descripcion.trim() === '') {
      setError('descripcion', { message: 'El valor no puede estar vacio.' });
      setOpenBack(false);
    } else {
      const handleSubmitII = async (datos) => {
        await evaluacionService.guardarPrincipal(datos).then((resp) => {
          if (resp !== null) {
            selectedCombo.push({
              descViabilidad: resp.data.viabilidad,
              idViabilidad: resp.data.idViabilidad,
              label: resp.data.descripcion,
              value: resp.data.id
            });
            selectedSetCombo(selectedCombo);
            setOpen(true);
          } else {
            setServerError('No se obtuvo respuesta del servidor');
            setOpenSnackError(true);
          }
        }).catch((error) => {
          setOpenBack(false);
          setServerError(error.message);
          setOpenSnackError(true);
        });
        setOpenBack(false);
      };
      handleSubmitII(itemDTO);
    }
  };
  const classes = useStyles();
  const errorReq = 'Campo requerido.';

  return (
    <form
      id={formName}
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                type="text"
                fullWidth
                label="Descripción Principal *"
                name="descripcion"
                inputRef={register({
                  required: { value: true, message: errorReq },
                  maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                })}
                variant="outlined"
                onInput={genService.toUpperCase}
                error={errors.descripcion}
                helperText={errors.descripcion?.message}
                size="small"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <GeneralBackDrop open={openBack} />
      <GeneralDialog
        open={open}
        changeState={() => {
          setOpen(false);
          setOpenDialog(false);
        }}
        title="Principal Guardado"
        message="El registro Principal fue guardada exitosamente."
      />
    </form>
  );
});

AltaPrincipal.propTypes = {
  className: PropTypes.string,
  formName: PropTypes.string,
  setOpenDialog: PropTypes.func,
  itemDTO: PropTypes.object,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  comboPrincipalA: PropTypes.array,
  comboPrincipalB1: PropTypes.array,
  comboPrincipalB2: PropTypes.array,
  comboPrincipalB3: PropTypes.array,
  comboPrincipalC: PropTypes.array,
  setComboPrincipalA: PropTypes.func,
  setComboPrincipalB1: PropTypes.func,
  setComboPrincipalB2: PropTypes.func,
  setComboPrincipalB3: PropTypes.func,
  setComboPrincipalC: PropTypes.func,
};

export default AltaPrincipal;
