import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  Collapse,
  List
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  blockItem: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  subTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: '40px'
  },
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  buttonType,
  buttonMap,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const notCollapse = (event) => {
    event.stopPropagation();
  };

  return (
    <div>
      {
        buttonType === 'boton'
          ? (
            <ListItem
              className={clsx(classes.item, className)}
              disableGutters
              {...rest}
              component="div"
            >
              <Button
                activeclassname={classes.active}
                className={classes.button}
                component={RouterLink}
                to={href}
              >
                {Icon && (
                  <Icon
                    className={classes.icon}
                    size="20"
                  />
                )}
                <span className={classes.title}>
                  {title}
                </span>
              </Button>
            </ListItem>
          )
          : (
            <ListItem
              onClick={handleClick}
              className={clsx(classes.blockItem, className)}
              disableGutters
              {...rest}
              component="div"
            >
              <Button
                activeclassname={classes.active}
                className={classes.button}
              >
                {Icon && (
                  <Icon
                    className={classes.icon}
                    size="20"
                  />
                )}
                <span className={classes.title}>
                  {title}
                </span>
                {open ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem
                    className={clsx(classes.blockItem, className)}
                    disableGutters
                  >
                    {buttonMap.map((subItem) => (
                      <Button
                        key={`'button_'${subItem.title}`}
                        activeclassname={classes.active}
                        className={classes.button}
                        component={RouterLink}
                        to={subItem.href}
                        onClick={notCollapse}
                      >
                        <span className={classes.subTitle}>
                          {subItem.title}
                        </span>
                      </Button>
                    ))}
                  </ListItem>
                </List>
              </Collapse>
            </ListItem>
          )
      }
    </div>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  buttonType: PropTypes.string,
  buttonMap: PropTypes.array
};

export default NavItem;
