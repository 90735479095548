import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({
  ...props
}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const {
    isAuth,
    nombreCliente,
    setNombreCliente,
    resetPassword,
    setComboHiperliga,
    comboHiperliga,
    setComboOrdenEstatus,
    comboOrdenEstatus,
    comboEstados,
    setComboEstados,
    setRolUsuario,
    setNombreUsuario,
    nombreUsuario,
    rolUsuario,
    ...rest
  } = props;

  /* useEffect(() => {
    console.log('inicio del dashboard');
    console.log('Props', props);
    console.log('Rest', rest);
  }, []); */

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} {...rest} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        {...props}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
DashboardLayout.propTypes = {
  isAuth: PropTypes.bool,
  nombreCliente: PropTypes.string,
  setNombreCliente: PropTypes.func,
  resetPassword: PropTypes.string,
  setComboHiperliga: PropTypes.func,
  comboHiperliga: PropTypes.array,
  setComboOrdenEstatus: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  comboEstados: PropTypes.array,
  setComboEstados: PropTypes.func,
  setRolUsuario: PropTypes.func,
  setNombreUsuario: PropTypes.func,
  nombreUsuario: PropTypes.string,
  rolUsuario: PropTypes.string
};
export default DashboardLayout;
