import React, {
  useState, useEffect, forwardRef, useImperativeHandle
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  TextField,
  makeStyles,
  Card,
  CardContent,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import GeneralDialog from 'src/views/commons/GeneralDialog';
import genService from 'src/services/generalServices';
import userService from 'src/services/usuariosService';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';

const useStyles = makeStyles((theme) => ({
  root: {},
  spaceButton: {
    marginRight: theme.spacing(1)
  },
  closeIcon: {
    float: 'right'
  },
  checkBoxVerde: {
    color: '#4caf50 !important'
  }
}));

const AltaUsuario = forwardRef(({
  className,
  preloadedValues,
  setServerError,
  setOpenSnackError,
  formData,
  setFormData,
  rowsUsers,
  idUsuario,
  setIdUsuario,
  formName,
  comboClientes,
  setOpenDialog
}, ref) => {
  const {
    register,
    handleSubmit,
    control,
    errors,
    reset
  } = useForm({
    defaultValues: {
      rol: ''
    },
  });
  const [open, setOpen] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [valComboClientes, setValComboClientes] = useState({});
  const [isCliente, setIsCliente] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const resetForm = () => {
    reset({
      id: 0,
      rol: '',
      nombre: '',
      nombreUsuario: '',
      email: '',
      idCliente: '',
      regCliente: ''
    });
    setValComboClientes({});
    setIsCliente(false);
  };
  useImperativeHandle(ref, () => ({
    resetUsuariosForm: () => {
      resetForm();
    }
  }));
  const getItemComboCliente = (idCliente) => {
    setValComboClientes(comboClientes.find((comboValue) => comboValue.value === idCliente));
  };
  useEffect(() => {
    reset(formData);
    setIdUsuario(0);
    console.log(formData);
    if (formData.id !== undefined) {
      setIdUsuario(formData.id);
    }
    if (formData.idCliente !== null && formData.idCliente > 0) {
      getItemComboCliente(formData.idCliente);
      setIsCliente(true);
      setIsSupervisor(formData.supervisor);
    }
    setFormData({});
  }, []);
  const onSubmit = (data) => {
    const handleSubmitII = async (datos) => {
      console.log(datos);
      setOpenBack(true);
      await userService.altaUsuario(datos).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          if (idUsuario > 0) {
            const modUser = rowsUsers.find((row) => row.id === idUsuario);
            console.log(preloadedValues);
            datos.rolDesc = preloadedValues.find((item) => item.value === datos.rol).text;
            Object.assign(modUser, datos);
          }
          setOpen(true);
          resetForm();
        }
      }).catch((error) => {
        setOpenBack(false);
        setServerError(error.message);
        setOpenSnackError(true);
      });
      setOpenBack(false);
    };
    data.id = idUsuario;
    if (isCliente) {
      data.idCliente = valComboClientes.value;
    } else {
      data.idCliente = 0;
      data.supervisor = false;
    }
    handleSubmitII(data);
  };
  const classes = useStyles();
  const errorReq = 'Campo requerido.';

  return (
    <form
      id={formName}
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                type="text"
                fullWidth
                label="Nombre *"
                name="nombre"
                inputRef={register({
                  required: { value: true, message: errorReq },
                  maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                })}
                variant="outlined"
                onInput={genService.toUpperCase}
                error={errors.nombre}
                helperText={errors.nombre?.message}
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Usuario *"
                name="nombreUsuario"
                inputRef={register({
                  required: { value: true, message: errorReq },
                  maxLength: { value: 15, message: 'La longitud máxima es de 15 carácteres.' },
                  minLength: { value: 4, message: 'La longitud mínima es de 4 carácteres.' }
                })}
                variant="outlined"
                onInput={genService.trimTextUpperCase}
                error={errors.nombreUsuario}
                helperText={errors.nombreUsuario?.message}
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Correo Electrónico *"
                name="email"
                inputRef={register({
                  required: { value: true, message: errorReq },
                  pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
                })}
                variant="outlined"
                onInput={genService.toLowerCase}
                error={errors.email}
                helperText={errors.email?.message}
                size="small"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
                size="small"
                disabled={idUsuario > 0 && isCliente}
              >
                <InputLabel htmlFor="rol-select">
                  {
                    errors.rol
                      ? <div style={{ color: '#f44336' }}>Seleccione un Rol *</div> : <div>Seleccione un Rol *</div>
                  }
                </InputLabel>
                <Controller
                  control={control}
                  name="rol"
                  rules={{ required: { value: true, message: errorReq } }}
                  error={errors.rol}
                  onChange={([selected]) => {
                    return { value: selected };
                  }}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <Select
                      id="rol-select"
                      label="Seleccione un Rol *"
                      value={value}
                      onChange={(evento, newValue) => {
                        onChange(newValue.props.value);
                        if (newValue.props.value === 'ROLE_CLIENTE') {
                          setIsCliente(true);
                        } else {
                          setIsCliente(false);
                        }
                      }}
                    >
                      {preloadedValues.map((rol) => (
                        <MenuItem key={rol.value} value={rol.value}>{rol.text}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {
                  errors.rol
                  && (
                    <FormHelperText style={{ color: '#f44336' }}>
                      {errors.rol.message}
                    </FormHelperText>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item md={12} xs={12} style={{ display: isCliente ? '' : 'none' }}>
              <Autocomplete
                id="combo-box-demo"
                options={comboClientes}
                getOptionLabel={(option) => (option.label ? `${option.label} - (${option.tipoCliente})` : '')}
                renderOption={(option) => `${option.label} - (${option.tipoCliente})`}
                fullWidth
                value={valComboClientes}
                disabled={idUsuario > 0 && isCliente}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="regCliente"
                    inputRef={register({
                      required: { value: isCliente, message: errorReq }
                    })}
                    label="Seleccione un Cliente *"
                    variant="outlined"
                    size="small"
                    error={errors.regCliente}
                    helperText={errors.regCliente?.message}
                    onInput={genService.toUpperCase}
                  />
                )}
                onChange={(event, newValue) => {
                  errors.regCliente = null;
                  setValComboClientes(newValue);
                }}
              />
            </Grid>
            <Grid item md={12} xs={12} style={{ display: isCliente ? '' : 'none' }}>
              <FormControlLabel
                control={(
                  <Checkbox
                    className={classes.checkBoxVerde}
                    name="supervisor"
                    inputRef={register}
                    color="primary"
                    checked={isSupervisor}
                  />
                )}
                onChange={(e) => setIsSupervisor(e.target.checked)}
                label="Supervisor"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <GeneralBackDrop open={openBack} />
      <GeneralDialog
        open={open}
        changeState={() => {
          setOpen(false);
          setOpenDialog(false);
        }}
        title="Usuario Guardado"
        message="El usuario fue guardado exitosamente."
      />
    </form>
  );
});

AltaUsuario.propTypes = {
  className: PropTypes.string,
  preloadedValues: PropTypes.array,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  rowsUsers: PropTypes.array,
  idUsuario: PropTypes.number,
  setIdUsuario: PropTypes.func,
  formName: PropTypes.string,
  comboClientes: PropTypes.array,
  setOpenDialog: PropTypes.func
};

export default AltaUsuario;
