import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';

const ConfirmationDialog = (props) => {
  const {
    onClose,
    open,
    confirm,
    title,
    message
  } = props;
  return (
    <Dialog
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {message}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={confirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  confirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};
export default ConfirmationDialog;
