import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  makeStyles,
  Tooltip,
  IconButton,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import DialogForms from 'src/views/commons/DialogForms';
import PropTypes from 'prop-types';
import combosService from 'src/services/combosService';
import DataTable from 'src/views/commons/DataTable';
import { Edit } from '@material-ui/icons';
import clientesService from 'src/services/clientesService';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import ClientesToolBar from './ClientesToolBar';
import AltaCliente from './AltaCliente';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  }
}));

const Clientes = ({
  comboEstados,
  comboMunicipios,
  setComboMunicipios,
  mapaMunicipios,
  setMapaMunicipios,
  setServerError,
  setOpenSnackError,
  setServerMessage,
  setOpenSnackSuccess,
  comboTipoClientes,
  setComboTipoClientes,
  setOpenBackDrop
}) => {
  const classes = useStyles();
  const [openAltaCliente, setOpenAltaCliente] = useState(false);
  const [idCliente, setIdCliente] = useState(0);
  const [rowsClientes, setRowsClientes] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [formData, setFormData] = useState({});
  const formName = 'formCliente';
  const clienteRef = useRef();

  const resetClientesRef = () => {
    clienteRef.current.resetClientesForm();
  };

  useEffect(() => {
    const getTiposClientes = async () => {
      setOpenBackDrop(true);
      if (comboTipoClientes.length === 0) {
        await combosService.comboTipoCliente().then((resp) => {
          setComboTipoClientes(resp);
        }).catch((error) => {
          setOpenBackDrop(false);
          console.log(`'Error al cargar el combo de roles: '${error}`);
        });
      }
      setOpenBackDrop(false);
    };
    getTiposClientes();
  }, []);

  const onClickEdit = (paramRow) => {
    /* const roleCode = comboRoles.filter((item) => item.text === paramRow.rol);
    setFormData({
      id: paramRow.id,
      nombre: paramRow.nombre,
      nombreUsuario: paramRow.nombreUsuario,
      email: paramRow.email,
      rol: roleCode[0].value
    }); */
    const modifyCustomer = async (custData) => {
      setOpenBackDrop(true);
      await clientesService.getClienteById(custData.id).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          setFormData(resp.data);
          setOpenAltaCliente(true);
          console.log(resp.data);
        }
      }).catch((err) => {
        console.log(err);
        setServerError(err.message);
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBackDrop(false);
      });
    };
    console.log(paramRow);
    modifyCustomer(paramRow);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 90,
      minWidth: 90,
      headerClassName: 'header-datagrid',
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreCliente',
      headerName: 'Cliente',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'tipoCliente',
      headerName: 'Tipo de Cliente',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'contactoPrimario',
      headerName: 'Contacto',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'telefonoPrimario',
      headerName: 'Teléfono',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'fechaCreacion',
      headerName: 'Creación',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          onClickEdit(params.row);
        };
        return (
          <div>
            <Tooltip title="Editar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Page
      className={classes.root}
      title="Búsqueda de Clientes"
    >
      <Container maxWidth="lg">
        <ClientesToolBar
          setOpenAltaCliente={setOpenAltaCliente}
          setOpenSnackSuccess={setOpenSnackSuccess}
          setOpenSnackError={setOpenSnackError}
          setServerError={setServerError}
          setServerMessage={setServerMessage}
          rowsClientes={rowsClientes}
          setRowsClientes={setRowsClientes}
          setOpenBackDrop={setOpenBackDrop}
          comboTipoClientes={comboTipoClientes}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        <Box mt={3} />
        <DataTable
          rows={rowsClientes}
          columns={columns}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          activateCheckBox
        />
        <DialogForms
          setOpenDialog={setOpenAltaCliente}
          openDialog={openAltaCliente}
          submitButtonName={idCliente === 0 ? 'GUARDAR' : 'ACTUALIZAR'}
          actionButtonName={idCliente === 0 ? 'LIMPIAR' : undefined}
          titulo={idCliente === 0 ? 'Alta de Cliente' : 'Editar Cliente'}
          formName={formName}
          handleRef={resetClientesRef}
          size="md"
        >
          <AltaCliente
            formName={formName}
            ref={clienteRef}
            comboEstados={comboEstados}
            comboMunicipios={comboMunicipios}
            setComboMunicipios={setComboMunicipios}
            mapaMunicipios={mapaMunicipios}
            setMapaMunicipios={setMapaMunicipios}
            setServerError={setServerError}
            setServerMessage={setServerMessage}
            comboTipoClientes={comboTipoClientes}
            setComboTipoClientes={setComboTipoClientes}
            setOpenSnackError={setOpenSnackError}
            idCliente={idCliente}
            setIdCliente={setIdCliente}
            setOpenDialog={setOpenAltaCliente}
            formData={formData}
            setFormData={setFormData}
            rowsClientes={rowsClientes}
          />
        </DialogForms>
      </Container>
    </Page>
  );
};

Clientes.propTypes = {
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  setComboMunicipios: PropTypes.func,
  mapaMunicipios: PropTypes.array,
  setMapaMunicipios: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  comboTipoClientes: PropTypes.array,
  setComboTipoClientes: PropTypes.func,
  setOpenBackDrop: PropTypes.func
};

export default Clientes;
