import http from '../http-common';

class AuthService {
  login = (data) => {
    return http.post('/auth/login', data);
  }

  roles = () => {
    return http.get('/catalogo/rolesActivos');
  }

  autorizado = () => {
    return http.get('/auth/checkAuth');
  }
}

export default new AuthService();
