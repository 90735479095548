import * as React from 'react';
import { DataGrid, esES } from '@material-ui/data-grid';
import { Card, Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    '&.header-datagrid': { backgroundColor: '#4caf50' }
  }
}));

export default function DataTable({
  columns, rows, setSelectedRows, selectedRows,
  nombreUsuario, activateCheckBox
}) {
  const [pageSize, setPageSize] = React.useState(20);
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
    >
      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        align="center"
      >
        <Card className={classes.root}>
          <DataGrid
            localeText={esES.props.MuiDataGrid.localeText}
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
            rowsPerPageOptions={[20, 50, 100]}
            pagination
            checkboxSelection={activateCheckBox}
            disableSelectionOnClick
            autoHeight
            onSelectionModelChange={(newSelec) => {
              console.log(newSelec);
              console.log(rows);
              rows.filter((row) => console.log(row.id));
              setSelectedRows(newSelec);
              console.log('end setRows');
            }}
            selectionModel={selectedRows}
            isRowSelectable={(params) => (
              nombreUsuario === undefined || params.row.nombreUsuario !== nombreUsuario
            )}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
DataTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectedRows: PropTypes.array,
  nombreUsuario: PropTypes.string,
  activateCheckBox: PropTypes.bool
};
