import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Done as DoneIcon,
  LocalLaundryService as ClearIcon,
  Send as CreateIcon,
  Edit,
  Refresh,
  Save
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import evaluacionService from 'src/services/evaluacionService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  }
}));

const InputData = ({
  setOpenBackDrop,
  setEvaluacion,
  comboEscuelas,
  formName,
  setValComboVivienda,
  setValComboPrincipal,
  blockFields,
  setBlockFields,
  evaluacion,
  valComboEscuelas,
  setValComboEscuelas,
  setServerError,
  setOpenSnackError,
  valComboVivienda,
  valComboPrincipal,
  comboViviendaA,
  comboViviendaB1,
  comboViviendaB2,
  comboViviendaB3,
  comboViviendaC,
  comboPrincipalA,
  comboPrincipalB1,
  comboPrincipalB2,
  comboPrincipalB3,
  comboPrincipalC,
  setResetPlantilla,
  setTabValue
}) => {
  const classes = useStyles();
  const {
    errors,
    register,
    handleSubmit,
    setError,
    setValue
  } = useForm();
  const twoChars = 'Máximo 2 caractéres.';
  const requiredField = 'Este campo es requerido.';
  const mayorCero = 'Campo númerico y mayor a cero.';

  const removeZero = (newVal, fieldName) => {
    while (newVal.length > 1 && newVal.charAt(0) === '0') {
      newVal = newVal.substring(1);
      setValue(fieldName, newVal);
    }
    return newVal;
  };

  const clearFields = () => {
    setEvaluacion(null);
    setValComboVivienda([]);
    setValComboPrincipal([]);
    setTabValue(0);
  };

  const onSelectCliente = async (idCliente) => {
    setOpenBackDrop(true);
    await evaluacionService.getPlantilla(idCliente).then((resp) => {
      setEvaluacion(resp.data);
      setValComboVivienda([]);
      console.log('Obteniendo evaluacion:', resp.data);
      resp.data.detalles.forEach((detalle) => {
        detalle.vivienda.forEach((vivienda, index) => {
          const comboVivName = `vivienda_${detalle.idViabilidad}_${index}`;
          if (detalle.viabilidad === 'A / 81-100') {
            const auxComboViv = comboViviendaA.filter(
              (vivA) => vivA.value === vivienda.idVivienda
            )[0];
            valComboVivienda[comboVivName] = auxComboViv;
          } else if (detalle.viabilidad === 'B-1 / 61-80') {
            const auxComboViv = comboViviendaB1.filter(
              (vivA) => vivA.value === vivienda.idVivienda
            )[0];
            valComboVivienda[comboVivName] = auxComboViv;
          } else if (detalle.viabilidad === 'B-2 / 41-60') {
            const auxComboViv = comboViviendaB2.filter(
              (vivA) => vivA.value === vivienda.idVivienda
            )[0];
            valComboVivienda[comboVivName] = auxComboViv;
          } else if (detalle.viabilidad === 'B-3 / 21-40') {
            const auxComboViv = comboViviendaB3.filter(
              (vivA) => vivA.value === vivienda.idVivienda
            )[0];
            valComboVivienda[comboVivName] = auxComboViv;
          } else if (detalle.viabilidad === 'C / 0-20') {
            const auxComboViv = comboViviendaC.filter(
              (vivA) => vivA.value === vivienda.idVivienda
            )[0];
            valComboVivienda[comboVivName] = auxComboViv;
          }
        });
        detalle.principal.forEach((principal, index) => {
          const comboPrinName = `principal_${detalle.idViabilidad}_${index}`;
          if (detalle.viabilidad === 'A / 81-100') {
            const auxComboPrin = comboPrincipalA.filter(
              (prinA) => prinA.value === principal.idPrincipal
            )[0];
            valComboPrincipal[comboPrinName] = auxComboPrin;
          } else if (detalle.viabilidad === 'B-1 / 61-80') {
            const auxComboPrin = comboPrincipalB1.filter(
              (prinA) => prinA.value === principal.idPrincipal
            )[0];
            valComboPrincipal[comboPrinName] = auxComboPrin;
          } else if (detalle.viabilidad === 'B-2 / 41-60') {
            const auxComboPrin = comboPrincipalB2.filter(
              (prinA) => prinA.value === principal.idPrincipal
            )[0];
            valComboPrincipal[comboPrinName] = auxComboPrin;
          } else if (detalle.viabilidad === 'B-3 / 21-40') {
            const auxComboPrin = comboPrincipalB3.filter(
              (prinA) => prinA.value === principal.idPrincipal
            )[0];
            valComboPrincipal[comboPrinName] = auxComboPrin;
          } else if (detalle.viabilidad === 'C / 0-20') {
            const auxComboPrin = comboPrincipalC.filter(
              (prinA) => prinA.value === principal.idPrincipal
            )[0];
            valComboPrincipal[comboPrinName] = auxComboPrin;
          }
        });
      });

      setValComboVivienda(valComboVivienda);
      setValComboPrincipal(valComboPrincipal);
      setBlockFields(true);
      setOpenBackDrop(false);
    }).catch((error) => {
      console.log('Hubo un error al cargar la plantilla.', error);
      if (error.response?.status === 404) {
        setServerError(error.response.data?.mensaje);
      } else {
        setServerError('Hubo un error al cargar la plantilla.');
      }
      setBlockFields(false);
      setValue('filasIngresos', null);
      setValue('filasVivienda', null);
      setValue('filasDeuda', null);
      setValue('ingresosInicio', null);
      setValue('ingresosFin', null);
      setValue('egresosInicio', null);
      setValue('egresosFin', null);
      setResetPlantilla(0);
      setOpenBackDrop(false);
      setOpenSnackError(true);
    });
  };

  const onSubmit = (formData) => {
    console.log('Datos del formulario:', formData);
    const asyncCall = async (evalDTO) => {
      setOpenBackDrop(true);
      await evaluacionService.generarPlantilla(evalDTO).then((resp) => {
        console.log('Evaluacion DTO:', resp.data);
        setEvaluacion(resp.data);
        setBlockFields(true);
      }).catch((error) => {
        setBlockFields(false);
        clearFields();
        setResetPlantilla(0);
        console.log('Error al generar la plantilla:', error);
      });
      setOpenBackDrop(false);
    };
    if (/^(?=.*[1-9])\d*,*\d*[.]*?\d*$/.test(formData.ingresosFin)) {
      asyncCall(formData);
    } else {
      setError('ingresosFin', { type: 'custom', message: 'Debe ser mayor a 0' });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Divider />
      <Typography
        className={classes.bottomSpace}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Clientes
      </Typography>
      <Grid
        className={classes.bottomSpace}
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid
          className={classes.item}
          item
          sm={4}
          xs={4}
          align="center"
        >
          <Autocomplete
            id="combo-box-escuelas"
            options={comboEscuelas}
            getOptionLabel={(option) => (option.label ? option.label : '')}
            renderOption={(option) => (
              <div>
                <DoneIcon
                  className={classes.iconSelected}
                  fontSize="small"
                  style={{ visibility: option.plantilla ? 'visible' : 'hidden' }}
                />
                {option.label}
              </div>
            )}
            fullWidth
            value={valComboEscuelas}
            renderInput={(params) => (
              <TextField
                {...params}
                name="regEscuelas"
                label="Seleccione un Cliente *"
                variant="outlined"
                size="small"
                error={!!errors.regEscuelas}
                helperText={errors.regEscuelas?.message}
                onInput={genService.toUpperCase}
                inputRef={register({
                  required: { value: true, message: requiredField }
                })}
              />
            )}
            onChange={(event, newValue) => {
              clearFields();
              if (newValue === null) {
                setBlockFields(false);
                setValue('filasIngresos', null);
                setValue('filasVivienda', null);
                setValue('filasDeuda', null);
                setValue('ingresosInicio', null);
                setValue('ingresosFin', null);
                setValue('egresosInicio', null);
                setValue('egresosFin', null);
                setResetPlantilla(0);
              } else {
                errors.regEscuelas = null;
                setValComboEscuelas(newValue);
                if (newValue !== null) {
                  onSelectCliente(newValue.value);
                }
              }
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <Typography
        className={classes.bottomSpace}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Filas
      </Typography>
      <Grid
        className={classes.bottomSpace}
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid
          className={classes.item}
          item
          sm={4}
          xs={4}
          align="center"
        >
          <TextField
            label="Ingresos/Egresos *"
            name="filasIngresos"
            variant="outlined"
            value={evaluacion?.filasIngresos ? evaluacion.filasIngresos : null}
            inputRef={register({
              required: { value: true, message: requiredField },
              maxLength: { value: 2, message: twoChars },
              pattern: { value: /^[1-9][0-9]*$/, message: mayorCero }
            })}
            onInput={genService.removeLetters}
            error={!!errors.filasIngresos}
            helperText={errors.filasIngresos?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled={blockFields}
          />
        </Grid>
        <Grid
          className={classes.item}
          item
          sm={4}
          xs={4}
          align="center"
        >
          <TextField
            label="Vivienda *"
            name="filasVivienda"
            variant="outlined"
            value={evaluacion?.filasVivienda ? evaluacion.filasVivienda : null}
            inputRef={register({
              required: { value: true, message: requiredField },
              maxLength: { value: 2, message: twoChars },
              pattern: { value: /^[1-9][0-9]*$/, message: mayorCero }
            })}
            onInput={genService.removeLetters}
            error={!!errors.filasVivienda}
            helperText={errors.filasVivienda?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled={blockFields}
          />
        </Grid>
        <Grid
          className={classes.item}
          item
          sm={4}
          xs={4}
          align="center"
        >
          <TextField
            label="Principal/Deuda *"
            name="filasDeuda"
            variant="outlined"
            value={evaluacion?.filasDeuda ? evaluacion.filasDeuda : null}
            inputRef={register({
              required: { value: true, message: requiredField },
              maxLength: { value: 2, message: twoChars },
              pattern: { value: /^[1-9][0-9]*$/, message: mayorCero }
            })}
            onInput={genService.removeLetters}
            error={!!errors.filasDeuda}
            helperText={errors.filasDeuda?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
            disabled={blockFields}
          />
        </Grid>
      </Grid>
      <Divider />
      <Typography
        className={classes.bottomSpace}
        color="textSecondary"
        display="block"
        variant="caption"
      >
        Montos
      </Typography>
      <Grid
        className={classes.bottomSpace}
        container
        spacing={3}
        justifyContent="center"
        direction="row"
      >
        <Grid
          className={classes.item}
          item
          align="center"
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <TextField
            label="Ingresos Ini. *"
            name="ingresosInicio"
            variant="outlined"
            value={evaluacion?.ingresosInicio || evaluacion?.ingresosInicio === 0
              ? evaluacion.ingresosInicio : null}
            onChange={(e) => {
              removeZero(e.target.value, 'ingresosInicio');
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              disabled: blockFields
            }}
            inputRef={register({
              required: { value: true, message: requiredField },
              pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
            })}
            onInput={genService.onlyAmounts}
            error={!!errors.ingresosInicio}
            helperText={errors.ingresosInicio?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          className={classes.item}
          item
          align="center"
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <TextField
            label="Ingresos Fin *"
            name="ingresosFin"
            variant="outlined"
            value={evaluacion?.ingresosFin || evaluacion?.ingresosFin === 0
              ? evaluacion.ingresosFin : null}
            onChange={(e) => {
              removeZero(e.target.value, 'ingresosFin');
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              disabled: blockFields
            }}
            inputRef={register({
              required: { value: true, message: requiredField },
              pattern: { value: /^(?=.*[1-9])\d*,*\d*([.]?\d)*$/, message: mayorCero }
            })}
            onInput={genService.onlyAmounts}
            error={!!errors.ingresosFin}
            helperText={errors.ingresosFin?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          className={classes.item}
          item
          align="center"
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <TextField
            label="Egresos Ini. *"
            name="egresosInicio"
            variant="outlined"
            value={evaluacion?.egresosInicio || evaluacion?.egresosInicio === 0
              ? evaluacion.egresosInicio : null}
            onChange={(e) => {
              removeZero(e.target.value, 'egresosInicio');
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              disabled: blockFields
            }}
            inputRef={register({
              required: { value: true, message: requiredField },
              pattern: { value: /^(?=.*[1-9])\d*,*\d*([.]?\d)*$/, message: mayorCero }
            })}
            onInput={genService.onlyAmounts}
            error={!!errors.egresosInicio}
            helperText={errors.egresosInicio?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          className={classes.item}
          item
          align="center"
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <TextField
            label="Egresos Fin *"
            name="egresosFin"
            variant="outlined"
            value={evaluacion?.egresosFin || evaluacion?.egresosFin === 0
              ? evaluacion.egresosFin : null}
            onChange={(e) => {
              removeZero(e.target.value, 'egresosFin');
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              disabled: blockFields
            }}
            inputRef={register({
              required: { value: true, message: requiredField },
              pattern: { value: /^(?=.*[1-9])\d*,*\d*([.]?\d)*$/, message: mayorCero }
            })}
            onInput={genService.onlyAmounts}
            error={!!errors.egresosFin}
            helperText={errors.egresosFin?.message}
            size="small"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Divider className={classes.bottomSpace} />
      <Grid
        className={classes.bottomSpace}
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid className={classes.item} item>
          {blockFields ? (
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                setBlockFields(false);
                setResetPlantilla(evaluacion.id);
                clearFields();
                e.preventDefault();
              }}
              endIcon={<Edit size="small" />}
            >
              EDITAR
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<CreateIcon size="small" />}
            >
              GENERAR
            </Button>
          )}
        </Grid>
        {blockFields ? (
          <Grid className={classes.item} item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form={formName}
              endIcon={
                (evaluacion !== null && evaluacion !== undefined
                  && evaluacion.id !== null && evaluacion.id > 0)
                  ? <Refresh size="small" /> : <Save size="small" />
              }
            >
              {(evaluacion !== null && evaluacion !== undefined
                && evaluacion.id !== null && evaluacion.id > 0)
                ? 'ACTUALIZAR' : 'GUARDAR'}
            </Button>
          </Grid>
        ) : (
          null
        )}
        <Grid className={classes.item} item>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setBlockFields(false);
              setValue('filasIngresos', null);
              setValue('filasVivienda', null);
              setValue('filasDeuda', null);
              setValue('ingresosInicio', null);
              setValue('ingresosFin', null);
              setValue('egresosInicio', null);
              setValue('egresosFin', null);
              setValComboEscuelas([]);
              setResetPlantilla(0);
              clearFields();
              e.preventDefault();
            }}
            endIcon={<ClearIcon size="small" />}
          >
            LIMPIAR
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

InputData.propTypes = {
  setOpenBackDrop: PropTypes.func,
  setEvaluacion: PropTypes.func,
  comboEscuelas: PropTypes.array,
  formName: PropTypes.string,
  setValComboVivienda: PropTypes.func,
  setValComboPrincipal: PropTypes.func,
  blockFields: PropTypes.bool,
  setBlockFields: PropTypes.func,
  evaluacion: PropTypes.object,
  valComboEscuelas: PropTypes.object,
  setValComboEscuelas: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  valComboVivienda: PropTypes.object,
  valComboPrincipal: PropTypes.object,
  comboViviendaA: PropTypes.array,
  comboViviendaB1: PropTypes.array,
  comboViviendaB2: PropTypes.array,
  comboViviendaB3: PropTypes.array,
  comboViviendaC: PropTypes.array,
  comboPrincipalA: PropTypes.array,
  comboPrincipalB1: PropTypes.array,
  comboPrincipalB2: PropTypes.array,
  comboPrincipalB3: PropTypes.array,
  comboPrincipalC: PropTypes.array,
  setResetPlantilla: PropTypes.func,
  setTabValue: PropTypes.func
};

export default InputData;
