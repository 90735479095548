import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {
  Button,
  DialogTitle as MuiDialogTitle,
  Dialog,
  Box,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Close
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  spaceButton: {
    marginRight: theme.spacing(1)
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    setCloseDialog
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h5">{children}</Typography>
      {setCloseDialog ? (
        <IconButton
          className={classes.closeButton}
          onClick={() => setCloseDialog(false)}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogForms = ({
  setOpenDialog,
  openDialog,
  titulo,
  submitButtonName,
  formName,
  actionButtonName,
  children,
  handleRef,
  disableButton,
  disableButtonFunc,
  funcButtonName,
  actionIcon,
  size,
  functionButton,
  downloadButtonName,
  downloadFunction,
  disableDownload
}) => {
  const classes = useStyles();
  return (
    <Dialog open={openDialog} maxWidth={size} fullWidth>
      <DialogTitle
        disableTypography
        setCloseDialog={setOpenDialog}
      >
        {titulo}
      </DialogTitle>
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <div>
            {downloadButtonName !== undefined
              ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={downloadFunction}
                  className={classes.spaceButton}
                  disabled={disableDownload}
                >
                  {downloadButtonName}
                </Button>
              ) : (
                null
              )}
            {actionButtonName !== undefined
              ? (
                <Button
                  className={classes.spaceButton}
                  variant="contained"
                  onClick={handleRef}
                  endIcon={actionIcon}
                >
                  {actionButtonName}
                </Button>
              ) : (
                null
              )}
            {submitButtonName !== undefined
              ? (
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  form={formName}
                  className={classes.spaceButton}
                  disabled={disableButton}
                >
                  {submitButtonName}
                </Button>
              ) : (
                null
              )}
            {funcButtonName !== undefined
              ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={functionButton}
                  className={classes.spaceButton}
                  disabled={disableButtonFunc}
                >
                  {funcButtonName}
                </Button>
              ) : (
                null
              )}
          </div>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DialogForms.propTypes = {
  setOpenDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  titulo: PropTypes.string,
  submitButtonName: PropTypes.string,
  funcButtonName: PropTypes.string,
  children: PropTypes.object,
  formName: PropTypes.string,
  actionButtonName: PropTypes.string,
  handleRef: PropTypes.func,
  disableButton: PropTypes.bool,
  disableButtonFunc: PropTypes.bool,
  actionIcon: PropTypes.element,
  size: PropTypes.string,
  functionButton: PropTypes.func,
  downloadButtonName: PropTypes.string,
  downloadFunction: PropTypes.func,
  disableDownload: PropTypes.bool
};

export default DialogForms;
