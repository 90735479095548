import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import combosService from 'src/services/combosService';
import Page from 'src/components/Page';
import clsx from 'clsx';
import genService from 'src/services/generalServices';
import reportService from 'src/services/reportesService';
import { Autocomplete } from '@material-ui/lab';
import { Download } from 'react-feather';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1.5)
  },
  paperRoot: {
    padding: theme.spacing(2),
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(3),
  },
}));

const Reportes = ({
  className,
  setOpenBackDrop,
  setOpenSnackSuccess,
  setServerMessage,
  setServerError,
  setOpenSnackError,
}) => {
  const classes = useStyles();
  const {
    register,
    errors,
    handleSubmit
  } = useForm();
  const [comboClientes, setComboClientes] = useState([]);
  const [valComboClientes, setValComboClientes] = useState([]);
  const [comboReportes, setComboReportes] = useState([]);
  const [valComboReporte, setValComboReporte] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [openFechaInicio, setOpenFechaInicio] = useState(false);
  const [openFechaFin, setOpenFechaFin] = useState(false);

  const optionsCliente = comboClientes.map((option) => {
    const firstLetter = option.tipoCliente;
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  useEffect(() => {
    const getCombosAsync = async () => {
      setOpenBackDrop(true);
      await combosService.comboClientes().then((resp) => {
        console.log(resp);
        setComboClientes(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de clientes: '${error}`);
      });
      await combosService.comboReportes().then((resp) => {
        console.log(resp);
        setComboReportes(resp);
        setValComboReporte(resp[0]);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de reportes: '${error}`);
      });
      setOpenBackDrop(false);
    };
    getCombosAsync();
  }, []);

  const onSubmit = async (data) => {
    setOpenBackDrop(true);
    data.idReporte = valComboReporte.value;
    data.idCliente = valComboClientes.value;
    data.fechaInicio = fechaInicio;
    data.fechaFin = fechaFin;
    console.log('Datos para reporteo:', data);
    await reportService.generateReport(data).then((resp) => {
      const link = document.createElement('a');
      console.log('Datos del Reporte:', resp);
      const dateRepo = new Date();
      link.href = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.ms-excel' }));
      link.download = `${data.regReporte}-${dateRepo.toLocaleDateString()}.xls`;
      link.click();
      setOpenSnackSuccess(true);
      setServerMessage('Reporte generado correctamente');
    }).catch((error) => {
      if (error.response) {
        error.response.data.text().then((errorResp) => {
          const finalError = JSON.parse(errorResp);
          console.log('error:', finalError);
          setServerError(finalError.message);
        });
      } else {
        setServerError(`${error.message}: No fue posible conectar con el servidor`);
      }
      setOpenSnackError(true);
    });
    setOpenBackDrop(false);
  };

  return (
    <Page
      className={classes.root}
      title="Búsqueda de Ordenes"
    >
      <Container maxWidth="lg">
        <div className={clsx(classes.root, className)}>
          <Typography className={classes.title}>
            Generación de reportes
          </Typography>
          <Divider className={classes.bottomSpace} />
          <Paper className={classes.paperRoot}>
            <form
              id="formReport"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={10} justifyContent="center">
                <Grid item md={6} xs={12} className={classes.bottomSpace}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={comboReportes}
                    getOptionLabel={(option) => (option.label !== undefined ? option.label : '')}
                    renderOption={(option) => option.label}
                    fullWidth
                    value={valComboReporte}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regReporte"
                        inputRef={register({
                          required: { value: true, message: 'Este campo es requerido' }
                        })}
                        label="Seleccione un Reporte"
                        variant="outlined"
                        size="small"
                        onInput={genService.toUpperCase}
                        error={errors.regReporte}
                        helperText={errors.regReporte?.message}
                      />
                    )}
                    onChange={(event, newValue) => {
                      errors.regReporte = null;
                      setValComboReporte(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={10} justifyContent="center">
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={optionsCliente.sort((a, b) => (
                      -b.firstLetter.localeCompare(a.firstLetter)
                    ))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => (option.label !== undefined ? `${option.label} - (${option.tipoCliente})` : '')}
                    renderOption={(option) => option.label}
                    fullWidth
                    value={valComboClientes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regCliente"
                        inputRef={register({
                          required: { value: true, message: 'Este campo es requerido' }
                        })}
                        label="Seleccione un Cliente"
                        variant="outlined"
                        size="small"
                        onInput={genService.toUpperCase}
                        error={errors.regCliente}
                        helperText={errors.regCliente?.message}
                      />
                    )}
                    onChange={(event, newValue) => {
                      errors.regCliente = null;
                      setValComboClientes(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} justifyContent="center">
                <Grid item md={6} xs={12} lg={3}>
                  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="fechaInicio"
                      open={openFechaInicio}
                      onOpen={() => setOpenFechaInicio(true)}
                      onClose={() => setOpenFechaInicio(false)}
                      onChange={setFechaInicio}
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha Inicio"
                      value={fechaInicio}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      inputRef={register}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={6} xs={12} lg={3}>
                  <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      name="fin"
                      open={openFechaFin}
                      onOpen={() => setOpenFechaFin(true)}
                      onClose={() => setOpenFechaFin(false)}
                      onChange={setFechaFin}
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha Fin"
                      value={fechaFin}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      fullWidth
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={10} justifyContent="center" className={classes.paperRoot}>
                <Grid item>
                  <Tooltip title="Generar reporte">
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Download />}
                      type="submit"
                    >
                      DESCARGAR REPORTE
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      </Container>
    </Page>
  );
};

Reportes.propTypes = {
  className: PropTypes.string,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
};

export default Reportes;
