import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tooltip,
  Button,
  TextField,
} from '@material-ui/core';
import Page from 'src/components/Page';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  Publish,
} from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import combosService from 'src/services/combosService';
import genService from 'src/services/generalServices';
import ordenService from 'src/services/ordenService';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiDataGrid-window': {
      bottom: 'auto'
    }
  },
  checkBoxVerde: {
    color: '#4caf50 !important'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  dropZoneStyle: {
    minHeight: '10px',
  },
  dropZoneText: {
    fontWeight: '200',
    fontFamily: 'sans-serif'
  },
  fontStyle: {
    fontSize: 8
  },
  paddingRight: {
    marginRight: theme.spacing(2)
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 300,
    backgroundColor: '#4caf508a'
  },
}));

const CargaMasiva = ({
  className,
  setOpenBackDrop,
  setServerError,
  setServerMessage,
  setOpenSnackError,
  setOpenSnackSuccess
}) => {
  const {
    handleSubmit,
    errors,
    register
  } = useForm();
  const classes = useStyles();

  const [archivo, setArchivo] = useState();
  const [comboClientes, setComboClientes] = useState([]);
  const [valComboClientes, setValComboClientes] = useState([]);
  const [valComboUsuarios, setValComboUsuarios] = useState({});
  const [comboUsuarios, setComboUsuarios] = useState([]);
  const [mapaUsuarios, setMapaUsuarios] = useState([]);
  const [respuestaServer, setRespuestaServer] = useState();

  useEffect(() => {
    const getCombosAsync = async () => {
      setOpenBackDrop(true);
      await combosService.comboClientes().then((resp) => {
        setComboClientes(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de clientes: '${error}`);
      });
      setOpenBackDrop(false);
    };
    getCombosAsync();
    setRespuestaServer('...');
    // BORRAR
    setServerMessage(',');
    setOpenSnackSuccess(false);
  }, []);

  const optionsCliente = comboClientes.map((option) => {
    const firstLetter = option.tipoCliente;
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const getComboUsuarioCliente = async (idCliente, idUsuario) => {
    setValComboUsuarios({});
    setComboUsuarios([]);
    setOpenBackDrop(true);
    const existUsuarios = mapaUsuarios.filter((item) => item.key === idCliente);
    if (existUsuarios !== undefined && existUsuarios.length > 0) {
      setComboUsuarios(existUsuarios[0].value);
      if (idUsuario !== null) {
        const selectComboUsuarios = existUsuarios[0].value.filter((aux) => (
          aux.value === idUsuario
        ));
        setValComboUsuarios(selectComboUsuarios[0]);
      }
    } else if (idCliente !== undefined && idCliente > 0) {
      await combosService.comboUsuariosCliente(idCliente, true).then((resp) => {
        setComboUsuarios(resp);
        setMapaUsuarios((newElement) => [...newElement, { key: idCliente, value: resp }]);
        if (idUsuario !== null) {
          const selectComboUsuarios = resp.filter((aux) => (
            aux.value === idUsuario
          ));
          setValComboUsuarios(selectComboUsuarios[0]);
        }
      }).catch((error) => {
        console.log('Error al llenar el combo de usuarios', error);
        setServerError(error.message);
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
    }
    setOpenBackDrop(false);
  };

  const onSubmit = (data) => {
    const handleAsync = async (datos) => {
      setOpenBackDrop(true);
      console.log('Antes de guardar');
      await ordenService.cargaMasiva(datos, archivo).then((resp) => {
        setRespuestaServer(resp.data);
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: El archivo fue modificado ó no fue posible conectar con el servidor`);
        }
      });
      setOpenBackDrop(false);
    };
    data.idUsuarioCliente = valComboUsuarios.value;
    data.idCliente = valComboClientes.value;
    handleAsync(data);
  };

  return (
    <Page
      className={classes.root}
    >
      <div className={clsx(classes.title, className)}>
        <Card>
          <CardHeader title="Carga Másiva de Ordenes" />
          <Divider />
          <CardContent>
            <form id="cargaMasivaForm" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={optionsCliente.sort((a, b) => (
                      -b.firstLetter.localeCompare(a.firstLetter)
                    ))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => (option.label !== undefined ? `${option.label} - (${option.tipoCliente})` : '')}
                    renderOption={(option) => option.label}
                    fullWidth
                    value={valComboClientes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regCliente"
                        inputRef={register({
                          required: { value: true, message: 'Este campo es requerido' }
                        })}
                        label="Seleccione un Cliente *"
                        variant="outlined"
                        size="small"
                        error={errors.regCliente}
                        helperText={errors.regCliente?.message}
                        onInput={genService.toUpperCase}
                      />
                    )}
                    onChange={(event, newValue) => {
                      errors.regCliente = null;
                      setValComboClientes(newValue);
                      getComboUsuarioCliente(newValue.value, null);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={comboUsuarios}
                    getOptionLabel={(option) => (option.usuario !== undefined ? `${option.usuario} - (${option.nombreUsuario})` : '')}
                    renderOption={(option) => `${option.usuario} - (${option.nombreUsuario})`}
                    fullWidth
                    value={valComboUsuarios}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="regUsuario"
                        inputRef={register({
                          required: { value: true, message: 'Este campo es requerido' }
                        })}
                        label="Seleccione un Usuario *"
                        variant="outlined"
                        size="small"
                        error={errors.regUsuario}
                        helperText={errors.regUsuario?.message}
                        onInput={genService.toUpperCase}
                      />
                    )}
                    onChange={(event, newValue) => {
                      errors.regUsuario = null;
                      console.log(newValue);
                      setValComboUsuarios(newValue);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={8} xs={8}>
                  <DropzoneArea
                    dropzoneClass={classes.dropZoneStyle}
                    acceptedFiles={[
                      'application/vnd.ms-excel',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                    onDrop={(file) => {
                      setArchivo(file);
                    }}
                    filesLimit={1}
                    dropzoneText={(
                      <div>
                        <div>Arrastre y suelte un archivo.</div>
                        <div style={{ fontWeight: '100', fontFamily: 'sans-serif', fontStyle: 'italic' }}>
                          (Máx 10 MB y solo se permiten archivos .xls-x)
                        </div>
                      </div>
                    )}
                    maxFileSize={10200000}
                    showPreviewsInDropzone={false}
                    showPreviews
                    previewText={(
                      <div style={{ fontWeight: '50', fontFamily: 'sans-serif' }}>
                        Archivo de Carga:
                      </div>
                    )}
                    useChipsForPreview
                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                    previewChipProps={{ classes: { root: classes.previewChip } }}
                    alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'center' } }}
                    showAlerts={['error']}
                    getDropRejectMessage={() => {
                      return 'Solo se aceptan archivos menores a 10 MB y con extensiones: .xls-x';
                    }}
                    onDelete={() => {
                      setArchivo(undefined);
                      setRespuestaServer('...');
                    }}
                  />
                </Grid>
                <Grid item md={8} xs={8}>
                  <TextField
                    id="filled-multiline-static"
                    label="Respuesta del Servidor"
                    fullWidth
                    multiline
                    rows={4}
                    value={respuestaServer}
                    defaultValue={respuestaServer}
                    variant="filled"
                    inputProps={{
                      readOnly: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={12} xs={12} sm={12} align="center">
                  <Tooltip title="Cargar ordenes">
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<Publish />}
                      disabled={archivo === undefined}
                      type="submit"
                    >
                      CARGAR
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </div>
    </Page>
  );
};

CargaMasiva.propTypes = {
  className: PropTypes.string,
  setServerError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setOpenBackDrop: PropTypes.func
};

export default CargaMasiva;
