import http from '../http-common';

class OrdenService {
  altaOrden = (data) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('orden', blob);
    data.archivos?.forEach((fileAux) => {
      if (fileAux.estatus === 'NUEVO') {
        formData.append('files', fileAux.archivo);
      }
    });
    if (data.id === 0) {
      return http.post('/ordenes/add', formData, config);
    }
    return http.put('/ordenes/update', formData, config);
  }

  searchOrdenes = (data, isEval) => {
    let urlSearch;
    if (isEval) {
      urlSearch = '/evaluaciones/buscarOrdenes?';
    } else {
      urlSearch = '/ordenes/buscarOrdenes?';
    }
    let haveValue = false;
    if (data.folio !== undefined && data.folio !== '') {
      haveValue = true;
      urlSearch += `folio=${data.folio}`;
    }
    if (data.nombreCliente !== undefined && data.nombreCliente !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `nombreCliente=${data.nombreCliente}`;
    }
    if (data.investigado !== undefined && data.investigado !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `investigado=${data.investigado}`;
    }
    if (data.desde !== undefined && data.desde !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `desde=${data.desde}`;
    }
    if (data.hasta !== undefined && data.hasta !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `hasta=${data.hasta}`;
    }
    if (data.estado !== undefined && data.estado !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `estado=${data.estado}`;
    }
    if (data.estatus !== undefined && data.estatus !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `estatus=${data.estatus}`;
    }
    if (data.idOrdenEstatus !== undefined && data.idOrdenEstatus !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `idOrdenEstatus=${data.idOrdenEstatus}`;
    }
    if (data.idCliente !== undefined && data.idCliente !== null) {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `idCliente=${data.idCliente}`;
    }
    if (haveValue) {
      urlSearch += '&pagina=0&limite=1000';
    } else {
      urlSearch += 'pagina=0&limite=1000';
    }
    return http.get(urlSearch);
  }

  getOrdenById = (idOrden) => {
    const urlSearch = `/ordenes/${idOrden}`;
    return http.get(urlSearch);
  }

  eliminaOrden = (idOrdenes) => {
    const json = JSON.stringify(idOrdenes);
    return http.put('/ordenes/delete', json);
  }

  terminarOrdenes = (idOrdenes) => {
    const json = JSON.stringify(idOrdenes);
    return http.put('/ordenes/terminar', json);
  }

  uploadArchivoAWS = (itemFile, file) => {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    };
    const formData = new FormData();
    formData.append('files', file);
    formData.append('pathName', itemFile.path);
    return http.post('ordenes/uploadOrderFile', formData, config);
  }

  deleteOrderFile = (itemFile) => {
    return http.put('ordenes/borrarArchivos', itemFile);
  }

  downloadArchivoAWS = (itemFile) => {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const json = JSON.stringify(itemFile);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('archivoDTO', blob);
    return http.put('ordenes/downloadArchivo', formData, config);
  }

  descargarZip = (itemFiles, archivos, folio) => {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const formData = new FormData();
    archivos?.forEach((element) => {
      formData.append('files', element);
    });
    const wrapperArchivos = {};
    wrapperArchivos.archivosDTO = itemFiles;
    const json = JSON.stringify(wrapperArchivos);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    formData.append('archivoDTO', blob);
    formData.append('folioOrden', folio);
    return http.put('ordenes/descargarArchivos', formData, config);
  }

  enviarEmailLiga = async (orden) => {
    return http.put('ordenes/emailLinkOT', orden);
  }

  enviarEmailOrdenEstauts = async (orden, usersList) => {
    const emailList = usersList.map((aux) => aux.email);
    return http.put('ordenes/emailOrdenEstatus', { ordenDTO: orden, emails: emailList });
  }

  generarFolio = () => {
    return Math.random().toString(36).substr(2, 10).toUpperCase();
  }

  getBaseOrderByKey = async (urlKey) => {
    const orderReturn = `/solicitudes?urlKey=${urlKey}`;
    return http.get(orderReturn);
  }

  altaOrdenExpress = async (datos) => {
    return http.post('solicitudes/create', datos);
  }

  cargaMasiva = (data, archivo) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    const json = JSON.stringify(data);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('datosCliente', blob);
    formData.append('files', archivo[0]);
    return http.post('/cargaMasiva/add', formData, config);
  }
}

export default new OrdenService();
