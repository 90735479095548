import React from 'react';
import {
  Grid,
  Typography,
  colors,
  Tooltip,
  Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
/* import moment from 'moment'; */
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import { DataGrid } from '@material-ui/data-grid';
import { Download } from 'react-feather';

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: '20px',
    '& .summary-header': {
      backgroundColor: colors.green[200]
    }
  },
}));

const SummaryDocs = ({
  fechaFinalizacion,
  rowsFiles,
  downloadVoucher
}) => {
  const classes = useStyles();

  const columnsFiles = [
    {
      field: 'tipoArchivo',
      headerName: 'Tipo de Archivo',
      minWidth: 200,
      flex: 1,
      renderCell: renderCellExpand,
      headerClassName: 'summary-header'
    },
    {
      field: 'nombre',
      headerName: 'Nombre de Archivo',
      minWidth: 150,
      flex: 1,
      renderCell: renderCellExpand,
      headerClassName: 'summary-header'
    },
    {
      field: 'comentarios',
      headerName: 'Comentarios',
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: renderCellExpand,
      headerClassName: 'summary-header'
    }
  ];

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
      >
        <Grid item md={12} xs={12} sm={12} align="center">
          <Typography align="center" color="textPrimary">
            {'La documentación para esta liga fue enviada para su validación el día '}
            <strong>
              {/* moment(fechaFinalizacion).format('DD/MM/YYYY HH:mm:ss') */fechaFinalizacion}
            </strong>
            :
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} sm={12} align="right">
          <Tooltip title="Descargar comprobante">
            <Button
              variant="contained"
              color="primary"
              endIcon={<Download />}
              onClick={downloadVoucher}
            >
              DESCARGAR COMPROBANTE
            </Button>
          </Tooltip>
        </Grid>
        <Grid item md={12} xs={12} sm={12} align="center">
          <DataGrid
            rows={rowsFiles}
            columns={columnsFiles}
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableVirtualization
            hideFooter
            disableSelectionOnClick
            style={{ bottom: 'auto' }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

SummaryDocs.propTypes = {
  fechaFinalizacion: PropTypes.instanceOf(Date),
  rowsFiles: PropTypes.array,
  downloadVoucher: PropTypes.func
};

export default SummaryDocs;
