import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  /* Button, */
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Settings as SettingsIcon,
  /* ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Lock as LockIcon,
  AlertCircle as AlertCircleIcon, */
  Users as UsersIcon,
  List as ListIcon,
  CheckCircle as CheckIcon
} from 'react-feather';
import {
  dashboard,
  altaCliente,
  altaUsuario,
  ordenes,
  login,
  passwordReset,
  clienteOT,
  reportes,
  cargaMasiva,
  plantilla,
  evaluacion
} from 'src/utils/appRoutes';
import combosService from 'src/services/combosService';
import authService from 'src/services/auth.service';
import tokenService from 'src/services/token.service';
import NavItem from './NavItem';

const user = {
  avatar: '/static/images/avatars/noProfilePicture.jpg',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
const dashItem = [
  {
    href: dashboard(),
    icon: BarChartIcon,
    title: 'Dashboard',
    buttonType: 'boton'
  }
];
const adminItems = [
  {
    href: dashboard(),
    icon: BarChartIcon,
    title: 'Dashboard',
    buttonType: 'boton'
  },
  {
    icon: SettingsIcon,
    title: 'Configuración',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: altaUsuario(),
        title: 'Usuarios'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Clientes',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: altaCliente(),
        title: 'Administrar'
      },
      {
        href: ordenes(),
        title: 'Ordenes'
      },
      {
        href: cargaMasiva(),
        title: 'Carga Másiva'
      }
    ]
  },
  {
    icon: CheckIcon,
    title: 'Evaluaciones',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: evaluacion(),
        title: 'Registrar Evaluación'
      },
      {
        href: plantilla(),
        title: 'Crear Plantilla'
      },
    ]
  },
  {
    icon: ListIcon,
    title: 'Reportes',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: reportes(),
        title: 'Generar'
      },
    ]
  }
];
const analistaItems = [
  {
    href: dashboard(),
    icon: BarChartIcon,
    title: 'Dashboard',
    buttonType: 'boton'
  },
  {
    icon: UsersIcon,
    title: 'Clientes',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: ordenes(),
        title: 'Ordenes'
      },
      {
        href: cargaMasiva(),
        title: 'Carga Másiva'
      }
    ]
  },
  {
    icon: CheckIcon,
    title: 'Evaluaciones',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: evaluacion(),
        title: 'Registrar Evaluación'
      },
      {
        href: plantilla(),
        title: 'Crear Plantilla'
      },
    ]
  },
];
const clientesItems = [
  {
    href: dashboard(),
    icon: BarChartIcon,
    title: 'Dashboard',
    buttonType: 'boton'
  },
  {
    icon: UsersIcon,
    title: 'Clientes',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: clienteOT(),
        title: 'Ordenes de Trabajo'
      }
    ]
  }
];
/* const items = [
  {
    href: dashboard(),
    icon: BarChartIcon,
    title: 'Dashboard',
    buttonType: 'boton'
  },
  {
    icon: SettingsIcon,
    title: 'Configuración',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: altaUsuario(),
        title: 'Usuarios'
      }
    ]
  },
  {
    icon: UsersIcon,
    title: 'Clientes',
    buttonType: 'acordion',
    buttonMap: [
      {
        href: altaCliente(),
        title: 'Alta cliente'
      },
      {
        href: '/app/customers',
        title: 'Buscar cliente'
      }
    ]
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    buttonType: 'boton'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products',
    buttonType: 'boton'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account',
    buttonType: 'boton'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login',
    buttonType: 'boton'
  },
  {
    href: '/passwordReset',
    icon: UserPlusIcon,
    title: 'PasswordReset',
    buttonType: 'boton'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error',
    buttonType: 'boton'
  }
]; */

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  nombreUsuario,
  rolUsuario,
  setIsAuth,
  setNombreUsuario,
  setRolUsuario,
  setComboEstados,
  comboEstados,
  resetPassword,
  nombreCliente
}) => {
  const classes = useStyles();
  const location = useLocation();
  let menuItems;
  switch (rolUsuario) {
  case 'ADMINISTRADOR':
    menuItems = adminItems;
    break;
  case 'CLIENTE':
    menuItems = clientesItems;
    break;
  case 'ANALISTA':
    menuItems = analistaItems;
    break;
  default:
    menuItems = dashItem;
    break;
  }
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    const mapRoutes = [];
    if (rolUsuario !== null) {
      menuItems.map((item) => {
        if (item.href !== 'undefined' && location.pathname.startsWith(item.href)) {
          mapRoutes.push(item);
        }
        if (item.buttonMap) {
          item.buttonMap.map((buttonItem) => {
            if (location.pathname.startsWith(buttonItem.href)) {
              mapRoutes.push(buttonItem);
            }
            return mapRoutes;
          });
        }
        return mapRoutes;
      });
    }

    const validarToken = async () => {
      const token = tokenService.getToken();
      if (token) {
        await authService.autorizado().then((datos) => {
          if (datos.data != null && datos.data.mensaje === 'OK') {
            setNombreUsuario(tokenService.getUserName(true));
            setRolUsuario(tokenService.getRole(true));
            setIsAuth(true);
            if (comboEstados !== undefined && comboEstados.length === 0) {
              combosService.comboEstados().then((respuesta) => {
                setComboEstados(respuesta);
              });
            }
            if (resetPassword === 'true') {
              navigate(passwordReset(), { replace: true });
            } else {
              navigate(dashboard(), { replace: true });
            }
          } else {
            tokenService.logOut();
            setIsAuth(false);
            navigate(login(), { replace: true });
          }
        }).catch((error) => {
          console.log('Error en la autenticación', error);
          setIsAuth(false);
          tokenService.logOut();
          navigate(login(), { replace: true });
        });
      } else {
        setIsAuth(false);
        navigate(login(), { replace: true });
      }
    };
    if (rolUsuario === null || mapRoutes.length === 0) {
      validarToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {nombreUsuario}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {rolUsuario !== 'CLIENTE'
            ? (
              rolUsuario
            ) : (
              nombreCliente
            )}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {menuItems.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              buttonType={item.buttonType}
              buttonMap={item.buttonMap}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      {/* <Box
        p={2}
        m={2}
        bgcolor="background.dark"
      >
        <Typography
          align="center"
          gutterBottom
          variant="h4"
        >
          Need more?
        </Typography>
        <Typography
          align="center"
          variant="body2"
        >
          Upgrade to PRO version and access 20 more screens
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            See PRO version
          </Button>
        </Box>
      </Box> */}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  nombreUsuario: PropTypes.string,
  rolUsuario: PropTypes.string,
  setIsAuth: PropTypes.func,
  setNombreUsuario: PropTypes.func,
  setRolUsuario: PropTypes.func,
  setComboEstados: PropTypes.func,
  comboEstados: PropTypes.array,
  resetPassword: PropTypes.string,
  nombreCliente: PropTypes.string
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
