import React, {
  forwardRef, useEffect, useImperativeHandle, useState
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  makeStyles,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Fab,
  Tooltip,
  Link,
  Paper,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import { ExpandMore, FileCopyOutlined } from '@material-ui/icons';
import genService from 'src/services/generalServices';
import PropTypes from 'prop-types';
import combosService from 'src/services/combosService';
import clientesService from 'src/services/clientesService';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import GeneralDialog from 'src/views/commons/GeneralDialog';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'block'
  },
  summary: {
    backgroundColor: theme.palette.AccordionSummary.background
  },
  verticalCenter: {
    alignItems: 'center',
    display: 'flex'
  },
  control: {
    padding: theme.spacing(2),
  },
}));

const AltaCliente = forwardRef(({
  formName,
  comboEstados,
  comboMunicipios,
  setComboMunicipios,
  mapaMunicipios,
  setMapaMunicipios,
  setServerError,
  setOpenSnackError,
  comboTipoClientes,
  idCliente,
  setIdCliente,
  setOpenDialog,
  formData,
  setFormData,
  rowsClientes
}, ref) => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    reset
  } = useForm({
    defaultValues: {
      idEstado: 9
    },
  });
  const classes = useStyles();
  const errorReq = 'Campo requerido.';
  const [acordionGeneral, setAcordionGeneral] = useState(true);
  const [acordionFiscales, setAcordionFiscales] = useState(true);
  const [acordionContactos, setAcordionContactos] = useState(true);
  const [acordionLink, setAcordionLink] = useState(true);
  const [openBack, setOpenBack] = useState(false);
  const [open, setOpen] = useState(false);
  const [urlLink, setUrlLink] = useState();
  const [completeUrlLink, setCompleteUrlLink] = useState();
  const [linkTooltip, setLinkTooltip] = useState(false);
  const [estadoLink, setEstadoLink] = useState(false);
  const [openVigenciaInicio, setOpenVigenciaInicio] = useState(false);
  const [openVigenciaFin, setOpenVigenciaFin] = useState(false);
  const [vigenciaInicio, setVigenciaInicio] = useState(null);
  const [vigenciaFin, setVigenciaFin] = useState(null);

  const resetForm = () => {
    reset({
      id: 0,
      nombreCliente: '',
      tipoCliente: '',
      actividad: '',
      contactoPrincipal: '',
      telefonoPrincipal: '',
      extPrincipal: '',
      contactoSecundario: '',
      telefonoSecundario: '',
      extSecundaria: '',
      email: '',
      paginaWeb: '',
      observaciones: '',
      rfc: '',
      calle: '',
      numero: '',
      numeroInt: '',
      idEstado: 9,
      idMunicipio: '',
      colonia: '',
      cp: '',
      pais: 'MEXICO',
      contactoEntregas: '',
      emailEntregas: '',
      telefonoEntregas: '',
      contactoPago: '',
      emailPago: '',
      telefonoPago: ''
    });
    setVigenciaInicio(null);
    setVigenciaFin(null);
  };

  const addComboMunicipios = (idEstado) => {
    const existMunicipios = mapaMunicipios.filter((item) => item.key === idEstado);
    if (existMunicipios !== undefined && existMunicipios.length > 0) {
      setComboMunicipios(existMunicipios[0].value);
    } else {
      combosService.comboMunicipios(idEstado).then((resp) => {
        setComboMunicipios(resp);
        setMapaMunicipios((newElement) => [...newElement, { key: idEstado, value: resp }]);
      });
    }
  };

  useEffect(() => {
    setComboMunicipios([]);
    setIdCliente(0);
    if (formData.idEstado !== undefined && formData.idEstado > 0) {
      addComboMunicipios(formData.idEstado);
    } else {
      addComboMunicipios(9);
    }
    reset(formData);
    if (formData.id !== undefined) {
      setIdCliente(formData.id);
      setUrlLink(formData.urlLink);
      setCompleteUrlLink(`${window.location.origin}/solicitudes?key=${formData.urlLink}`);
      setVigenciaInicio(formData.fechaVigenciaInicio);
      setVigenciaFin(formData.fechaVigenciaFin);
      setEstadoLink(formData.linkActivo);
    } else {
      const currentDate = new Date();
      const currDateOneMonth = new Date();
      setVigenciaInicio(currentDate);
      setVigenciaFin(currDateOneMonth.setMonth(currDateOneMonth.getMonth() + 1));
    }
    setFormData({});
  }, []);

  useImperativeHandle(ref, () => ({
    resetClientesForm: () => {
      resetForm();
    }
  }));

  const getComboMunicipios = (newValue) => {
    setComboMunicipios([]);
    if (newValue !== null) {
      setValue('idMunicipio', '');
      addComboMunicipios(newValue);
    }
  };

  const onError = (err) => {
    if (err.nombreCliente || err.contactoPrincipal
      || err.telefonoPrincipal || err.extPrincipal
      || err.telefonoAdicional || err.extAdicional
      || err.email) {
      setAcordionGeneral(true);
    }
    if (err.rfc || err.calle || err.idEstado
      || err.idMunicipio || err.colonia || err.cp
      || err.pais) {
      setAcordionFiscales(true);
    }
    if (err.contactoEntregas || err.emailEntregas || err.telefonoEntregas
      || err.contactoPago || err.emailPago || err.telefonoPago) {
      setAcordionContactos(true);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    const handleAsync = async (datos) => {
      setOpenBack(true);
      await clientesService.altaCliente(datos).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setServerError(resp.data.mensaje);
          setOpenSnackError(true);
        } else {
          if (idCliente > 0) {
            const modClient = rowsClientes.find((row) => row.id === idCliente);
            Object.assign(modClient, datos);
          }
          setOpen(true);
          resetForm();
        }
      }).catch((error) => {
        setOpenBack(false);
        setServerError(error.message);
        setOpenSnackError(true);
      }).finally(() => {
        setOpenBack(false);
      });
    };
    data.id = idCliente;
    data.fechaVigenciaInicio = vigenciaInicio;
    data.fechaVigenciaFin = vigenciaFin;
    data.urlLink = urlLink;
    data.linkActivo = estadoLink;
    handleAsync(data);
  };

  return (
    <div>
      <Accordion expanded={acordionGeneral}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionGeneral(!acordionGeneral)}
          className={classes.summary}
        >
          <Typography variant="h4">Datos Generales</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Nombre Cliente *"
                  name="nombreCliente"
                  inputRef={register({
                    required: { value: true, message: errorReq },
                    maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                  })}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.nombreCliente}
                  helperText={errors.nombreCliente?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="tipoCliente-select">
                    {
                      errors.tipoCliente
                        ? <div style={{ color: '#f44336' }}>Seleccione un Tipo de Cliente *</div>
                        : <div>Seleccione un Tipo de Cliente *</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="tipoCliente"
                    rules={{ required: { value: true, message: errorReq } }}
                    error={errors.tipoCliente}
                    defaultValue=""
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="tipoCliente-select"
                        label="Tipo de Cliente"
                        value={value}
                        readOnly={idCliente > 0}
                        onChange={(evento, newValue) => {
                          onChange(newValue.props.value);
                        }}
                      >
                        {comboTipoClientes.map((itemCliente) => (
                          <MenuItem
                            key={itemCliente.value}
                            value={itemCliente.value}
                          >
                            {itemCliente.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {
                    errors.tipoCliente
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.tipoCliente.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Actividad *"
                  name="actividad"
                  inputRef={register({
                    required: { value: true, message: errorReq },
                    maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                  })}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.actividad}
                  helperText={errors.actividad?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Contacto Primario *"
                  name="contactoPrimario"
                  inputRef={register({
                    required: { value: true, message: errorReq },
                    maxLength: { value: 250, message: 'Máximo 250 carácteres.' }
                  })}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.contactoPrimario}
                  helperText={errors.contactoPrimario?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={8}>
                <TextField
                  label="Tel. Primario *"
                  name="telefonoPrimario"
                  inputRef={register({
                    required: { value: true, message: errorReq },
                    maxLength: { value: 20, message: 'Máximo 20 caracteres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  variant="outlined"
                  onInput={genService.removeLetters}
                  error={errors.telefonoPrimario}
                  helperText={errors.telefonoPrimario?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <TextField
                  label="Ext."
                  name="extPrimaria"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caracteres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  variant="outlined"
                  onInput={genService.removeLetters}
                  error={errors.extPrimaria}
                  helperText={errors.extPrimaria?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Contacto Adicional"
                  name="contactoSecundario"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caracteres.' }
                  })}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.contactoSecundario}
                  helperText={errors.contactoSecundario?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={4} xs={8}>
                <TextField
                  label="Tel. Adicional"
                  name="telefonoSecundario"
                  ref={register}
                  inputRef={register({
                    maxLength: { value: 20, message: 'Máximo 20 caracteres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  variant="outlined"
                  onInput={genService.removeLetters}
                  error={errors.telefonoSecundario}
                  helperText={errors.telefonoSecundario?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <TextField
                  label="Ext."
                  name="extSecundaria"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caracteres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  variant="outlined"
                  onInput={genService.removeLetters}
                  error={errors.extSecundaria}
                  helperText={errors.extSecundaria?.message}
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Correo Electrónico *"
                  name="email"
                  inputRef={register({
                    required: { value: true, message: errorReq },
                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
                  })}
                  onInput={genService.toLowerCase}
                  error={errors.email}
                  helperText={errors.email?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Página Web"
                  name="paginaWeb"
                  inputRef={register({
                    pattern: { value: /^(http:\/\/|https:\/\/)?([a-z0-9][a-z0-9-]*\.)+[a-z0-9][a-z0-9-]*$/i, message: 'El formato no es correcto.' }
                  })}
                  onInput={genService.toLowerCase}
                  error={errors.paginaWeb}
                  helperText={errors.paginaWeb?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Observaciones"
                  name="observaciones"
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  minRows={3}
                  onInput={genService.toUpperCase}
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={acordionFiscales}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionFiscales(!acordionFiscales)}
          className={classes.summary}
        >
          <Typography variant="h4">Datos Fiscales</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="RFC"
                  name="rfc"
                  inputRef={register({
                    pattern: { value: /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, message: 'El formato no es correcto.' }
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.rfc}
                  helperText={errors.rfc?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Calle"
                  name="calle"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.calle}
                  helperText={errors.calle?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  label="Número"
                  name="numero"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caractéres.' },
                  })}
                  onInput={genService.removeLetters}
                  error={errors.numero}
                  helperText={errors.numero?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={2} xs={6}>
                <TextField
                  label="Núm Int."
                  name="numeroInt"
                  inputRef={register({
                    maxLength: { value: 6, message: 'Máximo 6 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.numeroInt}
                  helperText={errors.numeroInt?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="idEstado-select">
                    {
                      errors.idEstado
                        ? <div style={{ color: '#f44336' }}>Seleccione un Estado</div>
                        : <div>Seleccione un Estado</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="idEstado"
                    rules={{ required: { value: false, message: errorReq } }}
                    error={errors.idEstado}
                    defaultValue={9}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="idEstado-select"
                        label="Seleccione un Estado"
                        value={value}
                        onChange={(evento, newValue) => {
                          getComboMunicipios(newValue.props.value);
                          onChange(newValue.props.value);
                        }}
                      >
                        {comboEstados.map((edo) => (
                          <MenuItem
                            key={edo.value}
                            value={edo.value}
                          >
                            {edo.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {
                    errors.idEstado
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.idEstado.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl variant="outlined" size="small" className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="idMunicipio-select">
                    {
                      errors.idMunicipio
                        ? <div style={{ color: '#f44336' }}>Seleccione un Municipio</div> : <div>Seleccione un Municipio</div>
                    }
                  </InputLabel>
                  <Controller
                    control={control}
                    name="idMunicipio"
                    rules={{ required: { value: false, message: errorReq } }}
                    error={errors.idMunicipio}
                    defaultValue=""
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        id="idMunicipio-select"
                        label="Seleccione un Municipio"
                        value={value}
                        onChange={(evento, newValue) => {
                          onChange(newValue.props.value);
                        }}
                      >
                        {comboMunicipios.length === 0
                          ? (
                            <MenuItem
                              key={0}
                              value={0}
                            >
                              Elija un Estado
                            </MenuItem>
                          ) : (
                            comboMunicipios.map((mun) => (
                              <MenuItem
                                key={mun.value}
                                value={mun.value}
                              >
                                {mun.label}
                              </MenuItem>
                            ))
                          )}
                      </Select>
                    )}
                  />
                  {
                    errors.idMunicipio
                    && (
                      <FormHelperText style={{ color: '#f44336' }}>
                        {errors.idMunicipio.message}
                      </FormHelperText>
                    )
                  }
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Colonia"
                  name="colonia"
                  inputRef={register({
                    maxLength: { value: 125, message: 'Máximo 125 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.colonia}
                  helperText={errors.colonia?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Código Postal"
                  name="cp"
                  inputRef={register({
                    maxLength: { value: 5, message: 'Máximo 5 caractéres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  onInput={genService.removeLetters}
                  error={errors.cp}
                  helperText={errors.cp?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="País"
                  name="pais"
                  defaultValue="MEXICO"
                  inputRef={register({
                    maxLength: { value: 125, message: 'Máximo 125 caractéres.' }
                  })}
                  inputProps={{
                    readOnly: true
                  }}
                  onInput={genService.toUpperCase}
                  error={errors.pais}
                  helperText={errors.pais?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={acordionContactos}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionContactos(!acordionContactos)}
          className={classes.summary}
        >
          <Typography variant="h4">Contactos</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Contacto Entregas"
                  name="contactoEntregas"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.contactoEntregas}
                  helperText={errors.contactoEntregas?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Correo Entregas"
                  name="emailContactoEntregas"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caractéres.' },
                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
                  })}
                  onInput={genService.toLowerCase}
                  error={errors.emailEntregas}
                  helperText={errors.emailEntregas?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Telefóno Entregas"
                  name="telefonoContactoEntregas"
                  inputRef={register({
                    maxLength: { value: 10, message: 'Máximo 10 caractéres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  onInput={genService.removeLetters}
                  error={errors.telefonoEntregas}
                  helperText={errors.telefonoEntregas?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <br />
            <Divider />
            <br />
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Contacto pagos"
                  name="contactoPago"
                  inputRef={register({
                    maxLength: { value: 255, message: 'Máximo 255 caractéres.' },
                  })}
                  onInput={genService.toUpperCase}
                  error={errors.contactoPago}
                  helperText={errors.contactoPago?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Correo Pagos"
                  name="emailContactoPago"
                  inputRef={register({
                    maxLength: { value: 45, message: 'Máximo 45 caractéres.' },
                    pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i, message: 'El formato no es correcto.' }
                  })}
                  onInput={genService.toLowerCase}
                  error={errors.emailPagos}
                  helperText={errors.emailPagos?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  label="Telefóno Pagos"
                  name="telefonoContactoPago"
                  inputRef={register({
                    maxLength: { value: 10, message: 'Máximo 10 caractéres.' },
                    pattern: { value: /^[0-9]*$/, message: 'Campo númerico.' }
                  })}
                  onInput={genService.removeLetters}
                  error={errors.telefonoPagos}
                  helperText={errors.telefonoPagos?.message}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={acordionLink}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionLink(!acordionLink)}
          className={classes.summary}
        >
          <Typography variant="h4">Liga de solicitud</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            { idCliente > 0
              ? (
                <Grid container spacing={3} justifyContent="center">
                  <Grid item>
                    <Paper className={classes.control}>
                      <Link href={completeUrlLink} target="_blank">
                        {completeUrlLink}
                      </Link>
                    </Paper>
                  </Grid>
                  <Grid item className={classes.verticalCenter}>
                    <Tooltip
                      title={linkTooltip}
                      className={classes.tooltip}
                      leaveDelay="500"
                      arrow
                      onClose={() => setLinkTooltip('Copiar Liga')}
                    >
                      <Fab
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(completeUrlLink);
                          setLinkTooltip('Link Copiado!');
                        }}
                        color="transparent"
                      >
                        <FileCopyOutlined />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              ) : (
                null
              )}
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Estado Link</FormLabel>
                  <RadioGroup
                    row
                    value={estadoLink}
                    onChange={(e) => {
                      if (e.currentTarget.value === 'true') {
                        setEstadoLink(true);
                      } else {
                        setEstadoLink(false);
                      }
                    }}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Activo"
                      value
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      label="Inactivo"
                      value={false}
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="fechaVigenciaInicio"
                    open={openVigenciaInicio}
                    onOpen={() => setOpenVigenciaInicio(true)}
                    onClose={() => setOpenVigenciaInicio(false)}
                    value={vigenciaInicio}
                    onChange={setVigenciaInicio}
                    format="dd/MM/yyyy"
                    margin="none"
                    label="Inicio de Vigencia"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                    error={errors.fechaVigenciaInicio}
                    helperText={errors.fechaVigenciaInicio?.message}
                    inputRef={register}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="fechaVigenciaFin"
                    open={openVigenciaFin}
                    onOpen={() => setOpenVigenciaFin(true)}
                    onClose={() => setOpenVigenciaFin(false)}
                    value={vigenciaFin}
                    onChange={setVigenciaFin}
                    format="dd/MM/yyyy"
                    margin="none"
                    label="Fin de Vigencia"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                    error={errors.fechaVigenciaFin}
                    helperText={errors.fechaVigenciaFin?.message}
                    inputRef={register}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      <GeneralBackDrop open={openBack} />
      <GeneralDialog
        open={open}
        changeState={() => {
          setOpen(false);
          setOpenDialog(false);
        }}
        title="Cliente Guardado"
        message="El cliente fue guardado exitosamente."
      />
    </div>
  );
});

AltaCliente.propTypes = {
  formName: PropTypes.string,
  comboEstados: PropTypes.array,
  comboMunicipios: PropTypes.array,
  setComboMunicipios: PropTypes.func,
  mapaMunicipios: PropTypes.array,
  setMapaMunicipios: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  comboTipoClientes: PropTypes.array,
  idCliente: PropTypes.number,
  setIdCliente: PropTypes.func,
  setOpenDialog: PropTypes.func,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  rowsClientes: PropTypes.array
};

export default AltaCliente;
