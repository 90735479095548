const TOKEN_KEY = 'authorization';

class TokenService {
  getToken = () => {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  setToken = (token) => {
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  getUserName = (isAuth) => {
    if (!isAuth) {
      return null;
    }
    const payload = this.getToken().split('.')[1];
    const payloadDecoded = atob(payload);
    const values = JSON.parse(payloadDecoded);
    const username = values.sub;
    return username;
  }

  getRole = (isAuth) => {
    if (!isAuth) {
      return null;
    }
    const payload = this.getToken().split('.')[1];
    const payloadDecoded = atob(payload);
    const values = JSON.parse(payloadDecoded);
    const roles = values.roles[0];
    if (roles === 'ROLE_ADMIN') {
      return 'ADMINISTRADOR';
    }
    if (roles === 'ROLE_ANALISTA') {
      return 'ANALISTA';
    }
    if (roles === 'ROLE_CLIENTE') {
      return 'CLIENTE';
    }
    return null;
  }

  logOut = () => {
    window.localStorage.clear();
  }
}
export default new TokenService();
