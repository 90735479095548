import React from 'react';
import {
  Grid,
  Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const GeneralMessages = (props) => {
  const {
    openSnackSuccess, handleCloseSnackSuccess, serverMessage,
    openSnackError, handleCloseSnackError, serverError
  } = props;
  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
    >
      <Grid
        item
        align="center"
      >
        <Snackbar open={openSnackSuccess} autoHideDuration={5000} onClose={handleCloseSnackSuccess}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackSuccess}
            severity="success"
          >
            {serverMessage}
          </Alert>
        </Snackbar>
      </Grid>
      <Grid
        item
        align="center"
      >
        <Snackbar open={openSnackError} autoHideDuration={5000} onClose={handleCloseSnackError}>
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackError}
            severity="error"
          >
            {serverError}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

GeneralMessages.propTypes = {
  openSnackSuccess: PropTypes.bool,
  openSnackError: PropTypes.bool,
  serverError: PropTypes.string,
  handleCloseSnackSuccess: PropTypes.func,
  handleCloseSnackError: PropTypes.func,
  serverMessage: PropTypes.string
};

export default GeneralMessages;
