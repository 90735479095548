import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
/* import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google'; */
import Logo from 'src/components/Logo';
import Page from 'src/components/Page';
import genService from 'src/services/generalServices';
import authService from 'src/services/auth.service';
import {
  root,
  dashboard,
  passwordReset,
  login
} from 'src/utils/appRoutes';
import combosService from 'src/services/combosService';
import PropTypes from 'prop-types';
import tokenService from 'src/services/token.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({
  setIsAuth, setRolUsuario, setNombreUsuario, setOpenBackDrop,
  comboEstados, setComboEstados, setServerError, setOpenServerError,
  setResetPassword, setNombreCliente, setIsSupervisor, setIdCliente,
  setTipoCliente
}) => {
  const {
    register,
    handleSubmit,
    errors
  } = useForm();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const validarToken = async () => {
      if (tokenService.getToken() !== null && tokenService.getToken() !== undefined) {
        await authService.autorizado().then((datos) => {
          if (datos.data != null && datos.data.mensaje === 'OK') {
            navigate(dashboard(), { replace: true });
          } else {
            window.localStorage.clear();
            setIsAuth(false);
            navigate(login(), { replace: true });
          }
        }).catch((error) => {
          setIsAuth(false);
          console.log('Error el validar token:', error);
          window.localStorage.clear();
          navigate(login(), { replace: true });
        });
      }
    };
    validarToken();
  }, []);

  const onSubmit = async (param) => {
    const userObject = {
      nombreUsuario: param.usuario,
      password: param.password
    };
    setOpenBackDrop(true);
    await authService.login(userObject).then((res) => {
      /* Agregar mensajes de error desde la API */
      if (res.data.token !== null || res.data.token !== '') {
        tokenService.setToken(res.data.token);
        setIsAuth(true);
        setNombreUsuario(tokenService.getUserName(true));
        setRolUsuario(tokenService.getRole(true));
        setNombreCliente(res.data.nombreCliente);
        setIdCliente(res.data.idCliente);
        setTipoCliente(res.data.tipoCliente);
        if (res.data.idCliente !== null) {
          window.localStorage.setItem('idCliente', res.data.idCliente);
          window.localStorage.setItem('nombreCliente', res.data.nombreCliente);
          window.localStorage.setItem('tipoCliente', res.data.tipoCliente);
        }
        if (res.data.supervisor !== undefined) {
          setIsSupervisor(res.data.supervisor);
        }
        if (comboEstados !== undefined && comboEstados.length === 0) {
          combosService.comboEstados().then((respuesta) => {
            setComboEstados(respuesta);
          });
        }
        if (res.data.resetPassword) {
          setResetPassword(true);
          window.localStorage.setItem('reset', true);
          navigate(passwordReset(), { replace: false });
        } else {
          window.localStorage.setItem('reset', false);
          window.localStorage.setItem('refresh', true);
          navigate(dashboard(), { replace: true });
        }
      } else {
        setIsAuth(false);
        navigate(root(), { replace: true });
      }
    }).catch((error) => {
      setOpenBackDrop(false);
      if (error.response) {
        console.log('Error al login:', error.response.data.message);
        setServerError(error.response.data.message);
      } else {
        setServerError(error.message);
      }
      setIsAuth(false);
      setOpenServerError(true);
      navigate(root(), { replace: true });
    });
    setOpenBackDrop(false);
  };

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Inicio de sesión
            </Typography>
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={12}
              align="center"
            >
              <Logo />
            </Grid>
          </Grid>
          <Box
            mt={3}
            mb={1}
          >
            <Typography
              align="center"
              color="textSecondary"
              variant="body1"
            >
              Ingrese sus credenciales
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              label="Usuario"
              margin="normal"
              name="usuario"
              type="text"
              onInput={genService.toUpperCase}
              variant="outlined"
              inputRef={register({
                required: { value: true, message: 'Este campo es requerido' },
                maxLength: { value: 15, message: 'Máximo 15 carácteres.' }
              })}
              error={errors.usuario}
              helperText={errors.usuario?.message}
            />
            <TextField
              fullWidth
              label="Contraseña"
              margin="normal"
              name="password"
              type="password"
              variant="outlined"
              inputRef={register({
                required: { value: true, message: 'Este campo es requerido' },
                maxLength: { value: 15, message: 'Máximo 15 carácteres.' }
              })}
              error={errors.password}
              helperText={errors.password?.message}
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Iniciar Sesión
              </Button>
            </Box>
            {/* <Link
              component={RouterLink}
              to="/register"
              variant="h6"
            >
              Sign up
            </Link> */}
          </form>
        </Container>
      </Box>
    </Page>
  );
};
LoginView.propTypes = {
  setIsAuth: PropTypes.func,
  setNombreUsuario: PropTypes.func,
  setRolUsuario: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  comboEstados: PropTypes.array,
  setComboEstados: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenServerError: PropTypes.func,
  setResetPassword: PropTypes.func,
  setNombreCliente: PropTypes.func,
  setIsSupervisor: PropTypes.func,
  setIdCliente: PropTypes.func,
  setTipoCliente: PropTypes.func
};
export default LoginView;
