import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ordenService from 'src/services/ordenService';
import { useSearchParams } from 'react-router-dom';
import { DoubleArrow } from '@material-ui/icons';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import OrderGeneral from '../OrderCommons/OrderGeneral';
import AvisoPrivacidad from '../CargaDocumentos/AvisoPrivacidad';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& .MuiDataGrid-window': {
      bottom: 'auto'
    }
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  checkBoxVerde: {
    color: '#4caf50 !important'
  },
}));

const Solicitudes = ({
  setOpenBackDrop,
  setServerError,
  setOpenSnackError,
  setServerMessage,
  setOpenSnackSuccess
}) => {
  const {
    register,
    errors,
    setError,
    setValue,
    clearErrors,
    control,
    handleSubmit,
    reset
  } = useForm();
  const classes = useStyles();
  const [folio, setFolio] = useState();
  const [isLaboral, setIsLaboral] = useState(false);
  const [isEscuela, setIsEscuela] = useState(false);
  const [nombreCliente, setNombreCliente] = useState();
  const [idOrdenEstatus, setIdOrdenEstatus] = useState();
  const [idEstatusLiga, setIdEstatusLiga] = useState();
  const [searchParams] = useSearchParams();
  const [errorLigaSolicitud, setErrorLigaSolicitud] = useState(null);
  const [openGenerar, setOpenGenerar] = useState(false);
  const [formData, setFormData] = useState({});
  const [idCliente, setIdCliente] = useState(0);
  const [aceptarAviso, setAceptarAviso] = useState(false);
  const [openAviso, setOpenAviso] = useState(false);

  const resetForm = () => {
    reset({
      id: 0
    });
    setErrorLigaSolicitud(null);
    setFormData({});
  };

  useEffect(() => {
    const urlKey = searchParams.get('key');
    const encodedURL = encodeURIComponent(urlKey);
    console.log('the Key', encodedURL);

    const getBaseOrder = async (key) => {
      setOpenBackDrop(true);
      setErrorLigaSolicitud(null);
      await ordenService.getBaseOrderByKey(key).then((resp) => {
        console.log('Base order resp:', resp);
        setFolio(ordenService.generarFolio());
        if (resp.data.tipoCliente === 'Laboral') {
          setIsLaboral(true);
        }
        if (resp.data.tipoCliente === 'Escuela') {
          setIsEscuela(true);
        }
        setIdOrdenEstatus(resp.data.idOrdenEstatus);
        setIdEstatusLiga(resp.data.idEstatusLiga);
        setNombreCliente(resp.data.nombreCliente);
        setIdCliente(resp.data.idCliente);
      }).catch((error) => {
        if (error.response !== undefined) {
          setErrorLigaSolicitud(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setErrorLigaSolicitud(`${error.message}: No fue posible conectar con el servidor`);
        }
      });
      setOpenBackDrop(false);
    };

    getBaseOrder(encodedURL);
  }, []);

  const onSubmit = () => {
    const handleAsync = async (datos) => {
      console.log('Datos de la orden:', datos);
      setOpenBackDrop(true);
      await ordenService.altaOrdenExpress(datos).then((resp) => {
        setServerMessage(`La orden con folio ${resp.data.folio} fue creada correctamente.`);
        setOpenSnackSuccess(true);
        setFolio(ordenService.generarFolio());
        resetForm();
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response !== undefined) {
          setServerError(`${error.response.data.status}: ${error.response.data.message}`);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
      }).finally(() => {
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    formData.matricula = isEscuela ? formData.matricula : '';
    formData.puesto = isLaboral ? formData.puesto : '';
    formData.idOrdenEstatus = idOrdenEstatus;
    formData.idEstatusLiga = idEstatusLiga;
    formData.idCliente = idCliente;
    formData.estadoLiga = 1;
    handleAsync(formData);
  };

  const confirmSubmit = (data) => {
    setOpenGenerar(true);
    setFormData(data);
  };

  return (
    <Page
      className={classes.root}
      title="Solicitud de Trabajo"
    >
      { errorLigaSolicitud
        ? (
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="center"
          >
            <Container maxWidth="md">
              <Typography
                align="center"
                color="textPrimary"
                variant="h1"
              >
                {errorLigaSolicitud}
              </Typography>
              <Typography
                align="center"
                color="textPrimary"
                variant="subtitle2"
              >
                Hubo un error al tratar de cargar la página, por favor contacte con el
                administrador
              </Typography>
            </Container>
          </Box>
        ) : (
          <div className={clsx(classes.title)}>
            <Container maxWidth="lg">
              <Typography className={classes.title}>
                Solicitudes
              </Typography>
              <Card>
                <CardHeader title={nombreCliente} />
                <Divider />
                <CardContent>
                  <form
                    id="orderForm"
                    onSubmit={handleSubmit(confirmSubmit)}
                  >
                    <OrderGeneral
                      register={register}
                      folio={folio}
                      errors={errors}
                      isLaboral={isLaboral}
                      isEscuela={isEscuela}
                      idOrden={0}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      control={control}
                      rolUsuario="NO_ROL"
                    />
                    <br />
                    <Divider />
                    <br />
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                    >
                      <Grid item md={12} xs={12} sm={12} align="center">
                        <FormControl required error={errors}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                className={classes.checkBoxVerde}
                                name="aceptarAviso"
                                inputRef={register({
                                  required: { value: true, message: 'Es necesario aceptar el aviso de privacidad' }
                                })}
                                color="primary"
                                checked={aceptarAviso}
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    clearErrors(['aceptarAviso']);
                                  }
                                }}
                              />
                            )}
                            onChange={(e) => setAceptarAviso(e.target.checked)}
                            label={(
                              <Typography
                                variant="h6"
                              >
                                {'He leído y acepto el aviso de privacidad -- '}
                                <Link
                                  variant="body2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setOpenAviso(true);
                                  }}
                                >
                                  CONSULTAR
                                </Link>
                              </Typography>
                            )}
                          />
                          <FormHelperText>{errors.aceptarAviso?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      className={classes.paddingGrid}
                    >
                      <Grid item md={12} xs={12} align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<DoubleArrow />}
                          type="submit"
                        >
                          SOLICITAR
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Container>
            <ConfirmationDialog
              title="Generar solicitud"
              message={`Esta seguro que desea enviar la solicitud ${folio} ?`}
              open={openGenerar}
              onClose={() => setOpenGenerar(false)}
              confirm={() => {
                setOpenGenerar(false);
                onSubmit();
              }}
            />
            <AvisoPrivacidad
              openAviso={openAviso}
              setOpenAviso={setOpenAviso}
              setAceptarAviso={setAceptarAviso}
              clearErrors={clearErrors}
            />
          </div>
        )}
    </Page>
  );
};

Solicitudes.propTypes = {
  setOpenBackDrop: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
};

export default Solicitudes;
