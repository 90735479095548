import React from 'react';

// Andale Brahiam, arregla este logo.
const AvisoPrivacidad = () => {
  return (
    <iframe
      src="/static/documents/AvisoPrivacidad.pdf"
      title="Descargar Aviso"
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default AvisoPrivacidad;
