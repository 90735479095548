import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { clienteOT, ordenes } from 'src/utils/appRoutes';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import DataTable from 'src/views/commons/DataTable';
import ChipEstatusTable from 'src/views/commons/ChipEstatusTable';
import ordenService from 'src/services/ordenService';
import { Forward } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import tokenService from 'src/services/token.service';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  }
}));

const DashboardOrders = ({
  className,
  setOpenBackDrop,
  rolUsuario
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [rowsOrdenes, setRowsOrdenes] = useState([]);

  useEffect(() => {
    setOpenBackDrop(true);
    if (window.localStorage.getItem('refresh')) {
      window.localStorage.removeItem('refresh');
      window.location.reload(false);
    } else {
      const handleAsync = async (data) => {
        setOpenBackDrop(true);
        await ordenService.searchOrdenes(data, false).then((resp) => {
          setRowsOrdenes(resp.data);
        }).catch((error) => {
          console.log(error);
        });
        setOpenBackDrop(false);
      };
      let data = {
        estatus: 'SOLICITADA',
      };
      let rol;
      if (rolUsuario === null) {
        rol = tokenService.getRole(true);
      } else {
        rol = rolUsuario;
      }
      if (rol === 'CLIENTE') {
        data = {
          estatus: '!TERMINADO'
        };
      }
      handleAsync(data);
    }
  }, []);

  const columns = [
    {
      field: 'folio',
      headerName: 'Folio',
      maxWidth: 100,
      minWidth: 100,
      headerClassName: 'header-datagrid',
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreUsuario',
      headerName: 'Usuario',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreCliente',
      headerName: 'Cliente',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombre',
      headerName: 'Investigado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'telefonoPrimario',
      headerName: 'Teléfono',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'fechaSolicitudStr',
      headerName: 'Solicitud',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'descEstado',
      headerName: 'Estado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'descOrdenEstatus',
      headerName: 'Estatus',
      minWidth: 110,
      flex: 1,
      renderCell: ChipEstatusTable,
    },
    {
      field: 'consultar',
      headerName: 'Consultar',
      maxWidth: 40,
      minWidth: 40,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          window.localStorage.setItem('idOrden', params.row.id);
          let rol;
          if (rolUsuario === null) {
            rol = tokenService.getRole(true);
          } else {
            rol = rolUsuario;
          }
          if (rol === 'CLIENTE') {
            console.log('Navigate to cliente OT');
            navigate(clienteOT());
          } else {
            console.log('Navigate to cliente Oodenes');
            navigate(ordenes());
          }
        };
        return (
          <div>
            <Tooltip title="Consultar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
              >
                <Forward fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Ordenes Recientes" />
      <Divider />
      <Box>
        <DataTable
          rows={rowsOrdenes}
          columns={columns}
        />
      </Box>
    </Card>
  );
};

DashboardOrders.propTypes = {
  className: PropTypes.string,
  setOpenBackDrop: PropTypes.func,
  rolUsuario: PropTypes.string
};

export default DashboardOrders;
