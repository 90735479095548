import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  OutlinedInput,
  FormHelperText,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import userService from 'src/services/usuariosService';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  margin: {
    marginBottom: theme.spacing(2),
  },
}));

const UpdatePassword = ({
  setOpenBackDrop,
  setServerError,
  setServerMessage,
  setOpenSnackError,
  setOpenSnackSuccess,
}) => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    errors,
    setError
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (data.nuevoPassword !== data.confirmPassword) {
      setError('confirmPassword', { message: 'Tu nueva contraseña y su confirmación deben coincidir.' });
    } else {
      setOpenBackDrop(true);
      await userService.updatePassword(data.nuevoPassword, data.confirmPassword)
        .then((resp) => {
          window.localStorage.setItem('reset', false);
          setServerMessage(resp.data.mensaje);
          setOpenSnackSuccess(true);
          navigate('/app/dashboard', { replace: true });
        }).catch((error) => {
          if (error.response !== undefined) {
            setServerError(error.response.data.message);
          } else {
            setServerError(`${error.message}: No fue posible conectar con el servidor`);
          }
          setOpenSnackError(true);
          setOpenBackDrop(false);
        });
      setOpenBackDrop(false);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              ACTUALIZAR CONTRASEÑA
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Ingrese al menos 8 carácteres, una letra mayúscula, una minúsucla y un número.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              variant="outlined"
              fullWidth
              error={errors.nuevoPassword}
              className={classes.margin}
            >
              <InputLabel htmlFor="id-newPassword">
                Nueva contraseña *
              </InputLabel>
              <OutlinedInput
                id="id-newPassword"
                name="nuevoPassword"
                inputRef={register({
                  required: { value: true, message: 'Este campo es requerido' },
                  maxLength: { value: 15, message: 'Máximo 15 carácteres.' },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/i,
                    message: 'Debe ingresar al menos 8 carácteres, una letra mayúscula, una minúscula y un número.'
                  }
                })}
                endAdornment={
                  (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
                type={showPassword ? 'text' : 'password'}
                labelWidth={140}
              />
              <FormHelperText>{errors.nuevoPassword?.message}</FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              error={errors.confirmPassword !== null && errors.confirmPassword !== undefined}
              className={classes.margin}
            >
              <InputLabel htmlFor="id-confirmPassword">
                Confirmar nueva contraseña *
              </InputLabel>
              <OutlinedInput
                id="id-confirmPassword"
                name="confirmPassword"
                inputRef={register({
                  required: { value: true, message: 'Este campo es requerido' },
                  maxLength: { value: 15, message: 'Máximo 15 carácteres.' },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/i,
                    message: 'Debe ingresar al menos 8 carácteres, una letra mayúscula, una minúscula y un número.'
                  }
                })}
                endAdornment={
                  (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
                type={showConfirmPassword ? 'text' : 'password'}
                labelWidth={220}
              />
              <FormHelperText>{errors.confirmPassword?.message}</FormHelperText>
            </FormControl>
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                ACTUALIZAR
              </Button>
            </Box>
          </form>
        </Container>
      </Box>
    </Page>
  );
};

UpdatePassword.propTypes = {
  setOpenBackDrop: PropTypes.func,
  setServerError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
};

export default UpdatePassword;
