import http from '../http-common';

class EvaluacionService {
  generarPlantilla = (evalDTO) => {
    const json = JSON.stringify(evalDTO);
    return http.post('/evaluaciones/plantilla', json);
  }

  guardarVivienda = (viviendaDTO) => {
    const json = JSON.stringify(viviendaDTO);
    return http.post('/evaluaciones/add/vivienda', json);
  }

  guardarPrincipal = (principalDTO) => {
    const json = JSON.stringify(principalDTO);
    return http.post('/evaluaciones/add/principal', json);
  }

  guardarPlantillaEval = (evaluacionDTO) => {
    const json = JSON.stringify(evaluacionDTO);
    return http.post('/evaluaciones/add/plantilla', json);
  }

  getPlantilla = async (idCliente) => {
    return http.get(`/evaluaciones/plantilla/${idCliente}`);
  }

  getEvaluacionByOrden = async (idOrden) => {
    return http.get(`/evaluaciones/evaluacion/${idOrden}`);
  }

  getEvaluacionDetalles = (evaluacionDTO) => {
    return http.post('/evaluaciones/detallesEvaluacion', evaluacionDTO);
  }

  guardaEvaluacion = (evaluacionDTO) => {
    return http.post('/evaluaciones/guardarEvaluacion', evaluacionDTO);
  }

  descargarEvaluacion = (evaluacionDTO) => {
    const config = {
      responseType: 'blob'
    };
    const json = JSON.stringify(evaluacionDTO);
    return http.put('evaluaciones/descargarEvalaucion', json, config);
  }
}

export default new EvaluacionService();
