import http from '../http-common';

class ClientesService {
  altaCliente = (data) => {
    if (data.id === 0) {
      return http.post('/clientes/add', data);
    }
    return http.put('/clientes/update', data);
  }

  searchClientes = (data) => {
    let urlSearch = '/clientes/buscarClientes?';
    let haveValue = false;
    if (data.nombreCliente !== undefined && data.nombreCliente !== '') {
      haveValue = true;
      urlSearch += `cliente=${data.nombreCliente}`;
    }
    if (data.contacto !== undefined && data.contacto !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `contacto=${data.contacto}`;
    }
    if (data.rfc !== undefined && data.rfc !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `rfc=${data.rfc}`;
    }
    if (data.desde !== undefined && data.desde !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `desde=${data.desde}`;
    }
    if (data.hasta !== undefined && data.hasta !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `hasta=${data.hasta}`;
    }
    if (data.tipoCliente !== undefined && data.tipoCliente !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `tipoCliente=${data.tipoCliente}`;
    }
    if (haveValue) {
      urlSearch += '&pagina=0&limite=1000';
    } else {
      urlSearch += 'pagina=0&limite=1000';
    }
    return http.get(urlSearch);
  }

  getClienteById = (idCliente) => {
    const urlSearch = `/clientes/${idCliente}`;
    return http.get(urlSearch);
  }

  deleteClientes = (idClientes) => {
    const json = JSON.stringify(idClientes);
    return http.post('/clientes/delete', json);
  }
}

export default new ClientesService();
