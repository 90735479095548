import axios from 'axios';
import tokenService from './services/token.service';

const axiosInstance = axios.create({
  baseURL: 'https://servicios-consultores-cc.link',
  /* baseURL: 'http://localhost:5000', */
  headers: {
    'Content-type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.authorization = `${'Bearer '}${tokenService.getToken()}`;
    return config;
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  }, async (error) => {
    const originalReq = error.config;
    if (error.response !== undefined && error.response.status === 401 && !originalReq.retry) {
      originalReq.retry = true;
      const token = tokenService.getToken();
      if (token !== undefined && token !== null) {
        await axiosInstance.post('/auth/refresh', { token }).then((resp) => {
          tokenService.setToken(resp.data.token);
        }).catch((err) => {
          return Promise.reject(err);
        });
      }
      originalReq.headers.authorization = `${'Bearer '}${tokenService.getToken()}`;
      return axiosInstance(originalReq);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

/* export default axios.create({
  //baseURL: "http://testspring-env.eba-wed7ged4.us-west-2.elasticbeanstalk.com",
  baseURL: "http://localhost:5000",
  headers: {
    "Content-type": "application/json"
  }
}); */
