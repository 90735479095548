import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';

const ChipEstatusTable = (props) => {
  const { value } = props;
  const selectColor = (desc) => {
    if (desc === 'TERMINADO') {
      return 'secondary';
    }
    return 'primary';
  };
  const selectFiller = (desc) => {
    if (desc === 'SOLICITADA' || desc === 'TERMINADO') {
      return 'default';
    }
    return 'outlined';
  };
  return (
    <Chip
      color={selectColor(value)}
      label={value}
      size="small"
      variant={selectFiller(value)}
    />
  );
};

ChipEstatusTable.propTypes = {
  value: PropTypes.string,
};

export default ChipEstatusTable;
