import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Aviso from 'src/components/AvisoPrivacidad';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: '20px'
  },
  spaceButton: {
    marginRight: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: theme.palette.grey[500],
  },
  dialog: {
    height: 'inherit'
  }
}));

const AvisoPrivacidad = ({
  setOpenAviso,
  openAviso,
  setAceptarAviso,
  clearErrors
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={openAviso}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h5">
          Aviso de Privacidad
        </Typography>
        {setOpenAviso ? (
          <IconButton
            className={classes.closeButton}
            onClick={() => setOpenAviso(false)}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Aviso />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            className={classes.spaceButton}
            variant="contained"
            onClick={() => setOpenAviso(false)}
          >
            CANCELAR
          </Button>
          <Button
            className={classes.spaceButton}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenAviso(false);
              setAceptarAviso(true);
              clearErrors(['aceptarAviso']);
            }}
          >
            ACEPTAR
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

AvisoPrivacidad.propTypes = {
  setOpenAviso: PropTypes.func,
  openAviso: PropTypes.bool,
  setAceptarAviso: PropTypes.func,
  clearErrors: PropTypes.func
};

export default AvisoPrivacidad;
