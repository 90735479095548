import http from '../http-common';

class UsuariosService {
  altaUsuario = (data) => {
    if (data.id === 0) {
      return http.post('/usuarios/add', data);
    }
    return http.put('/usuarios/update', data);
  }

  searchUsuarios = (data) => {
    let urlSearch = '/usuarios/search?';
    let haveValue = false;
    if (data.usuario !== undefined && data.usuario !== '') {
      haveValue = true;
      urlSearch += `usuario=${data.usuario}`;
    }
    if (data.nombre !== undefined && data.nombre !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `nombre=${data.nombre}`;
    }
    if (data.desde !== undefined && data.desde !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `desde=${data.desde}`;
    }
    if (data.hasta !== undefined && data.hasta !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `hasta=${data.hasta}`;
    }
    if (data.rol !== undefined && data.rol !== '') {
      if (haveValue) {
        urlSearch += '&';
      }
      haveValue = true;
      urlSearch += `rol=${data.rol}`;
    }
    if (haveValue) {
      urlSearch += '&pagina=0&limite=1000';
    } else {
      urlSearch += 'pagina=0&limite=1000';
    }
    return http.get(urlSearch);
  }

  deleteUser = (idUsers) => {
    const json = JSON.stringify(idUsers);
    return http.post('/usuarios/delete', json);
  }

  resetPassword = (idUser) => {
    return http.put('/usuarios/resetPassword', idUser);
  }

  updatePassword = (password, confPassword) => {
    return http.put('/usuarios/updatePassword',
      {
        password,
        confPassword
      });
  }
}

export default new UsuariosService();
