import http from '../http-common';

class HiperligaService {
  getOrdenByKey = async (orderKey) => {
    const orderReturn = `/cargaDocumentos?orderKey=${orderKey}`;
    return http.get(orderReturn);
  }

  actualizarArchivos = async (hiperligaDTO) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    };
    const json = JSON.stringify(hiperligaDTO);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('hiperligaDTO', blob);
    hiperligaDTO.listaArchivos?.forEach((fileAux) => {
      if (fileAux.estatus === 'NUEVO') {
        formData.append('files', fileAux.archivo);
      }
    });
    return http.put('/cargaDocumentos/guardarArchivos', formData, config);
  }

  deleteOrderFile = (hiperligaDTO) => {
    const json = JSON.stringify(hiperligaDTO);
    return http.put('cargaDocumentos/borrarArchivos', json);
  }

  descargarZip = (itemFiles, archivos, keyLink) => {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const formData = new FormData();
    archivos?.forEach((element) => {
      formData.append('files', element);
    });
    const wrapperArchivos = {};
    wrapperArchivos.archivosDTO = itemFiles;
    const json = JSON.stringify(wrapperArchivos);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    formData.append('archivoDTO', blob);
    formData.append('keyLink', keyLink);
    return http.put('cargaDocumentos/descargarArchivos', formData, config);
  }

  downloadArchivoAWS = (itemFile) => {
    const config = {
      headers: {
        'Content-type': 'multipart/form-data'
      },
      responseType: 'blob'
    };
    const json = JSON.stringify(itemFile);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('archivoDTO', blob);
    return http.put('cargaDocumentos/downloadArchivo', formData, config);
  }

  descargarPDF = (hiperligaDTO) => {
    const config = {
      responseType: 'blob'
    };
    const json = JSON.stringify(hiperligaDTO);
    return http.put('cargaDocumentos/exportarComprobante', json, config);
  }
}

export default new HiperligaService();
