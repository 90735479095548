import React from 'react';
import {
  Box,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  ClickAwayListener
} from '@material-ui/core';
import {
  Add,
  Check,
  Delete,
  Search,
  TuneTwoTone as Menu,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import ordenService from 'src/services/ordenService';
import { format } from 'date-fns';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import BusquedaOrdenes from './BusquedaOrdenes';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paperRoot: {
    padding: '2px 6px',
    display: 'flex'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const OrdenesToolBar = ({
  className,
  setOpenAltaOrden,
  selectedRows,
  setOpenBackDrop,
  setOpenSnackError,
  setServerError,
  setRowsOrdenes,
  rowsOrdenes,
  setServerMessage,
  setOpenSnackSuccess,
  setSelectedRows,
  rolUsuario,
  comboOrdenEstatus,

}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const paperRef = React.useRef();
  const openPopper = Boolean(anchorEl);
  const [fechaDesde, setFechaDesde] = React.useState(null);
  const [fechaHasta, setFechaHasta] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogTerminar, setOpenDialogTerminar] = React.useState(false);

  const handleClickAway = () => {
    setAnchorEl(null);
    setFechaDesde(null);
    setFechaHasta(null);
  };
  const handleClickPopper = () => {
    setAnchorEl(anchorEl ? null : paperRef.current);
    reset({});
  };
  const removeRows = () => {
    setOpenDialog(false);
    const asyncCall = async () => {
      setOpenBackDrop(true);
      await ordenService.eliminaOrden(selectedRows).then((resp) => {
        if (resp.data.Errores !== undefined && resp.data.Errores.length > 0) {
          setServerError(`Fallo al eliminar: ${resp.data.Errores.length} clientes.`);
          setOpenSnackError(true);
        }
        if (resp.data.Eliminados !== undefined && resp.data.Eliminados.length > 0) {
          resp.data.Eliminados.map((del) => {
            console.log(del);
            rowsOrdenes = rowsOrdenes.filter((item) => item.id !== del);
            return rowsOrdenes;
          });
          setSelectedRows([]);
          setServerMessage(`Ordenes eliminadas: ${resp.data.Eliminados.length}`);
          setOpenSnackSuccess(true);
          setRowsOrdenes(rowsOrdenes);
        }
      }).catch((error) => {
        setServerError(error.message);
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    asyncCall();
  };
  const terminarRows = () => {
    setOpenDialogTerminar(false);
    const asyncCall = async () => {
      setOpenBackDrop(true);
      await ordenService.terminarOrdenes(selectedRows).then(() => {
        setServerMessage(`Ordenes terminadas: ${selectedRows.length}`);
        setOpenSnackSuccess(true);
        selectedRows.map((del) => {
          const modOrden = rowsOrdenes.find((row) => row.id === del);
          if (modOrden !== undefined && modOrden !== null) {
            modOrden.descOrdenEstatus = 'TERMINADO';
          }
          return modOrden;
        });
      }).catch((error) => {
        setOpenSnackError(true);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
      });
      setOpenBackDrop(false);
    };
    asyncCall();
  };
  const onSubmit = (formulario, searchType) => {
    console.log('Formulario busqueda de ordenes:', formulario);
    const asyncCall = async (datos, type) => {
      setOpenBackDrop(true);
      if (fechaDesde !== null && fechaDesde !== '') {
        datos.desde = format(fechaDesde, 'yyyy-MM-dd');
      }
      if (fechaHasta !== null && fechaHasta !== '') {
        datos.hasta = format(fechaHasta, 'yyyy-MM-dd');
      }
      await ordenService.searchOrdenes(datos, false).then((resp) => {
        setRowsOrdenes(resp.data);
        if (resp.data.length > 0 && type === 'adv') {
          handleClickPopper();
        }
      }).catch((error) => {
        setRowsOrdenes([]);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario, searchType).catch((error) => {
      setRowsOrdenes([]);
      const mensajeError = `Error al realiza la búsqueda: ${error.message}`;
      setServerError(mensajeError);
      setOpenSnackError(true);
      setOpenBackDrop(false);
    });
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.title}>
        Busqueda de Ordenes
      </Typography>
      <Divider className={classes.bottomSpace} />
      {rolUsuario === 'ADMINISTRADOR'
        ? (
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="Terminar Orden">
              <div>
                <Fab
                  color="primary"
                  className={clsx(classes.rightMargin, classes.bottomSpace)}
                  disabled={isEmpty(selectedRows)}
                  onClick={() => setOpenDialogTerminar(true)}
                >
                  <Check />
                </Fab>
              </div>
            </Tooltip>
            <Tooltip title="Eliminar">
              <div>
                <Fab
                  color="primary"
                  className={clsx(classes.rightMargin, classes.bottomSpace)}
                  disabled={isEmpty(selectedRows)}
                  onClick={() => setOpenDialog(true)}
                >
                  <Delete />
                </Fab>
              </div>
            </Tooltip>
            <Tooltip title="Agregar">
              <Fab
                color="primary"
                onClick={() => setOpenAltaOrden(true, 0)}
                className={classes.bottomSpace}
              >
                <Add />
              </Fab>
            </Tooltip>
          </Box>
        ) : (
          null
        )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid
            className={classes.item}
            item
            md={6}
            sm={12}
            xs={12}
            align="center"
          >
            <Paper
              ref={paperRef}
              component="form"
              className={classes.paperRoot}
              onSubmit={handleSubmit((data) => onSubmit(data, 'single'))}
            >
              <InputBase
                inputRef={register}
                className={classes.input}
                placeholder="Folio"
                inputProps={{ 'aria-label': 'buscar usuario' }}
                name="folio"
                onFocus={handleClickAway}
                onInput={genService.toUpperCase}
              />
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                type="submit"
              >
                <Search />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton className={classes.iconButton} aria-label="menu" onClick={handleClickPopper}>
                <Menu />
              </IconButton>
            </Paper>
          </Grid>
          <BusquedaOrdenes
            openPopper={openPopper}
            anchorEl={anchorEl}
            setFechaDesde={setFechaDesde}
            setFechaHasta={setFechaHasta}
            fechaDesde={fechaDesde}
            fechaHasta={fechaHasta}
            onSubmitAdv={onSubmit}
            comboOrdenEstatus={comboOrdenEstatus}
          />
        </Grid>
      </ClickAwayListener>
      <ConfirmationDialog
        keepMounted
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        confirm={removeRows}
        title="Eliminar registros"
        message="Se eliminarán las ordenes seleccionadas."
      />
      <ConfirmationDialog
        keepMounted
        open={openDialogTerminar}
        onClose={() => setOpenDialogTerminar(false)}
        confirm={terminarRows}
        title="Finalizar Ordenes"
        message={selectedRows.length > 1
          ? (
            `Se terminarán ${selectedRows.length} ordenes.`
          ) : (
            'Se terminará la orden seleccionada'
          )}
      />
    </div>
  );
};
OrdenesToolBar.propTypes = {
  className: PropTypes.string,
  setOpenAltaOrden: PropTypes.func,
  selectedRows: PropTypes.array,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  setRowsOrdenes: PropTypes.func,
  rowsOrdenes: PropTypes.array,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setSelectedRows: PropTypes.func,
  rolUsuario: PropTypes.string,
  comboOrdenEstatus: PropTypes.array
};

export default OrdenesToolBar;
