import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Divider,
  Paper,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import combosService from 'src/services/combosService';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DialogForms from 'src/views/commons/DialogForms';
import AltaVivienda from './AltaVivienda';
import Details from './Details';
import InputData from './InputData';
import AltaPrincipal from './AltaPrincipal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1.5)
  },
  paperRoot: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(3),
  },
}));

const Plantilla = ({
  className,
  setOpenBackDrop,
  setOpenSnackSuccess,
  setServerMessage,
  setServerError,
  setOpenSnackError,
  rolUsuario
}) => {
  const classes = useStyles();
  const viviendaRef = useRef();
  const principalRef = useRef();
  const formName = 'formEval';

  const [tabValue, setTabValue] = useState(0);
  const [evaluacion, setEvaluacion] = useState();
  const [comboEscuelas, setComboEscuelas] = useState([]);
  const [comboViviendaA, setComboViviendaA] = useState([]);
  const [comboViviendaB1, setComboViviendaB1] = useState([]);
  const [comboViviendaB2, setComboViviendaB2] = useState([]);
  const [comboViviendaB3, setComboViviendaB3] = useState([]);
  const [comboViviendaC, setComboViviendaC] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [tituloDialog, setTituloDialog] = useState('');
  const [itemDTO, setItemDTO] = useState();
  const [formData, setFormData] = useState({});
  const [blockFields, setBlockFields] = useState(false);
  const [valComboEscuelas, setValComboEscuelas] = useState({});
  const [comboPrincipalA, setComboPrincipalA] = useState([]);
  const [comboPrincipalB1, setComboPrincipalB1] = useState([]);
  const [comboPrincipalB2, setComboPrincipalB2] = useState([]);
  const [comboPrincipalB3, setComboPrincipalB3] = useState([]);
  const [comboPrincipalC, setComboPrincipalC] = useState([]);
  const [openAddDialogPrin, setOpenAddDialogPrin] = useState(false);
  const [formDataPrin, setFormDataPrin] = useState({});
  const [resetPlantilla, setResetPlantilla] = useState(0);

  const [valComboVivienda, setValComboVivienda] = useState([]);
  const [valComboPrincipal, setValComboPrincipal] = useState([]);

  useEffect(() => {
    const getCombosAsync = async () => {
      await combosService.comboEscuelas().then((resp) => {
        setComboEscuelas(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de escueas: '${error}`);
      });

      await combosService.comboVivienda('A').then((resp) => {
        setComboViviendaA(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de viienda A: '${error}`);
      });
      await combosService.comboVivienda('B-1').then((resp) => {
        setComboViviendaB1(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de vivienda B1: '${error}`);
      });
      await combosService.comboVivienda('B-2').then((resp) => {
        setComboViviendaB2(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de vivienda B2: '${error}`);
      });
      await combosService.comboVivienda('B-3').then((resp) => {
        setComboViviendaB3(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de vivienda B3: '${error}`);
      });
      await combosService.comboVivienda('C').then((resp) => {
        setComboViviendaC(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de vivienda C: '${error}`);
      });

      await combosService.comboPrincipal('A').then((resp) => {
        setComboPrincipalA(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de principal A: '${error}`);
      });
      await combosService.comboPrincipal('B-1').then((resp) => {
        setComboPrincipalB1(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de principal B1: '${error}`);
      });
      await combosService.comboPrincipal('B-2').then((resp) => {
        setComboPrincipalB2(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de principal B2: '${error}`);
      });
      await combosService.comboPrincipal('B-3').then((resp) => {
        setComboPrincipalB3(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de principal B3: '${error}`);
      });
      await combosService.comboPrincipal('C').then((resp) => {
        setComboPrincipalC(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de principal C: '${error}`);
      });
    };
    getCombosAsync();
    console.log(setOpenSnackSuccess);
    console.log(setServerMessage);
  }, []);

  return (
    <Page
      className={classes.root}
      title="Plantilla para Evaluación"
    >
      <Container maxWidth="lg">
        <div className={clsx(classes.root, className)}>
          <Typography className={classes.title}>
            Plantilla para Evaluaciones
          </Typography>
          <Divider className={classes.bottomSpace} />
          <Paper className={classes.paperRoot}>
            <InputData
              setOpenBackDrop={setOpenBackDrop}
              evaluacion={evaluacion}
              setEvaluacion={setEvaluacion}
              comboEscuelas={comboEscuelas}
              formName={formName}
              setValComboVivienda={setValComboVivienda}
              setValComboPrincipal={setValComboPrincipal}
              blockFields={blockFields}
              setBlockFields={setBlockFields}
              valComboEscuelas={valComboEscuelas}
              setValComboEscuelas={setValComboEscuelas}
              setServerError={setServerError}
              setOpenSnackError={setOpenSnackError}
              valComboVivienda={valComboVivienda}
              valComboPrincipal={valComboPrincipal}
              comboViviendaA={comboViviendaA}
              comboViviendaB1={comboViviendaB1}
              comboViviendaB2={comboViviendaB2}
              comboViviendaB3={comboViviendaB3}
              comboViviendaC={comboViviendaC}
              comboPrincipalA={comboPrincipalA}
              comboPrincipalB1={comboPrincipalB1}
              comboPrincipalB2={comboPrincipalB2}
              comboPrincipalB3={comboPrincipalB3}
              comboPrincipalC={comboPrincipalC}
              setResetPlantilla={setResetPlantilla}
              setTabValue={setTabValue}
            />
          </Paper>
          <Paper className={classes.paperRoot}>
            <Details
              tabValue={tabValue}
              setTabValue={setTabValue}
              evaluacion={evaluacion}
              setEvaluacion={setEvaluacion}
              comboViviendaA={comboViviendaA}
              comboViviendaB1={comboViviendaB1}
              comboViviendaB2={comboViviendaB2}
              comboViviendaB3={comboViviendaB3}
              comboViviendaC={comboViviendaC}
              rolUsuario={rolUsuario}
              setOpenAddDialog={setOpenAddDialog}
              setTituloDialog={setTituloDialog}
              setItemDTO={setItemDTO}
              comboPrincipalA={comboPrincipalA}
              comboPrincipalB1={comboPrincipalB1}
              comboPrincipalB2={comboPrincipalB2}
              comboPrincipalB3={comboPrincipalB3}
              comboPrincipalC={comboPrincipalC}
              setOpenAddDialogPrin={setOpenAddDialogPrin}
              formName={formName}
              setServerError={setServerError}
              setOpenSnackError={setOpenSnackError}
              valComboVivienda={valComboVivienda}
              valComboPrincipal={valComboPrincipal}
              setValComboVivienda={setValComboVivienda}
              setValComboPrincipal={setValComboPrincipal}
              setOpenSnackSuccess={setOpenSnackSuccess}
              setServerMessage={setServerMessage}
              valComboEscuelas={valComboEscuelas}
              setValComboEscuelas={setValComboEscuelas}
              setOpenBackDrop={setOpenBackDrop}
              resetPlantilla={resetPlantilla}
              setResetPlantilla={setResetPlantilla}
            />
          </Paper>
        </div>
        <DialogForms
          setOpenDialog={setOpenAddDialog}
          openDialog={openAddDialog}
          submitButtonName="GUARDAR"
          titulo={tituloDialog}
          formName="altaVivienda"
          size="md"
        >
          <AltaVivienda
            ref={viviendaRef}
            formData={formData}
            setFormData={setFormData}
            itemDTO={itemDTO}
            formName="altaVivienda"
            setOpenDialog={setOpenAddDialog}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
            comboViviendaA={comboViviendaA}
            comboViviendaB1={comboViviendaB1}
            comboViviendaB2={comboViviendaB2}
            comboViviendaB3={comboViviendaB3}
            comboViviendaC={comboViviendaC}
            setComboViviendaA={setComboViviendaA}
            setComboViviendaB1={setComboViviendaB1}
            setComboViviendaB2={setComboViviendaB2}
            setComboViviendaB3={setComboViviendaB3}
            setComboViviendaC={setComboViviendaC}
          />
        </DialogForms>
        <DialogForms
          setOpenDialog={setOpenAddDialogPrin}
          openDialog={openAddDialogPrin}
          submitButtonName="GUARDAR"
          titulo={tituloDialog}
          formName="altaPrincipal"
          size="md"
        >
          <AltaPrincipal
            ref={principalRef}
            formData={formDataPrin}
            setFormData={setFormDataPrin}
            itemDTO={itemDTO}
            formName="altaPrincipal"
            setOpenDialog={setOpenAddDialogPrin}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
            comboPrincipalA={comboPrincipalA}
            comboPrincipalB1={comboPrincipalB1}
            comboPrincipalB2={comboPrincipalB2}
            comboPrincipalB3={comboPrincipalB3}
            comboPrincipalC={comboPrincipalC}
            setComboPrincipalA={setComboPrincipalA}
            setComboPrincipalB1={setComboPrincipalB1}
            setComboPrincipalB2={setComboPrincipalB2}
            setComboPrincipalB3={setComboPrincipalB3}
            setComboPrincipalC={setComboPrincipalC}
          />
        </DialogForms>
      </Container>
    </Page>
  );
};

Plantilla.propTypes = {
  className: PropTypes.string,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  rolUsuario: PropTypes.string
};

export default Plantilla;
