import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  makeStyles,
  Box,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  Autorenew,
  Edit
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import Page from 'src/components/Page';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import combosService from 'src/services/combosService';
import authService from 'src/services/auth.service';
import userService from 'src/services/usuariosService';
import DataTable from 'src/views/commons/DataTable';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import DialogForms from 'src/views/commons/DialogForms';
import AltaUsuario from './AltaUsuario';
import UsuariosToolBar from './UsuariosToolBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  }
}));

const Usuario = ({
  setOpenBackDrop, comboRoles, setComboRoles,
  setServerError, setOpenSnackError,
  setServerMessage, setOpenSnackSuccess,
  nombreUsuario, ...props
}) => {
  const [openAlta, setOpenAlta] = useState(false);
  const [rowsUsers, setRowsUsers] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [formData, setFormData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [idUsuario, setIdUsuario] = useState(0);
  const [comboClientes, setComboClientes] = useState([]);
  const classes = useStyles();
  const formName = 'formUsuario';
  const usuarioRef = useRef();

  const handleUsuariosRef = () => {
    usuarioRef.current.resetUsuariosForm();
  };

  useEffect(() => {
    const getComboRoles = async () => {
      const newRoles = [];
      setOpenBackDrop(true);
      if (comboRoles.length === 0) {
        await authService.roles().then((res) => {
          res.data.forEach((val) => {
            const rolTemp = { value: val.rolEnum, text: val.descripcion };
            newRoles.push(rolTemp);
          });
          setComboRoles(newRoles);
        }).catch((error) => {
          setOpenBackDrop(false);
          console.log(`'Error al cargar el combo de roles: '${error}`);
        });
      }
      await combosService.comboClientes().then((resp) => {
        setComboClientes(resp);
      }).catch((error) => {
        setOpenBackDrop(false);
        console.log(`'Error al cargar el combo de clientes: '${error}`);
      });
      setOpenBackDrop(false);
    };
    getComboRoles();
  }, []);
  const onClickEdit = (paramRow) => {
    setFormData(paramRow);
    setOpenAlta(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedRows([]);
  };
  const onClickUpdatePw = () => {
    const awaitUpdatePw = async () => {
      setOpenBackDrop(true);
      await userService.resetPassword(selectedRows[0]).then((resp) => {
        if (resp.data != null && resp.data.errorCode === 204) {
          setOpenSnackError(true);
          setServerError(resp.data.mensaje);
        } else {
          setOpenSnackSuccess(true);
          setServerMessage(resp.data.mensaje);
        }
      }).catch((error) => {
        setOpenBackDrop(false);
        if (error.response) {
          setServerError(error.response.data.message);
        } else {
          setServerError(`${error.message}: No fue posible conectar con el servidor`);
        }
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    awaitUpdatePw();
    handleCloseDialog();
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 90,
      minWidth: 90,
      headerClassName: 'header-datagrid'
    },
    {
      field: 'nombreUsuario',
      headerName: 'Usuario',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombre',
      headerName: 'Nombre',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'rolDesc',
      headerName: 'Rol',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'nombreCliente',
      headerName: 'Cliente',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'fechaCreacion',
      headerName: 'Creación',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          console.log(params.row);
          onClickEdit(params.row);
        };
        const updatePassword = () => {
          setOpenDialog(true);
          setSelectedRows([params.row.id]);
        };
        return (
          <div>
            <Tooltip title="Editar">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Reset Password">
              <IconButton
                className={classes.customHoverFocus}
                onClick={updatePassword}
                fontSize="small"
                color="primary"
              >
                <Autorenew fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  return (
    <Page
      className={classes.root}
      title="Busqueda de Usuarios"
    >
      <Container maxWidth="lg">
        <UsuariosToolBar
          setOpenAlta={setOpenAlta}
          setRowsUsers={setRowsUsers}
          rowsUsers={rowsUsers}
          setOpenBackDrop={setOpenBackDrop}
          selectedRows={selectedRows}
          setOpenSnackSuccess={setOpenSnackSuccess}
          setOpenSnackError={setOpenSnackError}
          setServerError={setServerError}
          setServerMessage={setServerMessage}
          preloadedValues={comboRoles}
          setSelectedRows={setSelectedRows}
          {...props}
        />
        <Box mt={3} />
        <DataTable
          rows={rowsUsers}
          columns={columns}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          nombreUsuario={nombreUsuario}
          activateCheckBox
        />
        <DialogForms
          setOpenDialog={setOpenAlta}
          openDialog={openAlta}
          submitButtonName={idUsuario === 0 ? 'GUARDAR' : 'ACTUALIZAR'}
          actionButtonName={idUsuario === 0 ? 'LIMPIAR' : undefined}
          titulo={idUsuario === 0 ? 'Alta de Usuario' : 'Editar Usuario'}
          formName={formName}
          handleRef={handleUsuariosRef}
          size="md"
        >
          <AltaUsuario
            ref={usuarioRef}
            preloadedValues={comboRoles}
            formData={formData}
            setFormData={setFormData}
            setOpenBackDrop={setOpenBackDrop}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
            rowsUsers={rowsUsers}
            idUsuario={idUsuario}
            setIdUsuario={setIdUsuario}
            formName={formName}
            comboClientes={comboClientes}
            setOpenDialog={setOpenAlta}
          />
        </DialogForms>
        <ConfirmationDialog
          keepMounted
          open={openDialog}
          onClose={handleCloseDialog}
          confirm={onClickUpdatePw}
          title="Actualizar contraseña"
          message="Seguro que desea actualizar la contraseña?"
        />
      </Container>
    </Page>
  );
};

Usuario.propTypes = {
  setOpenBackDrop: PropTypes.func,
  comboRoles: PropTypes.array,
  setComboRoles: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  nombreUsuario: PropTypes.string
};

export default Usuario;
