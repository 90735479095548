import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Card,
  Popper,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import genService from 'src/services/generalServices';
import esLocale from 'date-fns/locale/es';

const useStyles = makeStyles((theme) => ({
  root: {},
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
}));

const BusquedaOrdenes = ({
  openPopper,
  anchorEl,
  setFechaDesde,
  setFechaHasta,
  fechaDesde,
  fechaHasta,
  onSubmitAdv,
  comboOrdenEstatus,
}) => {
  const { register, handleSubmit, control } = useForm();
  const classes = useStyles();
  const [openFechaHasta, setOpenFechaHasta] = React.useState(false);
  const [openFechaDesde, setOpenFechaDesde] = React.useState(false);

  return (
    <Popper open={openPopper} anchorEl={anchorEl} getcontentanchorel={null}>
      <form onSubmit={handleSubmit((data) => onSubmitAdv(data, 'adv'))}>
        <Card className={classes.root}>
          <CardHeader subheader="Búsqueda avanzada" style={{ padding: '5px' }} />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  inputRef={register}
                  label="Folio"
                  size="small"
                  name="folio"
                  onInput={genService.toUpperCase}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  inputRef={register}
                  label="Investigado"
                  size="small"
                  name="investigado"
                  onInput={genService.toUpperCase}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="desde"
                    open={openFechaDesde}
                    onOpen={() => setOpenFechaDesde(true)}
                    /* onClick={() => setOpenFechaDesde(true)} */
                    onClose={() => setOpenFechaDesde(false)}
                    onChange={setFechaDesde}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Desde"
                    value={fechaDesde}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item md={6} xs={12}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    name="hasta"
                    open={openFechaHasta}
                    onOpen={() => setOpenFechaHasta(true)}
                    onClose={() => setOpenFechaHasta(false)}
                    /* onClick={() => setOpenFechaHasta(true)} */
                    onChange={setFechaHasta}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Hasta"
                    value={fechaHasta}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <FormControl
                  variant="outlined"
                  size="small"
                  className={classes.formControl}
                  fullWidth
                >
                  <InputLabel htmlFor="ordenEstatus-select">
                    Seleccione un Estatus
                  </InputLabel>
                  <Controller
                    control={control}
                    name="idOrdenEstatus"
                    defaultValue={0}
                    onChange={([selected]) => {
                      return { value: selected };
                    }}
                    as={(
                      <Select
                        id="OrdenEstatus-select"
                        label="Seleccione un Estatus"
                        value=""
                        MenuProps={{
                          disablePortal: true,
                          anchorEl: this,
                          getContentAnchorEl: null,
                          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                          transformOrigin: { vertical: 'top', horizontal: 'center' },
                          PaperProps: { style: { position: 'sticky' } }
                        }}
                      >
                        <MenuItem key={0} value={0}>Seleccione un Estatus</MenuItem>
                        {comboOrdenEstatus.map((status) => (
                          <MenuItem
                            key={status.value}
                            value={status.value}
                          >
                            {status.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.bottomSpace} />
            <Box display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                Buscar
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Popper>
  );
};

BusquedaOrdenes.propTypes = {
  anchorEl: PropTypes.object,
  openPopper: PropTypes.bool,
  setFechaDesde: PropTypes.func,
  setFechaHasta: PropTypes.func,
  fechaDesde: PropTypes.string,
  fechaHasta: PropTypes.string,
  onSubmitAdv: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
};
export default BusquedaOrdenes;
