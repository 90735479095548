import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  InputBase,
  Paper,
  IconButton,
  Fab,
  Tooltip,
  Grid,
  ClickAwayListener
} from '@material-ui/core';
import {
  Add,
  TuneTwoTone as Menu,
  Search,
  Delete
} from '@material-ui/icons';
import BusquedaUsuarios from 'src/views/config/Usuarios/BusquedaUsuarios';
import usuariosService from 'src/services/usuariosService';
import genService from 'src/services/generalServices';
import { isEmpty } from 'lodash';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {},
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paperRoot: {
    padding: '2px 6px',
    display: 'flex'
  },
  dropdown: {
    position: 'revert',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid',
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const UsuariosToolBar = ({
  className,
  setOpenAlta,
  setRowsUsers,
  rowsUsers,
  setOpenBackDrop,
  selectedRows,
  setOpenSnackSuccess,
  setOpenSnackError,
  setServerError,
  setServerMessage,
  preloadedValues,
  setSelectedRows,
  rolUsuario
}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [fechaDesde, setFechaDesde] = React.useState(null);
  const [fechaHasta, setFechaHasta] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const paperRef = React.useRef();
  const handleClickPopper = () => {
    setAnchorEl(anchorEl ? null : paperRef.current);
    setFechaDesde(null);
    setFechaHasta(null);
    reset({});
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    setFechaDesde(null);
    setFechaHasta(null);
  };
  const removeRows = () => {
    setOpenDialog(false);
    const asyncCall = async () => {
      setOpenBackDrop(true);
      await usuariosService.deleteUser(selectedRows).then((resp) => {
        if (resp.data.Errores !== undefined && resp.data.Errores.length > 0) {
          setServerError(`Fallo al eliminar: ${resp.data.Errores.length} usuarios.`);
          setOpenSnackError(true);
        }
        if (resp.data.Eliminados !== undefined && resp.data.Eliminados.length > 0) {
          setSelectedRows([]);
          resp.data.Eliminados.map((del) => {
            rowsUsers = rowsUsers.filter((item) => item.id !== del);
            return rowsUsers;
          });
          setServerMessage(`Usuarios eliminados: ${resp.data.Eliminados.length}`);
          setOpenSnackSuccess(true);
          setRowsUsers(rowsUsers);
        }
      }).catch((error) => {
        setServerError(error.message);
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    asyncCall();
  };
  const onSubmit = (formulario, searchType) => {
    const asyncCall = async (datos, type) => {
      setOpenBackDrop(true);
      if (fechaDesde !== null && fechaDesde !== '') {
        datos.desde = format(fechaDesde, 'yyyy-MM-dd');
      }
      if (fechaHasta !== null && fechaHasta !== '') {
        datos.hasta = format(fechaHasta, 'yyyy-MM-dd');
      }
      await usuariosService.searchUsuarios(datos).then((resp) => {
        if (resp.data.errorCode === 204) {
          setOpenSnackError(true);
          setServerError(resp.data.mensaje);
          setRowsUsers([]);
        } else {
          setRowsUsers(resp.data);
          if (resp.data.length > 0 && type === 'adv') {
            handleClickPopper();
          }
        }
      }).catch((error) => {
        setRowsUsers([]);
        setServerError(error.message);
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario, searchType);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.title}>
        Busqueda de Usuarios
      </Typography>
      <Divider className={classes.bottomSpace} />
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Eliminar">
          <div>
            <Fab
              color="primary"
              className={clsx(classes.rightMargin, classes.bottomSpace)}
              disabled={isEmpty(selectedRows)}
              onClick={() => setOpenDialog(true)}
            >
              <Delete />
            </Fab>
          </div>
        </Tooltip>
        <Tooltip title="Agregar">
          <Fab
            color="primary"
            onClick={() => setOpenAlta(true)}
            className={classes.bottomSpace}
            disabled={rolUsuario !== 'ADMINISTRADOR'}
          >
            <Add />
          </Fab>
        </Tooltip>
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid
            className={classes.item}
            item
            md={6}
            sm={12}
            xs={12}
            align="center"
          >
            <Paper
              ref={paperRef}
              component="form"
              className={classes.paperRoot}
              onSubmit={handleSubmit((data) => onSubmit(data, 'single'))}
              elevation={6}
            >
              <InputBase
                inputRef={register}
                className={classes.input}
                placeholder="Nombre"
                inputProps={{ 'aria-label': 'buscar usuario' }}
                name="nombre"
                onFocus={handleClickAway}
                onInput={genService.toUpperCase}
              />
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                type="submit"
              >
                <Search />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton className={classes.iconButton} aria-label="menu" onClick={handleClickPopper}>
                <Menu />
              </IconButton>
            </Paper>
          </Grid>
          <BusquedaUsuarios
            openPopper={openPopper}
            anchorEl={anchorEl}
            setOpenBackDrop={setOpenBackDrop}
            setRowsUsers={setRowsUsers}
            setFechaDesde={setFechaDesde}
            setFechaHasta={setFechaHasta}
            fechaDesde={fechaDesde}
            fechaHasta={fechaHasta}
            onSubmitAdv={onSubmit}
            preloadedValues={preloadedValues}
          />
        </Grid>
      </ClickAwayListener>
      <ConfirmationDialog
        keepMounted
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        confirm={removeRows}
        title="Eliminar registros"
        message="Seguro que desea eleminar el(los) registro(s)?"
      />
    </div>
  );
};
UsuariosToolBar.propTypes = {
  className: PropTypes.string,
  setOpenAlta: PropTypes.func,
  setRowsUsers: PropTypes.func,
  setOpenBackDrop: PropTypes.func,
  selectedRows: PropTypes.array,
  rowsUsers: PropTypes.array,
  setOpenSnackSuccess: PropTypes.func,
  setServerError: PropTypes.func,
  setServerMessage: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  preloadedValues: PropTypes.array,
  setSelectedRows: PropTypes.func,
  rolUsuario: PropTypes.string,
};
export default UsuariosToolBar;
