import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.green[500], /* '#84af2d', */
      contrastText: '#fff'
    },
    secondary: {
      main: colors.blue[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
    AccordionSummary: {
      background: '#4caf508a'
    }
  },
  shadows,
  typography,
  /* overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
  } */
});

export default theme;
