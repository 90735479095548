import http from '../http-common';

class CombosService {
  comboEstados = async () => {
    const datosEstados = [];
    await http.get('/catalogo/estados').then((resp) => {
      resp.data.forEach((val) => {
        const estado = { value: val.id, label: val.nombreEstado };
        datosEstados.push(estado);
      });
    });
    return datosEstados;
  }

  comboMunicipios = async (idEstado) => {
    const datosMunicipios = [];
    await http.get(`/catalogo/municipios/${idEstado}`).then((resp) => {
      resp.data.forEach((val) => {
        const municipio = { value: val.id, label: val.nombreMunicipio };
        datosMunicipios.push(municipio);
      });
    });
    return datosMunicipios;
  }

  comboTipoCliente = async () => {
    const datosTipoCliente = [];
    await http.get('/catalogo/tiposCliente').then((resp) => {
      resp.data.forEach((val) => {
        const tipoCliente = { value: val.tipoCliente, label: val.tipoCliente };
        datosTipoCliente.push(tipoCliente);
      });
    });
    return datosTipoCliente;
  }

  comboClientes = async () => {
    const datosClientes = [];
    await http.get('/catalogo/clientesActivos').then((resp) => {
      resp.data.forEach((val) => {
        const listCliente = {
          value: val.id,
          label: val.nombreCliente,
          tipoCliente: val.tipoCliente
        };
        datosClientes.push(listCliente);
      });
    });
    return datosClientes;
  }

  comboEscuelas = async () => {
    const datosEscuelas = [];
    await http.get('/catalogo/escuelasEvaluacion').then((resp) => {
      resp.data.forEach((val) => {
        const listEscuelas = {
          value: val.id,
          label: val.nombreCliente,
          plantilla: val.tienePlantilla
        };
        datosEscuelas.push(listEscuelas);
      });
    });
    return datosEscuelas;
  }

  comboEscuelasEvaluacion = async () => {
    const datosEscuelas = [];
    await http.get('/catalogo/escuelasEvaluacionPlantilla').then((resp) => {
      resp.data.forEach((val) => {
        const listEscuelas = {
          value: val.id,
          label: val.nombreCliente,
          plantilla: val.tienePlantilla
        };
        datosEscuelas.push(listEscuelas);
      });
    });
    return datosEscuelas;
  }

  comboAnalistas = async () => {
    const datosAnalistas = [];
    await http.get('/catalogo/analistasActivos').then((resp) => {
      resp.data.forEach((val) => {
        const listUsuarios = {
          value: val.id,
          label: val.nombre,
          usuario: val.nombreUsuario
        };
        datosAnalistas.push(listUsuarios);
      });
    });
    return datosAnalistas;
  }

  comboOrdenEstatus = async () => {
    const datosOrdenEstatus = [];
    await http.get('/catalogo/getOrdenEstatus').then((resp) => {
      resp.data.forEach((val) => {
        const listOrdenEstatus = {
          value: val.id,
          label: val.ordenEstatus
        };
        datosOrdenEstatus.push(listOrdenEstatus);
      });
    });
    return datosOrdenEstatus;
  }

  comboUsuariosCliente = async (idCliente, isAdmin) => {
    const datosUsuarios = [];
    let requestURL = '';
    if (isAdmin) {
      requestURL = '/catalogo/getUsuariosEmail';
    } else {
      requestURL = '/catalogo/getUsuariosCliente';
    }
    await http.get(`${requestURL}/${idCliente}`).then((resp) => {
      resp.data.forEach((val) => {
        const listaUsuarios = {
          value: val.id,
          usuario: val.nombreUsuario,
          nombreUsuario: val.nombre,
          email: val.email,
          supervisor: val.supervisor
        };
        datosUsuarios.push(listaUsuarios);
      });
    });
    return datosUsuarios;
  }

  comboLinkEstatusActivos = async () => {
    const datosLinkEstatus = [];
    await http.get('/catalogo/linkEstatusActivos').then((resp) => {
      resp.data.forEach((val) => {
        const linkEstatus = {
          value: val.id,
          label: val.descripcion
        };
        datosLinkEstatus.push(linkEstatus);
      });
    });
    return datosLinkEstatus;
  }

  comboGradoEscolar = async (idCliente) => {
    const datosGradoEscolar = [];
    await http.get(`/catalogo/gradoEscolar/${idCliente}`).then((resp) => {
      if (resp.data !== undefined && resp.data.length > 0) {
        resp.data.forEach((val) => {
          const gradoEscolar = {
            value: val.id,
            label: val.descripcion,
            labelRender: val.descripcion
          };
          datosGradoEscolar.push(gradoEscolar);
        });
      }
    });
    return datosGradoEscolar;
  }

  addComboMunicipios = (idEstado, mapaMunicipios, setComboMunicipios,
    setMapaMunicipios) => {
    const existMunicipios = mapaMunicipios.filter((item) => item.key === idEstado);
    if (existMunicipios !== undefined && existMunicipios.length > 0) {
      setComboMunicipios(existMunicipios[0].value);
    } else {
      this.comboMunicipios(idEstado).then((resp) => {
        setComboMunicipios(resp);
        setMapaMunicipios((newElement) => [...newElement, { key: idEstado, value: resp }]);
      });
    }
  };

  comboFileTypeLink = async () => {
    const fileTypeLink = [];
    await http.get('/cargaDocumentos/tipoArchivo').then((resp) => {
      resp.data.forEach((val) => {
        const itemFileType = {
          value: val.id,
          label: val.descripcion
        };
        fileTypeLink.push(itemFileType);
      });
    });
    return fileTypeLink;
  }

  comboReportes = async () => {
    const datosReportes = [];
    await http.get('/catalogo/reportes').then((resp) => {
      resp.data.forEach((val) => {
        const listReportes = {
          value: val.idReporte,
          label: val.descripcion
        };
        datosReportes.push(listReportes);
      });
    });
    return datosReportes;
  }

  comboVivienda = async (descViabilidad) => {
    const datosVivienda = [];
    await http.get(`/catalogo/vivienda/${descViabilidad}`).then((resp) => {
      resp.data.forEach((val) => {
        const listVivienda = {
          value: val.idVivienda,
          label: val.descripcion,
          idViabilidad: val.idViabilidad,
          descViabilidad: val.descViabilidad
        };
        datosVivienda.push(listVivienda);
      });
    });
    return datosVivienda;
  }

  comboPrincipal = async (descViabilidad) => {
    const datosPrincipal = [];
    await http.get(`/catalogo/principal/${descViabilidad}`).then((resp) => {
      resp.data.forEach((val) => {
        const listPrincipal = {
          value: val.idPrincipal,
          label: val.descripcion,
          idViabilidad: val.idViabilidad,
          descViabilidad: val.descViabilidad
        };
        datosPrincipal.push(listPrincipal);
      });
    });
    return datosPrincipal;
  }
}

export default new CombosService();
