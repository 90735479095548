import React, { useEffect, useState, useRef } from 'react';
import {
  Container,
  Divider,
  makeStyles,
  Typography,
  Box,
  Tooltip,
  IconButton
} from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import { DoubleArrow } from '@material-ui/icons';
import clsx from 'clsx';
import combosService from 'src/services/combosService';
import evaluacionService from 'src/services/evaluacionService';
import DataTable from 'src/views/commons/DataTable';
import renderCellExpand from 'src/views/commons/RenderCellExpand';
import ChipEstatusTable from 'src/views/commons/ChipEstatusTable';
import DialogForms from 'src/views/commons/DialogForms';
import BuscarEvaluacion from './BuscarEvaluacion';
import AltaEvaluacion from './AltaEvaluacion';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1.5)
  },
  paperRoot: {
    padding: '0px 10px',
    display: 'flex'
  },
  customHoverFocus: {
    '&:hover': { color: '#fff', backgroundColor: '#4caf50' }
  },
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 2,
  },
  iconButton: {
    padding: 10,
  },
}));

const Evaluacion = ({
  className,
  setOpenBackDrop,
  setOpenSnackSuccess,
  setServerMessage,
  setServerError,
  setOpenSnackError,
  comboOrdenEstatus,
  setComboOrdenEstatus
}) => {
  const classes = useStyles();
  const formName = 'evaluacionForm';
  const evalRef = useRef();
  const [comboEscuelas, setComboEscuelas] = useState([]);
  const [mostrarBusqueda, setMostrarBusqueda] = useState(true);
  const [rowsOrdenes, setRowsOrdenes] = useState([]);
  const [openEvaluacion, setOpenEvaluacion] = useState(false);
  const [idEvaluacion, setIdEvaluacion] = useState(0);
  const [evaluacion, setEvaluacion] = useState({});
  const [comboVivienda, setComboVivienda] = useState([]);
  const [comboPrincipal, setComboPrincipal] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [confirmarSalida, setConfirmarSalida] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [cambioEvaluacion, setCambioEvaluacion] = useState(false);

  const resetEvalRef = () => {
    evalRef.current.resetEvalForm();
  };

  const validationTrigger = () => {
    evalRef.current.validationTrigger();
  };

  const onClickEdit = (paramRow) => {
    const asyncCall = async (idOrden) => {
      setOpenBackDrop(true);
      await evaluacionService.getEvaluacionByOrden(idOrden).then((resp) => {
        setEvaluacion(resp.data);
        const datosVivienda = [];
        resp.data.viviendaItems?.forEach((val) => {
          const listVivienda = {
            value: val.idVivienda,
            label: val.descripcion,
            idViabilidad: val.idViabilidad,
            descViabilidad: val.descViabilidad
          };
          datosVivienda.push(listVivienda);
        });
        setComboVivienda(datosVivienda);
        const datosPrincipal = [];
        resp.data.principalItems?.forEach((val) => {
          const listPrincipal = {
            value: val.idPrincipal,
            label: val.descripcion,
            idViabilidad: val.idViabilidad,
            descViabilidad: val.descViabilidad
          };
          datosPrincipal.push(listPrincipal);
        });
        setComboPrincipal(datosPrincipal);
        setIdEvaluacion(resp.data.id);
        if (resp.data.id !== undefined && resp.data.id > 0) {
          setDisableSubmit(false);
          setCambioEvaluacion(false);
        }
        console.log('execute action to create the evaluation', resp.data);
        console.log('disable submit', disableSubmit);
        console.log('Cambio Evaluacion', cambioEvaluacion);
      }).catch((error) => {
        console.log('error en busqueda de evaluacion', error);
        setServerError('Hubo un error al buscar la evaluacion');
        setOpenSnackError(true);
      });
      setOpenEvaluacion(true);
      setOpenBackDrop(false);
    };
    asyncCall(paramRow.id);
  };

  const preguntarSalirEval = (cerrar) => {
    if (disableSubmit || cambioEvaluacion) {
      console.log('tiene habilitado el cambio');
      setConfirmarSalida(true);
    } else {
      setEvaluacion({});
      setOpenEvaluacion(cerrar);
    }
  };

  const columns = [
    {
      field: 'folio',
      headerName: 'Folio',
      maxWidth: 120,
      minWidth: 120,
      headerClassName: 'header-datagrid',
      renderCell: renderCellExpand,
    },
    {
      field: 'nombre',
      headerName: 'Alumno',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'telefonoPrimario',
      headerName: 'Teléfono',
      minWidth: 100,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'fechaSolicitudStr',
      headerName: 'Solicitud',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand
    },
    {
      field: 'nombreUsuario',
      headerName: 'Usuario',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'descEstado',
      headerName: 'Estado',
      minWidth: 120,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      field: 'descOrdenEstatus',
      headerName: 'Estatus',
      minWidth: 110,
      flex: 1,
      renderCell: ChipEstatusTable
    },
    {
      field: 'evaluacion',
      headerName: 'Evaluación',
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      maxWidth: 60,
      minWidth: 60,
      sortable: false,
      disableClickEventBubbling: true,
      align: 'center',
      renderCell: (params) => {
        const onClick = () => {
          onClickEdit(params.row);
        };
        return (
          <div>
            <Tooltip title="Evaluación">
              <IconButton
                className={classes.customHoverFocus}
                onClick={onClick}
                fontSize="small"
                color="primary"
              >
                <DoubleArrow fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    const getCombosAsync = async () => {
      await combosService.comboEscuelasEvaluacion().then((resp) => {
        setComboEscuelas(resp);
      }).catch((error) => {
        console.log(`'Error al cargar el combo de escuelas con plantilla: '${error}`);
      });
      if (comboOrdenEstatus === undefined || comboOrdenEstatus.length === 0) {
        await combosService.comboOrdenEstatus().then((resp) => {
          setComboOrdenEstatus(resp);
        });
      }
    };
    getCombosAsync();
  }, []);

  const downloadEvaluacion = async () => {
    setOpenBack(true);
    await evaluacionService.descargarEvaluacion(evaluacion).then((resp) => {
      const link = document.createElement('a');
      console.log('Datos del la evaluacion:', resp);
      link.href = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
      link.download = `EVALUACION_${evaluacion.folio}.docx`;
      link.click();
    }).catch((e) => {
      console.log('Error al descargar el archivo:', e);
      setOpenBack(false);
      setServerError(e.message);
      setOpenSnackError(true);
    });
    setOpenBack(false);
  };

  return (
    <Page
      className={classes.root}
      title="Evaluaciones"
    >
      <Container maxWidth="lg">
        <div className={clsx(classes.root, className)}>
          <Typography className={classes.title}>
            Evaluaciones
          </Typography>
          <Divider className={classes.bottomSpace} />
          <BuscarEvaluacion
            comboEscuelas={comboEscuelas}
            mostrarBusqueda={mostrarBusqueda}
            setMostrarBusqueda={setMostrarBusqueda}
            comboOrdenEstatus={comboOrdenEstatus}
            setRowsOrdenes={setRowsOrdenes}
            setOpenBackDrop={setOpenBackDrop}
            setServerError={setServerError}
            setOpenSnackError={setOpenSnackError}
          />
          <Box mt={3} />
          <DataTable
            rows={rowsOrdenes}
            columns={columns}
          />
          <DialogForms
            setOpenDialog={preguntarSalirEval}
            openDialog={openEvaluacion}
            submitButtonName={idEvaluacion === 0 ? 'GUARDAR' : 'ACTUALIZAR'}
            actionButtonName={idEvaluacion === 0 ? 'LIMPIAR' : undefined}
            titulo={idEvaluacion === 0 ? 'NUEVA EVALUACION' : 'EVALUACION ALUMNO'}
            formName={formName}
            handleRef={resetEvalRef}
            functionButton={validationTrigger}
            size="lg"
            funcButtonName="EVALUAR"
            disableButton={disableSubmit}
            downloadFunction={downloadEvaluacion}
            downloadButtonName="DESCARGAR"
            disableDownload={cambioEvaluacion || disableSubmit}
          >
            <AltaEvaluacion
              formName={formName}
              ref={evalRef}
              setIdEvaluacion={setIdEvaluacion}
              idEvaluacion={idEvaluacion}
              comboVivienda={comboVivienda}
              comboPrincipal={comboPrincipal}
              setServerError={setServerError}
              setOpenSnackError={setOpenSnackError}
              setDisableSubmit={setDisableSubmit}
              setEvaluacion={setEvaluacion}
              evaluacion={evaluacion}
              setServerMessage={setServerMessage}
              setOpenSnackSuccess={setOpenSnackSuccess}
              setConfirmarSalida={setConfirmarSalida}
              confirmarSalida={confirmarSalida}
              setOpenEvaluacion={setOpenEvaluacion}
              openBackDrop={openBack}
              setOpenBackDrop={setOpenBack}
              downloadEvaluacion={downloadEvaluacion}
              setCambioEvaluacion={setCambioEvaluacion}
            />
          </DialogForms>
        </div>
      </Container>
    </Page>
  );
};

Evaluacion.propTypes = {
  className: PropTypes.string,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setServerMessage: PropTypes.func,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  comboOrdenEstatus: PropTypes.array,
  setComboOrdenEstatus: PropTypes.func
};

export default Evaluacion;
