class GeneralService {
  toUpperCase = (e) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    e.target.value = input.value.toUpperCase();
    input.setSelectionRange(start, end);
  };

  toLowerCase = (e) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    e.target.value = input.value.toLowerCase();
    input.setSelectionRange(start, end);
  };

  trimTextUpperCase = (e) => {
    e.target.value = e.target.value.trim().toUpperCase();
  };

  removeLetters = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/g, '');
  };

  onlyAmounts = (e) => {
    e.target.value = e.target.value.replace(/[^0-9&.]+/g, '');
  };
}

export default new GeneralService();
