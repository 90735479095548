import React from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Paper,
  Button
} from '@material-ui/core';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const GeneralDialog = ({
  open,
  changeState,
  title,
  message
}) => {
  return (
    <Dialog
      open={open}
      onClose={changeState}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={changeState} color="primary" variant="outlined">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GeneralDialog.propTypes = {
  open: PropTypes.bool,
  changeState: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};

export default GeneralDialog;
