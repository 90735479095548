import React, {
  useState, forwardRef, useEffect, useImperativeHandle
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  Table as MuiTable,
  TableBody
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import GeneralBackDrop from 'src/views/commons/GeneralBackDrop';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import genService from 'src/services/generalServices';
import evaluacionService from 'src/services/evaluacionService';
import { ExpandMore } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'block'
  },
  bottomSpace: {
    marginBottom: theme.spacing(3),
  },
  summary: {
    backgroundColor: theme.palette.AccordionSummary.background
  },
  table: {
    minWidth: 650
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main
  },
  mainColumn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold'
  },
  resColumn: {
    backgroundColor: theme.palette.AccordionSummary.background,
    fontWeight: 'bold'
  },
  tableCell: {
    color: 'white'
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#4caf50',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath"
        + " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 "
        + "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#4caf50',
    },
  },
}));

const Table = withStyles({
  root: {
    border: '1px solid rgba(204, 204, 204)',
    borderCollapse: 'inherit',
    borderSpacing: 'revert'
  },
})(MuiTable);

const TableCell = withStyles({
  root: {
    border: '1px solid rgba(204, 204, 204)',
  }
})(MuiTableCell);

const AltaEvaluacion = forwardRef(({
  formName,
  comboVivienda,
  comboPrincipal,
  setServerError,
  setOpenSnackError,
  setDisableSubmit,
  evaluacion,
  setEvaluacion,
  setIdEvaluacion,
  idEvaluacion,
  setServerMessage,
  setOpenSnackSuccess,
  setConfirmarSalida,
  confirmarSalida,
  setOpenEvaluacion,
  openBackDrop,
  setOpenBackDrop,
  setCambioEvaluacion
}, ref) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    clearErrors,
    trigger,
    watch,
    getValues,
    setError
  } = useForm();
  const classes = useStyles();
  const requiredField = 'Este campo es requerido.';
  // const mayorCero = 'Campo númerico y mayor a cero.';
  const [disableIngresos, setDisableIngresos] = useState(false);
  const [disableEgresos, setDisableEgresos] = useState(false);
  const [disableDeuda, setDisableDeuda] = useState(false);
  const [acordionDatos, setAcordionDatos] = useState(true);
  const [acordionEvaluacion, setAcordionEvaluacion] = useState(true);
  const [acordionFactores, setAcordionFactores] = useState(true);
  const [disableVivienda, setDisableVivienda] = useState(false);
  const [valueVivienda, setValueVivienda] = useState(null);
  const [disablePrincipal, setDisablePrincipal] = useState(false);
  const [valuePrincipal, setValuePrincipal] = useState(null);
  const [updateState, setUpdateState] = useState(false);

  useEffect(() => {
    console.log('el form data', evaluacion);
    if (evaluacion.matricula === '' || evaluacion.matricula === undefined) {
      evaluacion.matricula = 'N/A';
    }
    reset(evaluacion);
    if (evaluacion.sinIngresos) {
      setDisableIngresos(true);
    }
    if (evaluacion.sinEgresos) {
      setDisableEgresos(true);
    }
    if (evaluacion.sinVivienda) {
      setDisableVivienda(true);
    }
    if (evaluacion.sinPrincipal) {
      setDisablePrincipal(true);
    }
    if (evaluacion.sinDeuda) {
      setDisableDeuda(true);
    }
    if (evaluacion.idVivienda) {
      const viviendaValue = comboVivienda.filter((viv) => viv.value === evaluacion.idVivienda);
      setValueVivienda(viviendaValue[0]);
    }
    if (evaluacion.idPrincipal) {
      const principalValue = comboPrincipal.filter((prin) => prin.value === evaluacion.idPrincipal);
      setValuePrincipal(principalValue[0]);
    }
  }, []);

  const resetForm = () => {
    reset({
      sinIngresos: false
    });
    reset(evaluacion);
    // setEvaluacion({});
    setValueVivienda(null);
    setValuePrincipal(null);
    setDisableDeuda(false);
    setDisableEgresos(false);
    setDisableIngresos(false);
    setDisablePrincipal(false);
    setDisableVivienda(false);
  };

  const removeZero = (newVal, fieldName) => {
    while (newVal.length > 1 && newVal.charAt(0) === '0') {
      newVal = newVal.substring(1);
      setValue(fieldName, newVal);
    }
    return newVal;
  };

  const round2Decimals = (ammount) => {
    return Math.round(ammount * 100) / 100;
  };

  const calcularFactores = (evalua) => {
    console.log('dentro de calculo de factores', evalua);
    if (evalua !== null && evalua !== undefined) {
      console.log('Inicio de calculo');
      const colegiatura = getValues('colegiatura') ? parseFloat(getValues('colegiatura')) : 0;
      const colegiaturaHermanos = getValues('colegiaturaHermanos') ? parseFloat(getValues('colegiaturaHermanos')) : 0;
      const pctBeca = getValues('porcentajeBeca') ? parseFloat(getValues('porcentajeBeca')) : 0;
      const egresos = getValues('egresos') ? parseFloat(getValues('egresos')) : 0;
      const ingresos = getValues('ingresos') ? parseFloat(getValues('ingresos')) : 0;
      const montoDeuda = getValues('deuda') ? parseFloat(getValues('deuda')) : 0;
      const diversion = getValues('diversion') ? parseFloat(getValues('diversion')) : 0;
      let montoColegBeca = 0;
      let egresosBeca = 0;
      if (pctBeca > 0) {
        montoColegBeca = (colegiatura - ((pctBeca * colegiatura) / 100)) + colegiaturaHermanos;
        egresosBeca = egresos - ((pctBeca * colegiatura) / 100);
      } else {
        montoColegBeca = colegiatura + colegiaturaHermanos;
        egresosBeca = egresos;
      }

      evalua.colegiaturaGastos = colegiatura + colegiaturaHermanos;
      evalua.porcentajeColegiatura = egresos === 0 ? 0
        : round2Decimals(((colegiatura + colegiaturaHermanos) * 100) / egresos);
      evalua.colegiaturaBeca = round2Decimals(montoColegBeca);
      evalua.pctColegiaturaBeca = colegiatura === 0 || pctBeca === 0 ? 0
        : round2Decimals((montoColegBeca * 100) / (egresos - ((pctBeca / 100) * colegiatura)));
      evalua.montoDeficit = round2Decimals(egresos - ingresos);
      evalua.porcentajeDeficit = ingresos === 0 ? 0
        : round2Decimals(((egresos - ingresos) * 100) / ingresos);
      evalua.deficitBeca = round2Decimals(egresosBeca - ingresos);
      evalua.pctDeficitBeca = ingresos === 0 ? 0
        : round2Decimals(((egresosBeca - ingresos) * 100) / ingresos);
      evalua.montoDeuda = round2Decimals(montoDeuda);
      evalua.porcentajeDeuda = ingresos === 0 ? 0
        : round2Decimals((montoDeuda * 100) / ingresos);
      evalua.montoDiversion = round2Decimals(diversion);
      evalua.porcentajeDiversion = ingresos === 0 ? 0
        : round2Decimals((diversion * 100) / ingresos);
      evalua.montoLiquidez = round2Decimals(ingresos - egresos);
      evalua.porcentajeLiquidez = egresos === 0 ? 0
        : round2Decimals(((ingresos - egresos) * 100) / egresos);
      evalua.montoLiquidezBeca = round2Decimals(ingresos - egresosBeca);
      evalua.pctLiquidezBeca = egresosBeca === 0 ? 0
        : round2Decimals(((ingresos - egresosBeca) * 100) / egresosBeca);

      setEvaluacion(evalua);
      setUpdateState(!updateState);
      console.log('Fin de calculo', evalua);
    }
  };

  const setDatosEvaluacion = (datos) => {
    evaluacion.id = idEvaluacion;
    evaluacion.idVivienda = valueVivienda?.value;
    evaluacion.idPrincipal = valuePrincipal?.value;
    evaluacion.sinIngresos = disableIngresos;
    evaluacion.sinEgresos = disableEgresos;
    evaluacion.sinVivienda = disableVivienda;
    evaluacion.sinPrincipal = disablePrincipal;
    evaluacion.sinDeuda = disableDeuda;
    evaluacion.ingresos = datos.ingresos;
    evaluacion.egresos = datos.egresos;
    evaluacion.deuda = datos.deuda;
    evaluacion.specPrincipal = datos.specPrincipal;
    evaluacion.specVivienda = datos.specVivienda;
    evaluacion.colegiatura = datos.colegiatura;
    evaluacion.colegiaturaHermanos = datos.colegiaturaHermanos;
    evaluacion.diversion = datos.diversion;
    evaluacion.porcentajeBeca = datos.porcentajeBeca;
    evaluacion.recomendacion = datos.recomendacion;
    evaluacion.gradoEscolar = datos.gradoEscolar;
    evaluacion.folio = datos.folio;
    evaluacion.nombre = datos.nombre;
    evaluacion.nombreCliente = datos.nombreCliente;
    evaluacion.matricula = datos.matricula;
  };

  const crearEValuacion = (formulario) => {
    const asyncCall = async (datos) => {
      setOpenBackDrop(true);
      setDatosEvaluacion(datos);
      console.log('formulario de evaluacion antes:', evaluacion);
      await evaluacionService.getEvaluacionDetalles(evaluacion).then((resp) => {
        console.log('Respuesta de la evaluacion:', resp);
        setCambioEvaluacion(true);
        calcularFactores(resp.data);
        setServerMessage('Las tablas de evaluacion se han actualizado');
        setOpenSnackSuccess(true);
      }).catch((error) => {
        console.log('Error al crear la evaluacion', error);
        setServerError('No fue posible calcular la evaluacion');
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario);
  };

  useImperativeHandle(ref, () => ({
    resetEvalForm: () => {
      resetForm();
    },
    validationTrigger: async () => {
      clearErrors();
      const output = await trigger(['ingresos', 'egresos', 'deuda', 'descVivienda', 'descPrincipal']);
      if (output) {
        crearEValuacion(watch(['ingresos', 'egresos', 'deuda', 'descVivienda', 'descPrincipal']));
        clearErrors();
        setDisableSubmit(false);
      }
      console.log('output', output);
      console.log('watch:', watch(['ingresos', 'egresos', 'deuda', 'descVivienda', 'descPrincipal']));
    }
  }));

  const onSubmit = (formulario) => {
    const asyncCall = async (datos) => {
      setOpenBackDrop(true);
      setDatosEvaluacion(datos);
      await evaluacionService.guardaEvaluacion(evaluacion).then((resp) => {
        console.log('formularion de guardado', resp.data);
        setEvaluacion(resp.data);
        setIdEvaluacion(resp.data.id);
        setServerMessage('La evaluacion se guardó correctamente');
        setOpenSnackSuccess(true);
        setCambioEvaluacion(false);
      }).catch((error) => {
        console.log('Error al guardar la evaluacion', error);
        setServerError('No fue posible guardar la evaluacion');
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario);
  };
  return (
    <div>
      <Accordion expanded={acordionDatos}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          onClick={() => setAcordionDatos(!acordionDatos)}
          className={classes.summary}
        >
          <Typography variant="h4">Datos del Alumno</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.accordionDetails}>
          <form
            id={formName}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3} md={3} lg={3}>
                <TextField
                  label="Folio"
                  name="folio"
                  inputRef={register}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.folio}
                  helperText={errors.folio?.message}
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={9} md={9} lg={9}>
                <TextField
                  label="Nombre Cliente"
                  name="nombreCliente"
                  inputRef={register}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.nombreCliente}
                  helperText={errors.nombreCliente?.message}
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5}>
                <TextField
                  label="Alumno"
                  name="nombre"
                  inputRef={register}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.nombre}
                  helperText={errors.nombre?.message}
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={2} md={2} lg={2}>
                <TextField
                  label="Matrícula"
                  name="matricula"
                  inputRef={register}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.matricula}
                  helperText={errors.matricula?.message}
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={5} md={5} lg={5}>
                <TextField
                  label="Grado Escolar"
                  name="gradoEscolar"
                  inputRef={register}
                  variant="outlined"
                  onInput={genService.toUpperCase}
                  error={errors.gradoEscolar}
                  helperText={errors.gradoEscolar?.message}
                  fullWidth
                  size="small"
                  inputProps={{
                    readOnly: true
                  }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Monto Ingresos *"
                  name="ingresos"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'ingresos');
                    clearErrors('ingresos');
                    setDisableSubmit(true);
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  inputRef={register({
                    required: { value: !disableIngresos, message: requiredField },
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' },
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.ingresos}
                  helperText={errors.ingresos?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled={disableIngresos}
                />
                <FormControlLabel
                  label={<Typography variant="body2">Ingresos sin elementos</Typography>}
                  name="sinIngresos"
                  control={(
                    <Checkbox
                      disableRipple
                      checked={disableIngresos}
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      inputProps={{ 'aria-label': 'decorative checkbox' }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setValue('ingresos', null);
                          setDisableIngresos(true);
                          clearErrors('ingresos');
                        } else {
                          setDisableIngresos(false);
                        }
                        setDisableSubmit(true);
                      }}
                      tabIndex="-1"
                    />
                  )}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Monto Egresos *"
                  name="egresos"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'egresos');
                    clearErrors('egresos');
                    setDisableSubmit(true);
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  inputRef={register({
                    required: { value: !disableEgresos, message: requiredField },
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.egresos}
                  helperText={errors.egresos?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled={disableEgresos}
                />
                <FormControlLabel
                  label={<Typography variant="body2">Egresos sin elementos</Typography>}
                  control={(
                    <Checkbox
                      disableRipple
                      name="sinEgresos"
                      color="default"
                      checked={disableEgresos}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      inputProps={{ 'aria-label': 'decorative checkbox' }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setValue('egresos', null);
                          setDisableEgresos(true);
                          clearErrors('egresos');
                        } else {
                          setDisableEgresos(false);
                        }
                        setDisableSubmit(true);
                      }}
                      tabIndex="-1"
                    />
                  )}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Monto Deuda *"
                  name="deuda"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'deuda');
                    clearErrors('deuda');
                    setDisableSubmit(true);
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  inputRef={register({
                    required: { value: !disableDeuda, message: requiredField },
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' },
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.deuda}
                  helperText={errors.deuda?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled={disableDeuda}
                />
                <FormControlLabel
                  label={<Typography variant="body2">Deuda sin elementos</Typography>}
                  control={(
                    <Checkbox
                      disableRipple
                      name="sinDeuda"
                      color="default"
                      checked={disableDeuda}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      inputProps={{ 'aria-label': 'decorative checkbox' }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setValue('deuda', null);
                          setDisableDeuda(true);
                          clearErrors('deuda');
                        } else {
                          setDisableDeuda(false);
                        }
                        setDisableSubmit(true);
                      }}
                      tabIndex="-1"
                    />
                  )}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  id="combo-box-vivienda"
                  options={comboVivienda}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  renderOption={(option) => (option.label ? option.label : '')}
                  fullWidth
                  value={valueVivienda}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="descVivienda"
                      label="Opciones Vivienda *"
                      variant="outlined"
                      size="small"
                      error={!!errors.descVivienda}
                      helperText={errors.descVivienda?.message}
                      onInput={genService.toUpperCase}
                      inputRef={register({
                        required: { value: !disableVivienda, message: requiredField }
                      })}
                    />
                  )}
                  onChange={(event, newValue) => {
                    console.log('Combo vivienda newVal', newValue);
                    setValueVivienda(newValue);
                    clearErrors('descVivienda');
                    setDisableSubmit(true);
                  }}
                  disabled={disableVivienda}
                />
                <FormControlLabel
                  label={<Typography variant="body2">Vivienda sin elementos</Typography>}
                  control={(
                    <Checkbox
                      disableRipple
                      checked={disableVivienda}
                      name="sinVivienda"
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      inputProps={{ 'aria-label': 'decorative checkbox' }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setValueVivienda(null);
                          setDisableVivienda(true);
                          clearErrors('descVivienda');
                        } else {
                          setDisableVivienda(false);
                        }
                        setDisableSubmit(true);
                      }}
                      tabIndex="-1"
                    />
                  )}
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autocomplete
                  id="combo-box-principal"
                  options={comboPrincipal}
                  getOptionLabel={(option) => (option.label ? option.label : '')}
                  renderOption={(option) => (option.label ? option.label : '')}
                  fullWidth
                  value={valuePrincipal}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="descPrincipal"
                      label="Opciones Principal *"
                      variant="outlined"
                      size="small"
                      error={!!errors.descPrincipal}
                      helperText={errors.descPrincipal?.message}
                      onInput={genService.toUpperCase}
                      inputRef={register({
                        required: { value: !disablePrincipal, message: requiredField },
                      })}
                    />
                  )}
                  onChange={(event, newValue) => {
                    console.log('Combo principal newVal', newValue);
                    setValuePrincipal(newValue);
                    clearErrors('descPrincipal');
                    setDisableSubmit(true);
                  }}
                  disabled={disablePrincipal}
                />
                <FormControlLabel
                  label={<Typography variant="body2">Principal sin elementos</Typography>}
                  control={(
                    <Checkbox
                      disableRipple
                      name="sinPrincipal"
                      color="default"
                      checked={disablePrincipal}
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                      inputProps={{ 'aria-label': 'decorative checkbox' }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setValuePrincipal(null);
                          setDisablePrincipal(true);
                          clearErrors('descPrincipal');
                        } else {
                          setDisablePrincipal(false);
                        }
                        setDisableSubmit(true);
                      }}
                      tabIndex="-1"
                    />
                  )}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Detalle Vivienda *"
                  name="specVivienda"
                  variant="outlined"
                  inputRef={register({
                    required: { value: true, message: requiredField }
                  })}
                  onInput={genService.toUpperCase}
                  error={!!errors.specVivienda}
                  helperText={errors.specVivienda?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <TextField
                  label="Detalle Principal *"
                  name="specPrincipal"
                  variant="outlined"
                  inputRef={register({
                    required: { value: true, message: requiredField }
                  })}
                  onInput={genService.toUpperCase}
                  error={!!errors.specPrincipal}
                  helperText={errors.specPrincipal?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Monto Colegiatura"
                  name="colegiatura"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'colegiatura');
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  inputRef={register({
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.colegiatura}
                  helperText={errors.colegiatura?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Colegiatura Hermanos"
                  name="colegiaturaHermanos"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'colegiaturaHermanos');
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  inputRef={register({
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.colegiaturaHermanos}
                  helperText={errors.colegiaturaHermanos?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Monto Diversión"
                  name="diversion"
                  variant="outlined"
                  onChange={(e) => {
                    removeZero(e.target.value, 'diversion');
                    calcularFactores(evaluacion);
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  inputRef={register({
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.diversion}
                  helperText={errors.diversion?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label="Porcentaje Beca *"
                  name="porcentajeBeca"
                  variant="outlined"
                  onChange={(e) => {
                    if (e.target.value <= 100) {
                      setValue('porcentajeBeca', e.target.value);
                      removeZero(e.target.value, 'porcentajeBeca');
                      calcularFactores(evaluacion);
                      clearErrors('porcentajeBeca');
                    } else {
                      setError('porcentajeBeca', { type: 'maxLength', message: 'El porcentaje no puede ser mayor al 100 %' });
                      setValue('porcentajeBeca', getValues('porcentajeBeca'));
                    }
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    maxLength: 3
                  }}
                  inputRef={register({
                    required: { value: true, message: requiredField },
                    pattern: { value: /^(?=.*[0-9])\d*,*\d*([.]?\d)*$/, message: 'Número mayor o igual a 0.' }
                  })}
                  onInput={genService.onlyAmounts}
                  error={!!errors.porcentajeBeca}
                  helperText={errors.porcentajeBeca?.message}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Recomendacion *"
                  name="recomendacion"
                  placeholder="Ingresé máximo 1,000 caracteres."
                  inputRef={register({
                    required: { value: true, message: requiredField }
                  })}
                  inputProps={{
                    style: { fontSize: 12, padding: 5 },
                    maxLength: 1000
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  minRows={3}
                  onInput={genService.toUpperCase}
                  error={!!errors.recomendacion}
                  helperText={errors.recomendacion?.message}
                />
              </Grid>
            </Grid>
          </form>
        </AccordionDetails>
      </Accordion>
      {evaluacion !== null && evaluacion !== undefined
        ? (
          <div>
            <Accordion expanded={acordionFactores}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={() => setAcordionFactores(!acordionFactores)}
                className={classes.summary}
              >
                <Typography variant="h4">Factores Adicionales</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails className={classes.accordionDetails}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="spanning table" className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          CONCEPTO
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          CANTIDAD
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          PORCENTAJE
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          MONTO BECA
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          % BECA
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          COLEG. VS GASTOS
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.colegiaturaGastos} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.porcentajeColegiatura} displayType="text" suffix=" %" />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.colegiaturaBeca} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.pctColegiaturaBeca} displayType="text" suffix=" %" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          DEFICIT ECONOMICO
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.montoDeficit} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.porcentajeDeficit} displayType="text" suffix=" %" />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.deficitBeca} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.pctDeficitBeca} displayType="text" suffix=" %" />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          ENDEUDAMIENTO
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.montoDeuda} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.porcentajeDeuda} displayType="text" suffix=" %" />
                        </TableCell>
                        <TableCell align="right">
                          -
                        </TableCell>
                        <TableCell align="right">
                          -
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          DIVERSION
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.montoDiversion} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.porcentajeDiversion} displayType="text" suffix=" %" />
                        </TableCell>
                        <TableCell align="right">
                          -
                        </TableCell>
                        <TableCell align="right">
                          -
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          LIQUIDEZ
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.montoLiquidez} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.porcentajeLiquidez} displayType="text" suffix=" %" />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.montoLiquidezBeca} displayType="text" thousandSeparator prefix="$ " />
                        </TableCell>
                        <TableCell align="right">
                          <NumberFormat value={evaluacion.pctLiquidezBeca} displayType="text" suffix=" %" />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={acordionEvaluacion}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={() => setAcordionEvaluacion(!acordionEvaluacion)}
                className={classes.summary}
              >
                <Typography variant="h4">Evaluación</Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails className={classes.accordionDetails}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="spanning table" className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell align="center" className={classes.tableCell}>
                          VIABILIDAD
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          INGRESOS
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          EGRESOS
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell} colSpan={2}>
                          VIVIENDA
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell} colSpan={2}>
                          Principal
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          DEUDA
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {evaluacion?.detalles?.map((evalItem) => (
                        <TableRow key={evalItem.ingresosMin}>
                          <TableCell component="th" scope="row" className={classes.mainColumn}>
                            {evalItem.descViabilidad}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={
                              evalItem.ingresosFactor !== null ? classes.resColumn : null
                            }
                          >
                            <NumberFormat value={evalItem.ingresosMin} displayType="text" thousandSeparator prefix="$ " />
                            <br />
                            <NumberFormat value={evalItem.ingresosMax} displayType="text" thousandSeparator prefix="$ " />
                          </TableCell>
                          <TableCell
                            align="right"
                            className={evalItem.egresosFactor !== null ? classes.resColumn : null}
                          >
                            <NumberFormat value={evalItem.egresosMin} displayType="text" thousandSeparator prefix="$ " />
                            <br />
                            <NumberFormat value={evalItem.egresosMax} displayType="text" thousandSeparator prefix="$ " />
                          </TableCell>
                          <TableCell
                            align="right"
                            className={
                              evalItem.viviendaFactor !== null ? classes.resColumn : null
                            }
                            colSpan={2}
                          >
                            {evalItem.viviendaMin}
                            <br />
                            {evalItem.viviendaMax}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={
                              evalItem.principalFactor !== null ? classes.resColumn : null
                            }
                            colSpan={2}
                          >
                            {evalItem.principalMin}
                            <br />
                            {evalItem.principalMax}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={evalItem.deudaFactor !== null ? classes.resColumn : null}
                          >
                            {`${evalItem.deudaMin} - ${evalItem.deudaMax} %`}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow key="resultados" className={classes.mainColumn}>
                        <TableCell align="center" className={classes.tableCell} />
                        <TableCell align="center" className={classes.tableCell}>
                          INGRESOS(30)
                          <br />
                          {`% (${evaluacion.pctIngresos})`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          EGRESOS(30)
                          <br />
                          {`% (${evaluacion.pctEgresos})`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          VIVIENDA(20)
                          <br />
                          {`% (${evaluacion.pctVivienda})`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          PRINCIPAL(10)
                          <br />
                          {`% (${evaluacion.pctPrincipal})`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          DEUDA(10)
                          <br />
                          {`% (${evaluacion.pctDeuda})`}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          ACUMULADO
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          RESULTADO
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.mainColumn}>
                          PUNTAJE ASIGNADO
                        </TableCell>
                        <TableCell align="center">
                          {evaluacion.puntosIngresos}
                        </TableCell>
                        <TableCell align="center">
                          {evaluacion.puntosEgresos}
                        </TableCell>
                        <TableCell align="center">
                          {evaluacion.puntosVivienda}
                        </TableCell>
                        <TableCell align="center">
                          {evaluacion.puntosPrincipal}
                        </TableCell>
                        <TableCell align="center">
                          {evaluacion.puntosDeuda}
                        </TableCell>
                        <TableCell align="center" className={classes.resColumn}>
                          {evaluacion.puntosTotal}
                        </TableCell>
                        <TableCell align="center" className={classes.resColumn}>
                          {evaluacion.resultadoEvaluacion}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          null
        )}
      <GeneralBackDrop open={openBackDrop} />
      <ConfirmationDialog
        title="Salir de evaluación."
        message="Se perderan los cambios que haya realizado."
        open={confirmarSalida}
        onClose={() => setConfirmarSalida(false)}
        confirm={() => {
          setConfirmarSalida(false);
          setEvaluacion({});
          setOpenEvaluacion(false);
        }}
      />
    </div>
  );
});

AltaEvaluacion.propTypes = {
  formName: PropTypes.string,
  comboVivienda: PropTypes.array,
  comboPrincipal: PropTypes.array,
  setServerError: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setDisableSubmit: PropTypes.func,
  evaluacion: PropTypes.object,
  setEvaluacion: PropTypes.func,
  setIdEvaluacion: PropTypes.func,
  idEvaluacion: PropTypes.number,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setConfirmarSalida: PropTypes.func,
  confirmarSalida: PropTypes.bool,
  setOpenEvaluacion: PropTypes.func,
  openBackDrop: PropTypes.bool,
  setOpenBackDrop: PropTypes.func,
  setCambioEvaluacion: PropTypes.func
};

export default AltaEvaluacion;
