import React from 'react';
import {
  Box,
  Divider,
  Fab,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  ClickAwayListener
} from '@material-ui/core';
import {
  Add,
  Delete,
  Search,
  TuneTwoTone as Menu,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import genService from 'src/services/generalServices';
import { useForm } from 'react-hook-form';
import clientesService from 'src/services/clientesService';
import { format } from 'date-fns';
import ConfirmationDialog from 'src/views/commons/ConfirmationDialog';
import BusquedaClientes from './BusquedaClientes';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    letterSpacing: '-0.05px'
  },
  bottomSpace: {
    marginBottom: theme.spacing(1)
  },
  rightMargin: {
    marginRight: theme.spacing(1)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  paperRoot: {
    padding: '2px 6px',
    display: 'flex'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const ClientesToolBar = ({
  className,
  setOpenAltaCliente,
  selectedRows,
  setOpenBackDrop,
  setOpenSnackError,
  setServerError,
  setRowsClientes,
  comboTipoClientes,
  rowsClientes,
  setServerMessage,
  setOpenSnackSuccess,
  setSelectedRows

}) => {
  const {
    register,
    handleSubmit,
    reset
  } = useForm();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const paperRef = React.useRef();
  const openPopper = Boolean(anchorEl);
  const [fechaDesde, setFechaDesde] = React.useState(null);
  const [fechaHasta, setFechaHasta] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickAway = () => {
    setAnchorEl(null);
    setFechaDesde(null);
    setFechaHasta(null);
  };
  const handleClickPopper = () => {
    setAnchorEl(anchorEl ? null : paperRef.current);
    reset({});
  };
  const removeRows = () => {
    setOpenDialog(false);
    const asyncCall = async () => {
      setOpenBackDrop(true);
      await clientesService.deleteClientes(selectedRows).then((resp) => {
        if (resp.data.Errores !== undefined && resp.data.Errores.length > 0) {
          setServerError(`Fallo al eliminar: ${resp.data.Errores.length} clientes.`);
          setOpenSnackError(true);
        }
        if (resp.data.Eliminados !== undefined && resp.data.Eliminados.length > 0) {
          resp.data.Eliminados.map((del) => {
            console.log(del);
            rowsClientes = rowsClientes.filter((item) => item.id !== del);
            return rowsClientes;
          });
          setSelectedRows([]);
          setServerMessage(`Clientes eliminados: ${resp.data.Eliminados.length}`);
          setOpenSnackSuccess(true);
          setRowsClientes(rowsClientes);
        }
      }).catch((error) => {
        setServerError(error.message);
        setOpenSnackError(true);
      });
      setOpenBackDrop(false);
    };
    asyncCall();
  };
  const onSubmit = (formulario, searchType) => {
    console.log(formulario);
    const asyncCall = async (datos, type) => {
      setOpenBackDrop(true);
      if (fechaDesde !== null && fechaDesde !== '') {
        datos.desde = format(fechaDesde, 'yyyy-MM-dd');
      }
      if (fechaHasta !== null && fechaHasta !== '') {
        datos.hasta = format(fechaHasta, 'yyyy-MM-dd');
      }
      await clientesService.searchClientes(datos).then((resp) => {
        if (resp.data.errorCode === 204) {
          setOpenSnackError(true);
          setServerError(resp.data.mensaje);
          setRowsClientes([]);
        } else {
          setRowsClientes(resp.data);
          if (resp.data.length > 0 && type === 'adv') {
            handleClickPopper();
          }
        }
      }).catch((error) => {
        setRowsClientes([]);
        setServerError(error.message);
        setOpenSnackError(true);
        setOpenBackDrop(false);
      });
      setOpenBackDrop(false);
    };
    asyncCall(formulario, searchType);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.title}>
        Busqueda de Clientes
      </Typography>
      <Divider className={classes.bottomSpace} />
      <Box display="flex" justifyContent="flex-end">
        <Tooltip title="Eliminar">
          <div>
            <Fab
              color="primary"
              className={clsx(classes.rightMargin, classes.bottomSpace)}
              disabled={isEmpty(selectedRows)}
              onClick={() => setOpenDialog(true)}
            >
              <Delete />
            </Fab>
          </div>
        </Tooltip>
        <Tooltip title="Agregar">
          <Fab
            color="primary"
            onClick={() => setOpenAltaCliente(true)}
            className={classes.bottomSpace}
          >
            <Add />
          </Fab>
        </Tooltip>
      </Box>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid
            className={classes.item}
            item
            md={6}
            sm={12}
            xs={12}
            align="center"
          >
            <Paper
              ref={paperRef}
              component="form"
              className={classes.paperRoot}
              onSubmit={handleSubmit((data) => onSubmit(data, 'single'))}
            >
              <InputBase
                inputRef={register}
                className={classes.input}
                placeholder="Nombre"
                inputProps={{ 'aria-label': 'buscar usuario' }}
                name="nombreCliente"
                onFocus={handleClickAway}
                onInput={genService.toUpperCase}
              />
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                type="submit"
              >
                <Search />
              </IconButton>
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton className={classes.iconButton} aria-label="menu" onClick={handleClickPopper}>
                <Menu />
              </IconButton>
            </Paper>
          </Grid>
          <BusquedaClientes
            openPopper={openPopper}
            anchorEl={anchorEl}
            setFechaDesde={setFechaDesde}
            setFechaHasta={setFechaHasta}
            fechaDesde={fechaDesde}
            fechaHasta={fechaHasta}
            onSubmitAdv={onSubmit}
            comboTipoClientes={comboTipoClientes}
          />
        </Grid>
      </ClickAwayListener>
      <ConfirmationDialog
        keepMounted
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        confirm={removeRows}
        title="Eliminar registros"
        message="Se eliminará el cliente(s) y todos los usuarios relacionados."
      />
    </div>
  );
};
ClientesToolBar.propTypes = {
  className: PropTypes.string,
  setOpenAltaCliente: PropTypes.func,
  selectedRows: PropTypes.array,
  setOpenBackDrop: PropTypes.func,
  setOpenSnackError: PropTypes.func,
  setServerError: PropTypes.func,
  setRowsClientes: PropTypes.func,
  comboTipoClientes: PropTypes.array,
  rowsClientes: PropTypes.array,
  setServerMessage: PropTypes.func,
  setOpenSnackSuccess: PropTypes.func,
  setSelectedRows: PropTypes.func
};

export default ClientesToolBar;
