export function root() {
  return '/';
}

export function login() {
  return '/login';
}

export function logout() {
  return '/';
}

export function dashboard() {
  return '/app/dashboard';
}

export function altaCliente() {
  return '/app/altaCliente';
}

export function ordenes() {
  return '/app/ordenes';
}

export function plantilla() {
  return '/app/plantillas';
}

export function evaluacion() {
  return '/app/evaluacion';
}

export function reportes() {
  return '/app/reportes';
}

export function altaUsuario() {
  return '/app/config/usuarios';
}

export function passwordReset() {
  return '/passwordReset';
}

export function clienteOT() {
  return '/app/clientes/ordenes';
}

export function cargaMasiva() {
  return '/app/cargaMasiva';
}
